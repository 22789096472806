import {
	setGlobalNotification,
	getFetchConfig,
	offLineNotification,
	simpleFailFetchResponse
} from 'Actions/GlobalMetaActions';
import { trackGAEvent } from 'Actions/adsActions';

export const SHOWN_NOTICE = 'SHOWN_NOTICE';

export function noticeShown( notices ) {
	return {
		type: SHOWN_NOTICE,
		notices: ( Array.isArray( notices ) ? notices : [ notices ] )
	};
}

export function dismissNotice( noticeId ) {
	return function( dispatch, getState ) {
		const state = getState(),
			url = state.topifyPresets.forumSite.url,
			userID = state.ustate.id ? state.ustate.id : 0;

		if( !noticeId || !userID ) {
			return false;
		}

		topifyConsole.info( 'Dismiss notice', noticeId );

		let moreData = `noticeid=${ noticeId }`;
		const fetchConfig = dispatch( getFetchConfig( true, 'POST', moreData ) );

		return fetch( url + `?v=3.0&ajax=1&uid=${ userID }&req=post&type=dismissnotice`, fetchConfig ).then( ( response ) => {
				if( !response.ok ) {
					dispatch( simpleFailFetchResponse( response ) );

				} else {
					response.json().then( ( json ) => {
							setGlobalNotification( dispatch, json, { ok: true } );
							// Record the Google Analytics event.
							dispatch( trackGAEvent( 1, 'dismissnotice', 'userAction', 'consumption' ) );
						}
					);
				}
		} ).catch( () => dispatch( offLineNotification( { name: 'OfflineAction' } ) ) );
	};
}