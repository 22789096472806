import uniq  from 'lodash/uniq';

export const isCachedChangedForMultiple = ({prevResult,prevPage,multiple,currPage}) => {
	return Object.keys(multiple).some( resultKey =>
		isCachedChangedFor({
			prevResult: prevResult[ resultKey ],
			prevPage,
			currResult: multiple[ resultKey ],
			currPage,
		}) )
};

export const isCachedChangedFor = ({prevResult,prevPage,currResult,currPage}) => {
	return prevPage == currPage && uniq(prevResult).toString() != uniq(currResult).toString()
};