import postscribe from 'postscribe';
import queryString from 'query-string';
import throttle from 'lodash/throttle';

import { parsePath } from 'Utils/nav/NavHelpers';
import { getCookie } from 'Utils/cookies';

export const GEOLOCATION = 'GEOLOCATION';
export const SHOW_INTERSTITIAL = 'SHOW_INTERSTITIAL';
export const HIDE_INTERSTITIAL = 'HIDE_INTERSTITIAL';
export const ADS_PERMISSIONS = 'ADS_PERMISSIONS';
export const RESET_HIDDEN_ADS = 'RESET_HIDDEN_ADS';
export const DOCUMENT_VISIBLE = 'DOCUMENT_VISIBLE';
export const GDPR_CONSENT = 'GDPR_CONSENT';

let insertedNodes = [],
	useSSL = 'https:' === document.location.protocol,
	pageCounter = 0,
	adsPermissions = { vendorAds: false, noAdsPermissions: false, secondaryAds: false },
	lastPathPermsCheck = '',
	bodyDOM = document.body || document.getElementsByTagName( 'body' )[ 0 ],
	headDOM = document.head || document.getElementsByTagName( 'head' )[ 0 ],
	adProviders = {},
	privacySettings = {
		gdpr: true,
		cookiesOk: false,
		trackUserOk: false
	},
	is = {
		VS: false,
		userLoggedIn: false,
		Lotame: false,
		prebid: false,
		taboola: false,
		taboola_widget: false,
		sharethrough: false,
		VSInterstitial: false,
		gumgum: false,
		captify: false,
		sortable: false,
		blockthrough: false,
		comScore: false,
		viglink: false,
		skimlinks: false,
		listBuilder: false,
		crosspixel: false,
		adomik: false,
	},
	loaded = {
		init: false,
		initGoogleAds: false,
		asf: false,
		pageView: false,
		events: false,
		VSInterstitial: false,
		taboola: false,
		Lotame: false,
		Lotame_ccauds: false,
		VSAPI: false,
		comScore: false,
		viglink: false,
		skimlinks: false,
		customs: false,
		sharethrough: false,
		gumgum: false,
		sortable: false,
		blockthrough: false,
		listBuilder: false,
		crosspixel: false,
		adomik: false,
	};

const onPageViewEvent = new CustomEvent( 'topifyPageTransitionEvent' );
const analyticsTrackerName = 'Topify';
const topifyGA_id = 'UA-36870210-7';
const geoLocationRefresh = 60000; // 60 secs

window.googletag = window.googletag || { cmd: [] };

window._gaq = window._gaq || [];
window.PREBID_TIMEOUT = 1000;

// Action creators
function setGeoLocation( location = {allow: true, error: false, latitude: '', longitude: '', timestamp: 0} ) {
	return {
		type: GEOLOCATION,
		location
	}
}
export function showInterstitial() {
	return {
		type: SHOW_INTERSTITIAL
	}
}
export function resetHiddenAdsPerUser() {
	return {
		type: RESET_HIDDEN_ADS
	}
}
export function hideInterstitial() {
	return {
		type: HIDE_INTERSTITIAL
	}
}
export function setAdsPermissions( values = { vendorAds: false, noAdsPermissions: false, secondaryAds: false } ) {
	return {
		type: ADS_PERMISSIONS,
		values
	}
}
export function setDocumentVisibility( visible ) {
	return {
		type: DOCUMENT_VISIBLE,
		visible: visible
	}
}
export function acceptConsent( data ) {
	return {
		type: GDPR_CONSENT,
		data: data
	}
}

function getBody() {
	if( !bodyDOM ) {
		bodyDOM = document.body || document.getElementsByTagName( 'body' )[ 0 ];
	}
	return bodyDOM;
}
function getHead() {
	if( !headDOM ) {
		headDOM = document.head || document.getElementsByTagName( 'head' )[ 0 ];
	}
	return headDOM;
}

// Is VS
const isVS = ( state ) => {
	is.VS = ( parseInt( state.topifyPresets.forumSite.isVS ) === 1 );
	return is.VS;
};
// are we logged in?
const isUserLoggedIn = ( state ) => {
	is.userLoggedIn = !!( state.ustate.id );
	return is.userLoggedIn;
};
export function isLoggedIn() {
	return ( dispatch, getState ) => {
		return isUserLoggedIn( getState() );
	};
}

// Check ad libraries settings
const isPrebid = ( state ) => {
	// with flag on and dfp tags defined
	let enable = !!(
		typeof prebid_adUnits_def !== 'undefined'
		&& prebid_adUnits_def
		&& prebid_adUnits_def.length > 0
		&& state.topifyPresets.forumSite.f_prebid
		&& state.topifyPresets.forumSite.dfpTags.length > 0
	);

	// allow prebid for non-EU
	is.prebid = privacySettings.gdpr ? false : enable;
	return is.prebid;
};
const hasCustomScripts = ( state ) => {
	return ( state.topifyPresets.forumSite.customscripts.length > 0 );
};
const isLotame = ( state ) => {
	is.Lotame = !!( is.VS && state.topifyPresets.forumSite.f_lotame );
	return is.Lotame;
};
const isCaptify = ( state ) => {
	is.captify = !!( is.VS && state.topifyPresets.forumSite.f_captify );
	return is.captify;
};
const isShareThrough = ( state ) => {
	is.sharethrough = !!( is.VS && state.topifyPresets.forumSite.f_sharethrough );
	return is.sharethrough;
};
const isSortable = ( state ) => {
	is.sortable = privacySettings.gdpr ? false : !!state.topifyPresets.forumSite.f_sortable;
	// if it's enabled, set vars
	if( is.sortable ) {
		window.deployads_srt = true;
		// skip prebid
		is.prebid = false;
	}

	topifyConsole.log( 'Sortable: ', ( ( is.sortable && window.deployads_srt ) || false ) );
	return is.sortable;
};
const isBlockthrough = ( state ) => {
	is.blockthrough = state.topifyPresets.forumSite.dfpTags.filter( t => t.btid !== '' ).length > 0;
	return is.blockthrough;
};
const isComScore = ( state ) => {
	is.comScore = !!( state.topifyPresets.forumSite.f_site_name && state.topifyPresets.forumSite.f_comscoreid );
	return is.comScore;
};
const isViglink = ( state ) => {
	is.viglink = ( state.topifyPresets.forumSite.f_vigkey && state.topifyPresets.forumSite.f_vigkey.length > 0 );
	return is.viglink;
};
const isSkimLinks = ( state ) => {
	is.skimlinks = ( state.topifyPresets.forumSite.f_skimlinks && state.topifyPresets.forumSite.f_skimlinks.length > 0 );
	return is.skimlinks;
};
const isGumGum = ( state ) => {
	is.gumgum = !!state.topifyPresets.forumSite.f_gumgum;
	return is.gumgum;
};
const isCrossPixel = ( state ) => {
	is.crosspixel = !!state.topifyPresets.forumSite.f_crosspixel;
	return is.crosspixel;
};
const isAdomik = ( state ) => {
	is.adomik = !!state.topifyPresets.forumSite.f_adomik;
	return is.adomik;
};
const isTaboola = ( state ) => { // guests only, for now
	const forumSite = state.topifyPresets.forumSite;
	// check all taboola types
	is.taboola = !!( forumSite.f_taboola && !adsPermissions.noAdsPermissions && !is.userLoggedIn );
	is.taboola_widget = ( forumSite.f_taboola_widget && !adsPermissions.noAdsPermissions && !is.userLoggedIn ) ? forumSite.f_taboola_widget : false;
};
const isListBuilder = ( state ) => {
	is.listBuilder = !!state.topifyPresets.forumSite.f_listbuilder;
	return is.listBuilder;
};
function willUseInterstitial( state ) {
	is.VSInterstitial = state.topifyPresets.forumSite.dfpTags.length > 0 && is.VS ? state.topifyPresets.forumSite.dfpTags.some( ad => parseInt( ad.at_id ) === 4 ) : false;

	return is.VSInterstitial;
}
function goInterstitial() {
	return ( pageCounter === 2 && is.VSInterstitial );
}

// Events
function initEvents( dispatch ) {
	if( !loaded.events ) {
		window.addEventListener( 'message', adMessageHandler, false );
		document.addEventListener( 'visibilitychange', () => focusChange( dispatch ) );
		focusChange( dispatch );

		if( privacySettings.gdpr ) {
			// Listen for GDPR consent event
			document.addEventListener( 'topifyGDPRConsent', ( e ) => gdprConsentHandler( e, dispatch ) );
		}

		loaded.events = true;
	}
}
function focusChange( dispatch ) {
	topifyConsole.log( 'visibility change', !document.hidden, document.visibilityState );
	dispatch( setDocumentVisibility( !document.hidden ) );
}
// when document.dispatchEvent( new CustomEvent( 'topifyGDPRConsent', { detail: { cookiesOk: true, trackUserOk: true } } ) );
function gdprConsentHandler( e, dispatch ) {
	if( e.detail ) {
		topifyConsole.info( 'GDPR consent handler - ', e.detail );
		dispatch( acceptConsent( { ...e.detail } ) );
	}
}

// VS special ads
function adMessageHandler( event ) {
	let adData;
	try {
		adData = JSON.parse( event.data );

		// Kill if this is not from our Ad Network
		if(
			!adData ||
			adData.adSettings === undefined ||
			adData.adType === undefined
		) {
			return false;
		}

	} catch( e ) { /* ignore */
		return false;
	}

	let iFrame;

	// Get the iFrame
	if( adData.adId ) {
		iFrame = event.source.frameElement || document.getElementById( adData.adId );
	} else {
		iFrame = event.source.frameElement;
	}

	// if we have iFrame, let's go
	if( iFrame ) {
		switch( adData.adType.toLowerCase() ) {
			case "insertatiframe":
				insertAdAtIframe( adData, iFrame );
				break;
		}
	}
}
function insertAdAtIframe( adData, iFrame ) {
	// Stop if we don't have data needed
	if(
		!adData ||
		adData.adSettings === undefined ||
		adData.content === undefined ||
		adData.styles === undefined
	) {
		return false;
	}

	topifyConsole.info( "ad 'insertAtIframe'" );

	let randId = Math.floor( Math.random() * (9999 - 1) + 1 );

	// Applying the ad SETTINGS passed

	// Adding the styles
	postscribe(
		getHead(),
		'<style id="insertAtIFrame-ad-css-' + randId + '" type="text/css">' + adData.styles + '</style>',
		{
			done: function() {
				topifyConsole.info( "loading passed iframe styles" );
			},
			error: function() {
				topifyConsole.warn( 'error loading: passed styles');
			}
		}
	);

	// Creating container with content
	let adContainer = iFrame.parentNode,
		divElement = document.createElement( "div" );
	// adding classes
	divElement.classList.add( 'insertAtIFrame-ad', 'no-ad', 'nolinks', 'nooptimize', 'norewrite', 'noskim' );
	// adding content
	divElement.insertAdjacentHTML( 'beforeend', adData.content );

	// search ad container and remove margins
	let p_counter = 0;
	while( adContainer.classList.contains( 'ad-card' ) === false && p_counter < 5 ) {
		adContainer = adContainer.parentNode;
		p_counter++;
	}
	if( adContainer.classList.contains( 'ad-card' ) ) {
		adContainer.style.margin = "0 auto";
	}

	// Hide iframe
	iFrame.style.display = 'none';

	// Insert the content next to the iframe
	iFrame.insertAdjacentElement( 'afterend', divElement );
	topifyConsole.log( "ad 'insertAtIframe' complete" );
}

// GDPR consent initial status
export function checkGDPRStatus() {
	return ( dispatch, getState ) => {
		let state = getState();

		// Site's ad providers we want to trigger
		adProviders = state.topifyPresets.forumSite.adProviders;

		// check FC status
		if( googlefc && googlefc.getConsentStatus ) {
			try {
				const fcStatus = googlefc.getConsentStatus(),
					consentDetail = {
						fcStatus: fcStatus,
						cookiesOk: [ googlefc.ConsentStatusEnum.CONSENTED_TO_PERSONALIZED_ADS, googlefc.ConsentStatusEnum.CONSENTED_TO_NON_PERSONALIZED_ADS, googlefc.ConsentStatusEnum.CONSENT_NOT_REQUIRED ].includes( fcStatus ),
						trackUserOk: [ googlefc.ConsentStatusEnum.CONSENTED_TO_PERSONALIZED_ADS, googlefc.ConsentStatusEnum.CONSENT_NOT_REQUIRED ].includes( fcStatus )
					};
				topifyConsole.info( 'GDPR consent status - ', consentDetail );
				dispatch( acceptConsent( consentDetail ) );

			} catch( e ) {
				topifyConsole.warn( 'Error getting fcConsent status', e );
			}
		}

		// check privacy settings
		updatePrivacySettings( state );
	};
}

// Privacy Settings checks snf provider check
function updatePrivacySettings( state ) {
	privacySettings = {
		gdpr: state.topifyPresets.GDPR,
		cookiesOk: state.topifyPresets.GDPR ? state.ads.GDPRConsent.cookiesOk : true,
		trackUserOk: state.topifyPresets.GDPR ? state.ads.GDPRConsent.trackUserOk : true
	};

	topifyConsole.info( 'privacySettings', privacySettings );

	return privacySettings;
}
function isAdProviderAllowed( providerName ) {
	// we only need to check if GDPR applies
	if( privacySettings.gdpr === false ) {
		return true;
	}

	if(
		!providerName
		|| typeof googlefc === 'undefined'
		|| typeof googlefc.getConsentedProviderIds === 'undefined'
		|| privacySettings.trackUserOk === false
	) {
		return false;
	}

	// get google FC Consented provider Ids for the current site
	let consentedIds = googlefc.getConsentedProviderIds() || [],
		providerIds = adProviders[ providerName.toLowerCase() ] || [];

	// Allow only those we want and are allowed
	return !!consentedIds.find( p => providerIds.length && providerIds.indexOf( p ) >= 0 );
}

// Ads ------
// GumGum cleaner
function clearGumGum( state ) {
	// destroy GUMGUM
	if( window.GUMGUM ) {
		window.GUMGUM.Bean.fire( window.GUMGUM.container, 'destroy' );
		loaded.gumgum = false;
	}

	const page = document.getElementById( 'page' );
	if( page === null ) {
		return false;
	}

	if( is.gumgum ) {
		try {
			if( !adsPermissions.noAdsPermissions && !is.userLoggedIn ) {
				// Adding global id
				window.ggpid = state.topifyPresets.forumSite.f_gumgum;
				page.classList.remove( 'no-ad' );
				return true;
			} else {
				page.classList.add( 'no-ad' );
				return false;
			}
		} catch( e ) {
			topifyConsole.error( 'GumGum failed to check', e );
			return false;
		}
	}
	return false;
}

// Load Libraries: prebid, googletag, analytics ...
// Load Amazon a9
function loadA9() {
	// with Sortable enable do not load Amazon A9
	if( is.sortable ) {
		return;
	}

	//load the apstag.js library
	postscribe(
		getBody(),
		`<script type="text/javascript">!function(a9,a,p,s,t,A,g){if(a[a9])return;function q(c,r){a[a9]._Q.push([c,r])}a[a9]={init:function(){q("i",arguments)},fetchBids:function(){q("f",arguments)},setDisplayBids:function(){},targetingKeys:function(){return[]},_Q:[]};A=p.createElement(s);A.async=!0;A.src=t;g=p.getElementsByTagName(s)[0];g.parentNode.insertBefore(A,g)}("apstag",window,document,"script","//c.amazon-adsystem.com/aax2/apstag.js");</script>`,
		{
			done: function() {
				topifyConsole.info( 'loading a9' );
				//initialize the apstag.js library on the page to allow bidding
				apstag.init({
					pubID: `3b761737-e062-4a98-bc94-655a83da57af`,
					adServer: 'googletag'
				});
			},
			error: function() {
				topifyConsole.warn( 'error loading: a9' );
			}
		}
	);
}
export function loadAFS() {
	// AFS ads
	if( !loaded.asf && TopifyPresets.forumData.forumSite[ 0 ].f_afs_publisherid && TopifyPresets.forumData.forumSite[ 0 ].f_afs_channelid ) {
		(function( G, o, O, g, L, e ) {
			G[ g ] = G[ g ] || function() { (G[ g ][ 'q' ] = G[ g ][ 'q' ] || []).push( arguments ) },
				G[ g ][ 't' ] = 1 * new Date;
			L = o.createElement( O ), e = o.getElementsByTagName( O )[ 0 ];
			L.async = 1; L.src = '//www.google.com/adsense/search/async-ads.js';
			e.parentNode.insertBefore( L, e )
		})( window, document, 'script', '_googCsa' );

		loaded.asf = true;
	}
}
// normalizing prebid adUnits definitions: ensure we are using the correct sizes int values
const normalizePrebidAdUnits = () => {
	try {
		// With Prebid 1.x some adapters changed their names,
		// we need to map them for now
		let newBidderName = {
			indexExchange: 'ix',
			sekindo: 'sekindoUM',
			brealtime: 'emxdigital',
		};

		prebid_adUnits_def = prebid_adUnits_def.map( ad => {
			// all sizes parts are int
			ad.sizes = ad.sizes.map( sizes => sizes.map( size => parseInt( size ) ) );
			// define mediaTypes with normalized sizes
			ad.mediaTypes = { banner: { sizes: ad.sizes } };

			// Update bidder names and params
			ad.bids = ad.bids.map( bid => {
				if( bid.bidder === 'indexExchange' && bid.params.siteID ) { // ix change param name from siteID => siteId
					bid.params = {
						siteId: bid.params.siteID,
						size: ad.sizes[ 0 ],
					}
				} else if( bid.bidder === 'sortable' ) { // Adding Sortable params
					bid.params = { tagId: `tag-${ ad.code }` };
				}

				// adapters name mapping
				bid.bidder = newBidderName[ bid.bidder ] || bid.bidder;

				return bid;
			} );

			return ad;
		} );

	} catch( e ) {
		topifyConsole.error( 'Prebid normalizing Error:', e );
	}
};

let loadLibraries = function( state ) {
	return new Promise( resolve => {
		// Already loaded? resolve promise
		if( loaded.initGoogleAds ) {
			resolve( false );
		}

		// Set up Google Analytics
		(function() {
			let gaq = document.createElement( 'script' );
			gaq.type = 'text/javascript';
			gaq.async = true;
			gaq.src = (useSSL ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
			let s = document.getElementsByTagName( 'script' )[ 0 ];
			s.parentNode.insertBefore( gaq, s );
		})();

		(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
			(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
			m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
		})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

		ga( function() {
			ga( 'create', topifyGA_id, { name: analyticsTrackerName } );

			// Set the session scope appType dimension to the appropriate application type.
			// Defined dimensions:
			// dimension1 = forumId - hit
			// dimension2 = appType - session
			// dimension3 = forumUserGroup - hit
			// dimension4 = Network Status - hit
			ga( analyticsTrackerName + '.set', 'dimension2', 'react_v1' );
			ga( analyticsTrackerName + '.set', 'dimension4', 'online' ); // default online

			// appName
			ga( analyticsTrackerName + '.set', 'appName', 'Topify Browser V3' );
			// hostname
			ga( analyticsTrackerName + '.set', 'hostname', state.topifyPresets.forumSite.f_site_name );

			topifyConsole.info( 'GA - create tracker: ' + analyticsTrackerName );

			if( state.topifyPresets.forumSite.f_analytics_id ) {
				ga( 'create', state.topifyPresets.forumSite.f_analytics_id, { name: state.topifyPresets.forumSite.f_id } );
				topifyConsole.info( 'GA - create tracker: ' + state.topifyPresets.forumSite.f_analytics_id + ' - forumId: ' + state.topifyPresets.forumSite.f_id );
			}
		});

		// Prepare prebid loading
		// fallback to gpt
		function initAdserver() {
			if ( is.prebid ) {
				if( pbjs.adserverRequestSent ) {
					return;
				}
				pbjs.adserverRequestSent = true;
			}

			// Skip GPT when there are no dfp tags
			if( state.topifyPresets.forumSite.dfpTags.length > 0 ) {
				(function() {
					let gads = document.createElement( 'script' );
					gads.async = true;
					gads.type = 'text/javascript';
					gads.src = (useSSL ? 'https:' : 'http:') + '//www.googletagservices.com/tag/js/gpt.js';
					let node = document.getElementsByTagName( 'script' )[ 0 ];
					node.parentNode.insertBefore( gads, node );
				})();
			}

			if( is.prebid ) {
				// resolve the promise
				resolve( true );
			} else {
				resolve( false );
			}
		}

		if( is.prebid ) {
			setTimeout( initAdserver, window.PREBID_TIMEOUT );

			// Loading prebid
			(function() {
				let pbjsEl = document.createElement( "script" );
				pbjsEl.type = "text/javascript";
				pbjsEl.async = true;
				pbjsEl.id = 'prebid_src';
				pbjsEl.src = 'https://pb.vsassets.com/js/prebid1.24.0.js';
				let pbjsTargetEl = getHead();
				pbjsTargetEl.insertBefore( pbjsEl, pbjsTargetEl.firstChild );
			})();

			// setting adUnits
			pbjs.que.push( function() {
				// create rubiconpmp alias
				pbjs.aliasBidder( 'rubicon', 'rubiconpmp' );

				pbjs.setConfig( {
					bidderTimeout: window.PREBID_TIMEOUT,
					enableSendAllBids: false,
					sortable: {
						siteId: state.topifyPresets.forumSite.f_site_name,
					},
					userSync: {
						syncsPerBidder: 9999, // allow all syncs - 0 doesn't work anymore: https://github.com/prebid/Prebid.js/issues/2781
						syncDelay: 200, // 200 ms after the auction
						filterSettings: {
							iframe: {
								bidders: [ 'sortable' ],
								filter: 'include',
							},
							image: {
								bidders: '*',
								filter: 'include'
							}
						},
					}
				} );

				pbjs.bidderSettings = {
					standard: {
						alwaysUseBid: false,
						adserverTargeting: [ {
							key: "hb_bidder",
							val: function( bidResponse ) {
								topifyConsole.log( 'hb_bidder', bidResponse );
								return bidResponse.bidder;
							}
						}, {
							key: "hb_adid",
							val: function( bidResponse ) {
								return bidResponse.adId;
							}
						}, {
							key: "hb_pb",
							val: function( bidResponse ) {
								let cpm = bidResponse.cpm;
								if( cpm < 0.1 ) cpm = 0.10;
								if( cpm > 20 ) cpm = 20.00;
								let newCpm = (Math.floor( cpm * 10 ) / 10).toFixed( 2 );
								return "pb" + newCpm.replace( ".", "" );
							}
						}, {
							key: "hb_size",
							val: function( bidResponse ) {
								return bidResponse.size;
							}
						} ]
					}
				};

				pbjs.requestBids( {
					bidsBackHandler: initAdserver
				} );
			} );

			// Normalizing prebid Ad Units
			normalizePrebidAdUnits();

			// Load amazon a9
			loadA9();
		} else {
			initAdserver();
		}
	} );
};

function initGoogleAds( state ) {
	const forumSite = state.topifyPresets.forumSite;

	return loadLibraries( state ).then( () => {
		// No need to run again
		if( loaded.initGoogleAds ) {
			return true;
		}

		if( is.sortable ) { // Load Sortable's library
			loadSortable( forumSite.f_sortable );
		}

		loaded.initGoogleAds = true;

		return true;
	} );
}

// VS API
function loadVSAPI( state ) {
	// VSAPI depends on Lotame
	if( loaded.VSAPI || isAdProviderAllowed( 'Lotame' ) === false ) {
		return;
	}

	if( is.VS && is.Lotame && process.env.NODE_ENV === 'production' ) {
		postscribe(
			getBody(),
			'<script async id="vsapi_js" type="text/javascript" src="https://d1r55yzuc1b1bw.cloudfront.net/vsapi.all.min.js"></script>',
			{
				done: function() {
					topifyConsole.info( "loading vsapi js tag" );
					loaded.VSAPI = true;
					initVSAPI( state );
				},
				error: function() {
					topifyConsole.warn( 'error loading: VSAPI' );
				}
			}
		);
	}
}
function initVSAPI( state ) {
	// Inititating vsAPI
	if( loaded.VSAPI && typeof vsapi !== 'undefined' ) {
		let vs_api = vsapi.api();
		window._vs_observe = new vs_api.PostReadObserve();
		_vs_observe.debug = !( process.env.NODE_ENV === 'production' ); // log debug visibility and callback info, turn off in prod
		window._vs_beacon = new vs_api.PostReadBeacon( _vs_observe, state.ustate.id, 'https://api.verticalscope.com' );
		window._vs_lotame = new vs_api.LotameBeacon( _vs_observe );

		_vs_observe.init();
		_vs_beacon.init();
		topifyConsole.log( "vsAPI init" );

		refreshVSAPI( state );

	} else if( is.VS && is.Lotame ) {
		topifyConsole.log( 're initVSAPI' );
		setTimeout( () => initVSAPI( state ), 500 );
	}
}

// Load libraries functions
function loadSortable( sortableJs) {
	if( window.deployads_srt && !loaded.sortable && is.sortable && sortableJs ) {
		window.deployads = window.deployads || [];

		postscribe(
			getBody(),
			'<script async id="sortable_js" type="text/javascript" src="' + sortableJs + '"></script>',
			{
				done: function() {
					topifyConsole.info( "loading sortable js tag" );
					loaded.sortable = true;
				},
				error: function() {
					topifyConsole.warn( 'error loading: SORTABLE' );
					window.deployads_srt = false;
					window.deployads = null;
				}
			}
		);
	} else {
		topifyConsole.info( "Skipping sortable js tag" );
	}
}
function loadBlockthrough() {
	if( !is.blockthrough || loaded.blockthrough || isAdProviderAllowed( 'blockthrough' ) === false ) {
		return;
	}

	postscribe(
		getBody(),
		'<script async type="text/javascript" src="https://verticalscope-com.videoplayerhub.com/galleryplayer.js"></script>',
		{
			done: function() {
				loaded.blockthrough = true;
				topifyConsole.info( "loading Blockthrough js tag" );
				fireBlockthrough();
			},
			error: function() {
				topifyConsole.warn( 'error loading: Blockthrough' );
			}
		}
	);
}
function loadLotame() {
	if( !is.Lotame || loaded.Lotame || isAdProviderAllowed( 'Lotame' ) === false ) {
		return;
	}

	postscribe(
		getHead(),
		'<script async id="LOTCC_8060" type="text/javascript" src="https://tags.crwdcntrl.net/c/8060/cc_af_ajax.js?ns=_cc0" ></script>',
		{
			done: function() {
				topifyConsole.info( "loading Lotame js tag" );
				loaded.Lotame = true;
				refreshLotame();
			},
			error: function() {
				topifyConsole.warn( 'error loading: Lotame' );
			}
		}
	);
}
function loadLotame_ccauds() {
	if( !is.Lotame || loaded.Lotame_ccauds || isAdProviderAllowed( 'Lotame' ) === false ) {
		return;
	}

	postscribe(
		getHead(),
		'<script async id="lotame_ccauds_js" type="text/javascript" src="https://ad.crwdcntrl.net/5/c=8059/pe=y/var=ccauds" ></script>',
		{
			done: function() {
				topifyConsole.info( "loading Lotame_ccauds js tag" );
				loaded.Lotame_ccauds = true;
				setTargetingLotame_ccauds();
			},
			error: function() {
				topifyConsole.warn( 'error loading: Lotame_ccauds' );
			}
		}
	);
}
function loadCustomScripts( state ) {
	if( privacySettings.gdpr ) {
		return;
	}

	// Custom ads js
	if( hasCustomScripts( state ) ) {
		state.topifyPresets.forumSite.customscripts.forEach( function( s_data, s_index ) {
			if( s_data['on_refresh'] || loaded.customs === false ) {
				let script_id = ( s_data['on_refresh'] ? '_delete' : '' ) + '_' + s_index;
				if( s_data[ 'url' ] ) {
					postscribe(
						getBody(),
						'<script async id="customjs' + script_id + '" type="text/javascript" src="' + s_data[ 'url' ] + '"></script>',
						{
							done: function() {
								loaded.customs = true;
								topifyConsole.info( "loading custom js tag ", s_index )
							},
							error: function() {
								topifyConsole.warn( 'error loading: customScript', s_index );
							}
						}
					);
				}

				if( s_data[ 'code' ] ) {
					postscribe(
						getBody(),
						'<script id="customcode' + script_id + '" type="text/javascript">' + s_data[ 'code' ] + '</script>',
						{
							done: function() {
								loaded.customs = true;
								topifyConsole.info( "loading custom js code ", s_index )
							},
							error: function() {
								topifyConsole.warn( 'error loading: customCode', s_index );
							}
						}
					);
				}
			}
		} );
	}
}
function loadComScore( state ) {
	if( !is.comScore || loaded.comScore || isAdProviderAllowed( 'comScore' ) === false ) {
		return;
	}

	// Set up comScore functionality before attempting to record any tracking.
	const comscoreScript = `${ useSSL ? 'https://sb' : 'http://b' }.scorecardresearch.com/beacon.js`;

	postscribe(
		getBody(),
		'<script async id="comScoreBeacon" type="text/javascript" src="' + comscoreScript + '"></script>',
		{
			done: function () {
				loaded.comScore = true;
				topifyConsole.info("loading comscore js");
				fireComScore( state );
			},
			error: function() {
				topifyConsole.warn( 'error loading: ComScore');
			}
		}
	);
}
function loadSkimlinks( state ) {
	if( !is.skimlinks || isAdProviderAllowed( 'Skimlinks' ) === false ) {
		return;
	}

	const appContainer = document.getElementById( 'appContainer' );

	// SkimLinks
	// Note that, for VS sites, we only use SkimLinks for guest users.
	if( !is.VS || ( is.VS && !is.userLoggedIn ) ) { // we are in guest only
		const skimlinksId = state.topifyPresets.forumSite.f_skimlinks;

		// if we are loading skimlink we must prevent viglink from loading
		is.viglink = false;

		appContainer.classList.remove( 'noskim' );
		delete window.noskim;
		delete window.noskimlinks;
		delete window.noskimwords;
		delete window.noskimproducts;

		if( !loaded.skimlinks ) {
			postscribe(
				getBody(),
				"<script type='text/javascript' src='https://s.skimresources.com/js/" + skimlinksId + ".skimlinks.js'></script>",
				{
					done: function() {
						topifyConsole.info( 'loading SkimLinks js' );
						loaded.skimlinks = true;
					},
					error: function() {
						topifyConsole.warn( 'error loading: SkimLinks' );
					}
				}
			);
		}
		return true;
	}

	// Adding classes and vars to skip skimlinks
	window.noskim = 'true';
	window.noskimlinks = 'true';
	window.noskimwords = 'true';
	window.noskimproducts = 'true';
	appContainer.classList.add( 'noskim' );
}
function loadViglink( state ) {
	if( !is.viglink || isAdProviderAllowed( 'Viglink' ) === false ) {
		return;
	}

	const appContainer = document.getElementById( 'appContainer' );

	// Note that, for VS sites, we only use VigLink for guest users.
	if( !is.VS || ( is.VS && !is.userLoggedIn ) ) { // we are in guest only
		const forumSite = state.topifyPresets.forumSite;

		appContainer.classList.remove( 'nolinks', 'nooptimize', 'norewrite' );

		if( !loaded.viglink ) {
			postscribe(
				getBody(),
				"<script type='text/javascript'>var vglnk = { api_url: '//api.viglink.com/api',key: '" + forumSite.f_vigkey + "'};(function(d, t) {var s = d.createElement(t); s.type = 'text/javascript'; s.async = true;s.src = ('https:' == document.location.protocol ? vglnk.api_url:'//cdn.viglink.com/api') + '/vglnk.js';var r = d.getElementsByTagName(t)[0]; r.parentNode.insertBefore(s, r);}(document, 'script'));</script>",
				{
					done: function() {
						topifyConsole.info( 'loading viglink js' );
						loaded.viglink = true;
					},
					error: function() {
						topifyConsole.warn( 'error loading: Viglink' );
					}
				}
			);
		}
		return true;
	}

	// Adding classes to skip viglink
	appContainer.classList.add( 'nolinks', 'nooptimize', 'norewrite' );
}
function loadShareThrough() {
	if( !is.sharethrough || loaded.sharethrough || isAdProviderAllowed( 'ShareThrough' ) === false ) {
		return;
	}

	postscribe(
		getBody(),
		'<script async id="sharethrough_js" type="text/javascript" src="https://native.sharethrough.com/assets/sfp.js"></script>',
		{
			done: function() {
				topifyConsole.info( "loading ShareThrough js tag" );
				loaded.sharethrough = true;
			},
			error: function() {
				topifyConsole.warn( 'error loading: ShareThrough');
			}
		}
	);
}
function loadGumGum( state ) {
	if( loaded.gumgum || isAdProviderAllowed( 'GumGum' ) === false ) {
		return;
	}

	if( clearGumGum( state ) ) {
		postscribe(
			getBody(),
			'<script async id="gumgum_js" type="text/javascript" src="https://js.gumgum.com/services.js"></script>',
			{
				done: function() {
					topifyConsole.info( "loading gumgum js tag" );
					loaded.gumgum = true;
				},
				error: function() {
					topifyConsole.warn( 'error loading: gumgum');
				}
			}
		);
	}
}
function loadListBuilder( state ) {
	if( !is.listBuilder || loaded.listBuilder || isAdProviderAllowed( 'ListBuilder' ) === false ) {
		return;
	}

	// define base url for listBuilder, ensure trailing /
	window.tlBaseUrl= ( state.topifyPresets.forumSite.f_url  + '/' ).replace( /\/+$/, '/' );

	postscribe(
		getBody(),
		'<script async id="listBuilder_js" type="text/javascript" src="' + state.topifyPresets.forumSite.f_listbuilder + '"></script>',
		{
			done: function() {
				topifyConsole.info( "loading listBuilder js tag" );
				loaded.listBuilder = true;
			},
			error: function() {
				topifyConsole.warn( 'error loading: listBuilder' );
			}
		}
	);
}
function loadCrossPixel( state ) {
	if( !is.crosspixel || isAdProviderAllowed( 'CrossPixel' ) === false ) {
		return;
	}

	const cb = new Date().getTime(),
		src = `//tag.crsspxl.com/s1.js?d=${ state.topifyPresets.forumSite.f_crosspixel }&cb=${ cb }`;

	postscribe(
		getBody(),
		// async=false to simulate defer => https://developer.mozilla.org/en-US/docs/Web/HTML/Element/script#Attributes
		'<script async="false" id="crosspixel_js" type="text/javascript" src="' + src + '"></script>',
		{
			done: function() {
				topifyConsole.info( "loading crosspixel js tag" );
				loaded.crosspixel = true;
			},
			error: function() {
				topifyConsole.warn( 'error loading: crosspixel');
			}
		}
	);
}
function loadVsInterstitial( state ) {
	// let's skip interstitial until integration

//	willUseInterstitial( state );
//
//	try {
//		if( privacySettings.cookiesOk && is.VSInterstitial && !loaded.VSInterstitial ) {
//			let src = 'https://assets2.topify.com/vendor/vs/vsInterstitialAd_2.0.js';
//			postscribe(
//				getBody(),
//				'<script id="vsInterstitial" type="text/javascript" src="' + src + '"></script>',
//				{
//					done: function() {
//						loaded.VSInterstitial = true;
//						topifyConsole.info( "loading vsInterstitialAd" )
//					},
//					error: function() {
//						topifyConsole.warn( 'error loading: vsInterstitialAd' );
//					}
//				}
//			);
//		}
//	} catch( e ) {
//		topifyConsole.error( 'vsInterstitialAd Error:', e );
//	}
}
function loadLiveRamp( state ) {
	if( isAdProviderAllowed( 'LiveRamp' ) === false ) {
		return;
	}

	try {
		let liverampData = state.ustate.liveramp ? state.ustate.liveramp : false;

		if( liverampData === false ) {
			return;
		}

		let lrSrc = `//pippio.com/api/sync?pid=${liverampData.PID}&it=4&iv=${liverampData.MD5}&it=4&iv=${liverampData.SHA1}&it=4&iv=${liverampData.SHA256}`;
		postscribe(
			getBody(),
			'<script async id="liveramp_script" type="text/javascript" src="' + lrSrc + '"></script>',
			{
				done: function() {
					topifyConsole.info( "loading LiveRamp js" );
				},
				error: function() {
					topifyConsole.warn( 'error loading: LiveRamp' );
				}
			}
		);
	} catch( e ) {
		topifyConsole.error( 'LiveRamp Error:', e );
	}
}
function loadAdomik() {
	if( !is.adomik || loaded.adomik ) {
		return;
	}

	window.Adomik = window.Adomik || {};

	try {
		Adomik.randomAdGroup = function() {
			let rand = Math.random();
			switch( false ) {
				case !( rand < 0.09 ):
					return "ad_ex" + ( Math.floor( 100 * rand ) );
				case !( rand < 0.10 ):
					return "ad_bc";
				default:
					return "ad_opt";
			}
		};

		loaded.adomik = true;
		topifyConsole.info( "loaded Adomik randomizer" );

	} catch( e ) {
		topifyConsole.error( 'Adomik Error:', e );
	}
}

export function loadTaboola() {
	// without consent skip or already loaded
	if( loaded.taboola || isAdProviderAllowed( 'Taboola' ) === false ) {
		return;
	}

	if( is.taboola ||  is.taboola_widget ) {
		window._taboola = window._taboola || [];
		_taboola.push( { article: 'auto' } );

		postscribe(
			getBody(),
			'<script async id="taboola_script" type="text/javascript" src="https://cdn.taboola.com/libtrc/verticalscope-network/loader.js"></script>',
			{
				done: function() {
					loaded.taboola = true;
					topifyConsole.info( "loading taboola loader" );
				},
				error: function() {
					topifyConsole.warn( 'error loading: Taboola loader' );
				}
			}
		);
	}
}

// Ads Permissions
export function checkAdsPermissions( currentForumId = null ) {
	return ( dispatch, getState ) => {
		let state = getState(),
			forumSite = state.topifyPresets.forumSite,
			ustate = state.ustate,
			forumId = typeof currentForumId === 'number' ? currentForumId : false,
			user_groups = ustate.id ? ( ustate.membergroupids ? ustate.membergroupids : [ ustate.usergroupid ] ) : [];

		// Are we browsing a subforum set for "Secondary Ads"?
		if( forumId !== false && forumSite.secondaryAdForumIDs.length > 0 ) {
			let isSecAdsForum = forumSite.secondaryAdForumIDs.some( f_id => parseInt( f_id ) === forumId || parseInt( f_id ) === -999 );
			adsPermissions.secondaryAds = forumSite.isVS && isSecAdsForum;

		} else {
			adsPermissions.secondaryAds = false;
		}

		// Is the user member of a "No Ads User Group"?
		if( user_groups.length > 0 && forumSite.noAdGroupIDs.length > 0 ) {
			adsPermissions.noAdsPermissions = forumSite.noAdGroupIDs.some( groupId => {
				let groupId_int = parseInt( groupId );
				return ( user_groups.findIndex( usergroup => parseInt( usergroup ) === groupId_int ) >= 0 );
			} );
		} else {
			adsPermissions.noAdsPermissions = false;
		}

		// Vendor Ads per user group?
		if( forumSite.vendorAdsGroupIDs.length > 0 ) {
			adsPermissions.vendorAds = forumSite.vendorAdsGroupIDs.some( groupId => {
				let groupId_int = parseInt( groupId );
				switch( groupId_int ) {
					case -1: // vendorAdsGroupIDs: -1 => everybody
						return true;

					case 0: // vendorAdsGroupIDs: 0 => only members
					default: // vendorAdsGroupIDs: > 0  => per user group, matches user group?
						return ( user_groups.length > 0 && groupId_int === 0 ) || user_groups.findIndex( usergroup => parseInt( usergroup ) === groupId_int) >= 0;
				}
			} );
		} else {
			adsPermissions.vendorAds = false;
		}

		topifyConsole.info( 'Ads Permissions', adsPermissions );

		dispatch( setAdsPermissions( adsPermissions ) );

		// We reset hidden ads when we switch sections or ids
		if( lastPathPermsCheck !== state.router.location.pathname ) {
			// save new path
			lastPathPermsCheck = state.router.location.pathname;
			// reset hidden ads
			dispatch( resetHiddenAdsPerUser() );
		}
	};
}

// Secondary ads
export function loadSecondaryAds( type, props, callbackFunc, onErrorFunc ) {
	return ( dispatch, getState ) => {
		let state = getState();

		// check privacy settings because it's call from components
		updatePrivacySettings( state );

		// if no consent trigger error func and exit
		if( isAdProviderAllowed( type ) === false ) {
			topifyConsole.info( 'Secondary ads canceled - privacy settings' );
			if( onErrorFunc ) {
				onErrorFunc();
			}
			return false;
		}

		switch( type ) {
			case 'rubicon':
				loadRubicon( state, props, callbackFunc, onErrorFunc );
		}
	};
}
function loadRubicon( state, props = {}, callbackFunc, onErrorFunc ) {
	let adSizeRubicon = null,
		_rubiconContanier = document.getElementById( props.container );

	if( !props.size || props.size.length < 2 || !_rubiconContanier ) {
		topifyConsole.warn( 'Rubicon Ad incorrect data provided', props );
		if( onErrorFunc ) {
			onErrorFunc();
		}
		return false;
	}

	// Find the Rubicon size
	let RubiconAdSizesMap = {
		"468x60": 1,
		"728x90": 2,
		"120x600": 8,
		"160x600": 9,
		"300x600": 10,
		"300x250": 15,
		"336x280": 16,
		"320x50": 43,
		"300x50": 44,
		"300x1050": 54,
		"970x90": 55,
		"970x250": 57,
		"1000x90": 58,
		"320x480": 67,
		"1800x1000": 68,
		"480x320": 101,
		"768x1024": 102
	};

	let adSize = [ props.size[ 0 ], props.size[ 1 ] ];

	try {
		// use the ad size or fall back to 300x250 (rubicon size 15)
		adSizeRubicon = RubiconAdSizesMap[ adSize[ 0 ] + 'x' + adSize[ 1 ] ] || 15;
		adSize[ 0 ] = adSizeRubicon === 15 ? 300 : props.size[ 0 ];
		adSize[ 1 ] = adSizeRubicon === 15 ? 250 : props.size[ 1 ];

	} catch( e ) {
		topifyConsole.warn( 'Rubicon Ad incorrect size provided', props );
		if( onErrorFunc ) {
			onErrorFunc();
		}
		return false;
	}

	// force iframe size based on ad size
	_rubiconContanier.style.width = `${ adSize[0] }px`;
	_rubiconContanier.style.height = `${ adSize[1] }px`;

	// OnLoad event
	_rubiconContanier.onload = callbackFunc;

	let rp_site = '25062',
		rp_zonesize = `97080-${ adSizeRubicon }`;

	let iFrameContent = `<!DOCTYPE html><html>
		<head><title>rubicon ad</title>
			<style type="text/css">body{margin:0;padding:0;}</style>
			<script>window.rp_account = '1019'; window.rp_site = '${ rp_site }'; window.rp_zonesize = '${ rp_zonesize }'; window.rp_adtype = 'js'; window.rp_smartfile = ''; window.rp_kw = '${ state.topifyPresets.forumSite.f_site_name }';</script>
			<script type="text/javascript" src="https://ads.rubiconproject.com/ad/1019.js"></script>
		</head><body></body>
	</html>`;

	let iFrameDoc = _rubiconContanier.contentWindow.document;
	// Clear and Write the content
	iFrameDoc.open();
	iFrameDoc.write( iFrameContent );
	iFrameDoc.close();

	topifyConsole.log( 'rubicon ad added', props, adSize, rp_site, rp_zonesize );
}

// Fire or Refresh libraries
export function fireCaptify( query ) {
	// if no VS or no consent, skip
	if( is.VS !== true || !is.captify || isAdProviderAllowed( 'Captify' ) === false ) {
		return true;
	}

	// Clear previous pixel
	let captify_js = document.getElementById( 'captify_js' );
	if( captify_js ) {
		captify_js.parentNode.removeChild( captify_js );
	}

	// process keywords: limit to 40, join +
	let captify_kw_limit = 40;
	window.captify_kw_query_11942 = query.trim().split( /\s+/, captify_kw_limit ).join( '+' );

	postscribe(
		getBody(),
		'<script async id="captify_js" type="text/javascript" src="https://p.cpx.to/p/11942/px.js" ></script>',
		{
			done: function() {
				topifyConsole.info( "loading Captify js tag " + ( window.captify_kw_query_11942 ? 'query: ' : '' ), window.captify_kw_query_11942 );
			},
			error: function() {
				topifyConsole.warn( 'error loading: Captify' );
			}
		}
	);
}
// throttle for blockthrough
function btFire() {
	if( loaded.blockthrough && BT.BLOCKER_ENABLED ) {
		BT.clearThrough();
	}
}
export const fireBlockthrough = throttle( btFire, 200, { leading: false, trailing: true } );

function fireComScore( state ) {
	const forumSite = state.topifyPresets.forumSite,
		id = forumSite.f_comscoreid,
		siteName = forumSite.f_site_name;

	if( loaded.comScore && id ) {
		topifyConsole.log( 'fireComScore' );
		let internalTrackComScore = ( id, siteName, retryCount ) => {
			if( window.COMSCORE && window.COMSCORE.beacon ) {
				window.COMSCORE.beacon( { c1: "2", c2: id, c4: siteName } );

			} else if( retryCount < 6 ) { // retry up to 6 times
				window.setTimeout( () => internalTrackComScore( id, siteName, retryCount + 1 ), 500 );
			}
		};

		internalTrackComScore( id, siteName, 1 );
	}
}

function refreshLotame() {
	// Starting and/or Refreshing Lotame
	if( loaded.Lotame ) {
		if( ( typeof _lotameObj === 'undefined' || !_lotameObj ) && typeof _cc0 !== 'undefined' ) {
			// create a new object with ajax tag functionality
			window._lotameObj = new _cc0.ajax();
		}

		if( typeof _lotameObj !== 'undefined' && _lotameObj ) {
			// this will result in the firing of a sync pixel
			_lotameObj.bcpa( true, true, true );
			topifyConsole.log( 'Lotame triggered' );
		}
	}
}
function setTargetingLotame_ccauds() {
	if( loaded.Lotame_ccauds ) {
		if( typeof googletag === 'undefined' ) { return false; }

		if( typeof ccauds !== "undefined" ) {
			( function( ccauds ) {
				let aud_abbr = [], audience = ccauds.Profile.Audiences.Audience;
				for( let cci = 0; cci < audience.length; cci++ ) {
					aud_abbr.push( audience[ cci ].abbr );
				}
				googletag.cmd.push( () => {
					googletag.pubads().setTargeting( "aud_abbr", aud_abbr );
					topifyConsole.log( 'setTargeting Lotame ccauds Audience ', aud_abbr );
				} );
			} )( ccauds );
		}

		googletag.cmd.push( () => {
			if( typeof ccauds !== "undefined" && typeof ccauds.Profile.tpid !== "undefined" && ccauds.Profile.tpid !== "" ) {
				googletag.pubads().setTargeting( "tpid", ccauds.Profile.tpid );
				topifyConsole.log( 'setTargeting Lotame ccauds tpid ', ccauds.Profile.tpid );
			} else {
				googletag.pubads().setTargeting( "tpid", "0" );
				topifyConsole.log( 'setTargeting Lotame ccauds ', 0 );
			}
		} );
	}
}
function refreshVSAPI( state ) {
	// Do we use vsAPI?
	if( loaded.VSAPI ) {
		topifyConsole.log( 'refresh VSAPI' );
		// Trigger vsapi track if we have to
		if( state.threadview.result.posts && state.threadview.result.posts.length > 0 && state.infiniteScroll.page ) {
			topifyConsole.log( 'Clear and init vsApi call for post page for user ', state.ustate.id);
			_vs_beacon.userId = state.ustate.id; // set user id, just in case
			_vs_observe.clear();
			_vs_lotame.init( window.location.hostname, state.threadview.result.posts );

			// What posts are in this page?
			let pageIndex = state.threadview.pages.indexOf( state.infiniteScroll.page ),
				page_size = state.ui.userSettings.pageSize,
				postsInPage = state.threadview.result.posts.slice( pageIndex * page_size, (pageIndex + 1) * page_size );

			// For each post on the page:
			postsInPage.forEach( function( post, index ) {
				if( document.getElementById( 'post_message_' + post ) ) {
					_vs_observe.addPostElement(
						document.getElementById( 'post_container_' + post ),
						document.getElementById( 'post_message_' + post ),
						post,
						parseInt( state.threadview.entities.posts[ post ].topic ),
						parseInt( state.threadview.entities.posts[ post ].forum ),
						parseInt( state.threadview.entities.posts[ post ].forumUser ),
						index + 1
					);
				}
			});
		}
	}
}
function refreshListBuilder() {
	if( loaded.listBuilder && typeof tloom_listBuilder !== 'undefined' && tloom_listBuilder.init ) {
		tloom_listBuilder.init();
		topifyConsole.log( 'ListBuilder refresh' );
	}
}

export function refreshTaboola( type ) {
	return ( dispatch, getState ) => {
		let state = getState();

		// check privacy settings because it is called from components
		updatePrivacySettings( state );

		// without consent skip
		if( isAdProviderAllowed( 'Taboola' ) === false ) {
			return;
		}

		isTaboola( state );

		if( is.taboola_widget || is.taboola ) {
			// load Taboola library
			loadTaboola();

			topifyConsole.groupCollapsed( "taboola ads" );
			topifyConsole.log( "taboola type", type );

			let taboolaData = {},
				_taboolaAd_id = '',
				_taboolaAd_placement = '';

			switch( type ) {
				case 'taboola':
					if( is.taboola ) {
						_taboolaAd_id = 'taboola-mobile-below-article-thumbnails';

						taboolaData = {
							mode: 'thumbnails-f',
							container: _taboolaAd_id,
							placement: 'Mobile Below Article Thumbnails',
							target_type: 'mix'
						};
					}
					break;

				case 'recirculation':
					if( is.taboola_widget ) {
						_taboolaAd_id = 'taboola-mid-article-stream-thumbnails';
						_taboolaAd_placement = is.taboola_widget;

						// clear current slot
						let currentTaboola = document.querySelectorAll( '#taboola-mid-article-stream-thumbnails div.trc_rbox' );
						if( currentTaboola && currentTaboola.length ) {
							[ ...currentTaboola ].forEach( function( item ) {
								item.parentNode.removeChild( item );
							} );
						}

						taboolaData = {
							mode: 'organic-thumbnails-a',
							container: _taboolaAd_id,
							placement: _taboolaAd_placement,
							target_type: 'mix'
						};
					}
					break;
			}

			if( window._taboola && taboolaData.container && document.getElementById( _taboolaAd_id ) ) { // Only if container exists
				topifyConsole.log( "taboola push data", taboolaData );
				window._taboola.push( taboolaData );
				topifyConsole.log( "taboola flush" );
				window._taboola.push( { flush: true } );
			} else {
				topifyConsole.log( 'not loaded' );
			}
			topifyConsole.groupEnd( "taboola ads" );
		}
	}
}

function initialGPTTargetting( state ) {
	topifyConsole.log( 'Initial Targetting GPT' );

	const forumSite = state.topifyPresets.forumSite;

	window.googletag.cmd.push( () => {
		//ForumSite Name Targeting
		googletag.pubads().setTargeting( 'sitename', forumSite.f_site_name );

		if( window.deployads_srt && window.deployads ) { // sortable enable
			deployads.push( () => deployads.gpt.pubadsDisableInitialLoad() );
		} else {
			googletag.pubads().disableInitialLoad();
		}

		//Custom Targeting
		for( let key in forumSite.dfpTargets ) {
			if( forumSite.dfpTargets.hasOwnProperty( key ) ) {
				googletag.pubads().setTargeting( key, forumSite.dfpTargets[ key ] );
			}
		}

		// identify EEA users
		if( privacySettings.gdpr ) {
			googletag.pubads().setTargeting( 'is_eea', 'true' );
		} else {
			googletag.pubads().setTargeting( 'is_eea', 'false' );
		}

		// are we allowed to Personalized Ads?
		googletag.pubads().setRequestNonPersonalizedAds( ( privacySettings.trackUserOk ? 0 : 1 ) );

		topifyConsole.log( 'setting other googletag options' );

		googletag.pubads().setCentering( true );
		googletag.pubads().enableSingleRequest();

		// TODO: who handles and how do we manage this?
//		googletag.pubads().setTagForUnderAgeOfConsent();

		// common settings
		if( window.deployads_srt && window.deployads ) { // sortable enable
			deployads.push( () => deployads.gpt.enableServices() );
		} else {
			googletag.enableServices();
		}

		if( is.prebid ) {
			topifyConsole.log( 'setting prebid targeting' );

			pbjs.que.push( function() {
				pbjs.setTargetingForGPTAsync();
			} );
		}
	} );

	window.googletag.cmd.push( () => {
		if( /topify_adsdebug=true/.test( window.location.search ) ) {
			googletag.pubads().addEventListener( 'slotRenderEnded', function( event ) {
				console.info( '**** Slot has been rendered:', event.slot.u, event );
			} );
			googletag.pubads().addEventListener( 'impressionViewable', function( event ) {
				console.info( '**** impressionViewable', event.slot.u, event );
			} );
		}
	} );
}

export function setTargetingGPT( state ) {
	topifyConsole.log( 'setTargetingGPT' );

	try {
		const path = parsePath( state.router.location.pathname ), // we cannot trust state.routing to be updated at this time
			ustate = state.ustate,
			usergroup = ( typeof ustate.displaygrouptitle !== 'undefined' && ustate.displaygrouptitle ? ustate.displaygrouptitle : 'guest' ),
			metaPath = state.meta.path && state.meta.path.length ? state.meta.path[0] : false,
			currentSubforum_id = metaPath ? metaPath.id : -1,
			currentSubforum_name = metaPath ? metaPath.title : '';

		let currentLegacyUrl = '',
			PageID = '0';

		switch( path[1] || path[0] ) {
			case 'topics':
				PageID = ( typeof state.meta.threadid !== 'undefined' ? state.meta.threadid : 0 );
				currentLegacyUrl = state.threadview.entities.topics ? state.threadview.entities.topics[ PageID ].href : '';
				break;
			case 'forums':
				if( path[2] ) {
					currentLegacyUrl = state.forumview.entities.forums ? state.forumview.entities.forums[ state.forumview.forumId ].href : '';
				} else {
					currentLegacyUrl = state.topifyPresets.forumSite.f_url;
					PageID = 'Homepage';
				}
				break;
			case 'portal':
				let portalpage = state.portalpage;
				currentLegacyUrl = portalpage.result && portalpage.result.portalpage ? state.topifyPresets.forumSite.f_url.replace( /\/$/, '' ) + '/' + portalpage.result.portalpage[0] + '.html' : '';
				PageID =  !( portalpage.result && portalpage.result.portalpage ) ? 'cmps' : ( /insurance/i.test( portalpage.result.portalpage[0] ) ? 'insurance' : portalpage.result.portalpage[0] );
				break;
			case '/':
				currentLegacyUrl = state.topifyPresets.forumSite.f_url;
				PageID = 'Homepage';
				break;
		}

		// ensure targeting for Lotame ccauds are set
		setTargetingLotame_ccauds();

		window.googletag.cmd.push( () => {
			// are we allowed to Personalized Ads?
			googletag.pubads().setRequestNonPersonalizedAds( ( privacySettings.trackUserOk ? 0 : 1 ) );

			// Sharethrough targetting if we are not GDPR
			if( is.sharethrough && isAdProviderAllowed( 'ShareThrough' ) ) {
				googletag.pubads().setTargeting( "strNativeKey", state.topifyPresets.forumSite.f_sharethrough );
			}

			// update the dynamic targeting values
			googletag.pubads().setTargeting( 'forum_id', currentSubforum_id + '' );
			googletag.pubads().setTargeting( 'forum_name', currentSubforum_name );
			googletag.pubads().setTargeting( 'group_id', usergroup );
			googletag.pubads().setTargeting( 'registered', is.userLoggedIn + '' );
			googletag.pubads().setTargeting( 'PageID', PageID + '' );

			googletag.pubads().set( "page_url", currentLegacyUrl );

			let page = document.getElementById( 'page' ),
				page_backgroundColor = page ? window.getComputedStyle( page ).getPropertyValue( 'background-color' ) : '';
			googletag.pubads().set( 'adsense_background_color', page_backgroundColor );
		} );

		window.googletag.cmd.push( () => {
			// Set the GPS info. that is only gathered once when the web app loads.
			//GEO Location
			if( state.ads.geoLocation.latitude && state.ads.geoLocation.longitude ) {
				topifyConsole.log( 'setting googletag location' );
				googletag.pubads().setLocation( state.ads.geoLocation.latitude, state.ads.geoLocation.longitude );
			}
		} );

	} catch( e ) {
		topifyConsole.error( 'setTargetingGPT setTargeting ERROR ', e );
	}
}

// Create a observers for DOM mutations on head and body
const observerConfig = { childList: true }; // configuration of the observer
let checkExcludedFromDelete = ( node ) => {
		// urls to exclude from deletion
		const excludedSRC = [
				'google-analytics.com/ga.js',
				'google-analytics.com/analytics.js',
				'google.com/adsense/search/async-ads.js',
				'googletagservices.com/tag/js/gpt.js'
			],
			// script Ids
			excludedIDs = [
				'prebid_src',
				'vsIframeMessenger', 'vsInterstitial',
				'comScoreBeacon', 'captify_js',
				'LOTCC_8060', 'lotame_ccauds_js'
			],
			// styles content
			excludedStyles = ['topifyKeep', 'trc_rbox_container', 'qc-cmp-ui-showing' ],
			// Div ids
			excludedDivIDs = [],
			// Div Classes
			excludedDivClasses = [ 'topifyKeep', 'ym', 'ym-wrapper-left', 'ym-wrapper-right', 'ym-wrapper-meter' ],
			// link tags
			excludedLinkIDs = [],
			excludedLinkHref = [ 'cdn.threadloom.com' ];

		switch( node.nodeName ) {
			case 'SCRIPT':
				return node.src && (
					( node.id && excludedIDs.some( ( js ) => node.id === js ) ) || // if we have id check it first
					excludedSRC.some( ( js ) => ( new RegExp( js, 'i' ) ).test( node.src ) )
				);

			case 'STYLE':
				return excludedStyles.some( ( style ) => ( new RegExp( style ) ).test( node.textContent ) );

			case 'DIV':
				let classList = node.classList || [];
				return ( classList.length > 0 && excludedDivClasses.some( ( className ) => classList.contains( className ) ) ) ||  // Classes exclusion
					(  // Id's exclusion
						node.id !== undefined && (
							( excludedDivIDs.some( ( div ) => node.id === div ) ) ||
							( window.GUMGUM !== undefined && node.id === window.GUMGUM.container.id ) // GUMGUM
						)
					);

			case 'LINK':
				return node.href && (
					( node.id && excludedLinkIDs.some( id => node.id === id ) ) || // if we have id check it first
					excludedLinkHref.some( href => ( new RegExp( href, 'i' ) ).test( node.href ) )
				);
		}

		return false;
	},
	trackAddedNodes = ( mutations ) => {
		mutations.forEach( ( mutation ) => {
			// convert nodeList into an array
			// avoiding nodeList.forEach polyfill for iOS8 + Chrome
			[ ...mutation.addedNodes ].forEach( node => {
				if( checkExcludedFromDelete( node ) === false ) {
					insertedNodes.push( node );
				}
			} );

			// If there are removed nodes, remove them from our array
			if( mutation.removedNodes.length ) {
				[ ...mutation.removedNodes ].forEach( ( removed ) => {
					let index = insertedNodes.findIndex( e => e === removed );
					if( index >= 0 ) {
						insertedNodes.splice( index, 1 );
					}
				});
			}
		} );
	},
	_mutationObserver_Body = new MutationObserver( trackAddedNodes ),
	_mutationObserver_Head = new MutationObserver( trackAddedNodes );

// Cleans elements from previous page view
export function cleanOldAdElements() {
	topifyConsole.groupCollapsed( "cleaning ad elements" );
	topifyConsole.time("cleaning ad elements");

	// Stop Observers
	_mutationObserver_Head.disconnect();
	_mutationObserver_Body.disconnect();

	// Elements
	let elementsToRemove = [ ...insertedNodes ];

	// remove the elements
	elementsToRemove.forEach( item => {
		try {
			// skip GumGum container
			if( window.GUMGUM && item.id && item.id === window.GUMGUM.container.id ) {
				return;
			}
			item.parentNode.removeChild( item );
		} catch( e ) { /* ignore */ }
	} );

	topifyConsole.timeEnd("cleaning ad elements");
	topifyConsole.log( 'Total Elements:', elementsToRemove.length );
	topifyConsole.groupEnd( "cleaning ad elements" );

	insertedNodes = [];
	_mutationObserver_Head.observe( getHead(), observerConfig );
	_mutationObserver_Body.observe( getBody(), observerConfig );
}
export function yieldmoWrapperCleaner() {
	topifyConsole.time("cleaning yieldmo wrapper");
	// Yieldmo Wrapper cleaner - styles and class in <html> tag :?
	let ym_wrapper = window.top.document.getElementsByClassName("ym-phone");
	if( ym_wrapper && ym_wrapper.length ) {
		ym_wrapper[0].style.cssText = null;
		ym_wrapper[0].classList.remove( 'ym-phone' );
	}
	// Cleaning styles and childs of Yieldmo Wrapper elements, that makes the trick :|
	[ ...document.querySelectorAll( '.ym, .ym-wrapper-left, .ym-wrapper-right, .ym-wrapper-meter' ) ].forEach( el => {
		el.style.cssText = null; // cleaning styles...
		while( el.firstChild ) {
			el.removeChild( el.firstChild ); // no elements inside
		}
	} );

	topifyConsole.timeEnd("cleaning yieldmo wrapper");
}

// Google analytics
function isValidAnalyticsID( value ) {
	let pattern = new RegExp( "^UA-\\d{4,10}(-\\d{1,4})?$" );
	return pattern.test( value );
}
function getTrackingUrl( state ) {
	let rtnUrl = '/';
	try {
		if( state.router.location ) {
			rtnUrl = state.router.location.pathname;

			let query = state.router.location ? queryString.parse( state.router.location.search ) : {};

			// always include page if  > 1 unless we are searching for a post id
			if( query.postid === undefined && ( state.meta.page > 1 || state.infiniteScroll.page > 1 ) ) {
				// it's a search? grab query.page otherwise use meta or infinitescroll
				rtnUrl += '?page=' + ( query.q ? query.page : ( state.infiniteScroll.page || state.meta.page ) );
			}
			// process the other query stuff
			for( let v in query ) {
				if( v !== 'page' ) {
					rtnUrl +=  ( rtnUrl.lastIndexOf( '?' ) < 0 ? '?' : '&' );
					rtnUrl += v + '=' + query[ v ];
				}
			}
		} else {
			// let's grab by the current url
			throw new Error( 'Cannot get URL from router on getTrackingUrl' );
		}
	} catch( e ) {
		if( window.location.hash && (window.location.hash.length > 1) ) {
			rtnUrl = window.location.hash.substr( 1 );

			if( rtnUrl.lastIndexOf( '_k=' ) >= 0 ) {
				rtnUrl = rtnUrl.substring( 0, rtnUrl.lastIndexOf( '_k=' ) - 1 );
			}
		}
	}

	return rtnUrl;
}

export function trackPageView() {
	return ( dispatch, getState ) => {
		if( privacySettings.cookiesOk !== true || typeof ga === 'undefined' ) {
			return;
		}

		const state = getState();
		// Defined dimensions:
		// dimension1 = forumId - hit
		// dimension2 = appType - session
		// dimension3 = forumUserGroup - hit
		// dimension4 = Network Status - hit
		let page = getTrackingUrl( state ),
			f_analytics_id = state.topifyPresets.forumSite.f_analytics_id,
			f_id = state.topifyPresets.forumSite.f_id + '',
			usergroup = ( typeof state.ustate.displaygrouptitle !== 'undefined' && state.ustate.displaygrouptitle ? state.ustate.displaygrouptitle : 'guest' ),
			data = {
				page: page,
				appName: 'Topify Browser V3',
				dimension1: f_id,
				dimension3: usergroup
			};

		// let's track only the landing page
//		if( pageCounter <= 1 ) {
			// do we need to anonymize IPs?
			if( privacySettings.trackUserOk !== true ) {
				ga( analyticsTrackerName + '.set', 'anonymizeIp', true );
			}
			ga( analyticsTrackerName + '.set', data );
			ga( analyticsTrackerName + '.send', 'pageview' );
			topifyConsole.info( 'GA PAGEVIEW ', data );
//		} else {
//			topifyConsole.info( 'SKIP GA PAGEVIEW ', pageCounter, data );
//		}

		if( f_analytics_id && isValidAnalyticsID( f_analytics_id ) ) {
			if( is.VS ) {
				// We do special handling for VerticalScope forums to record a specific domain
				// as well as the current user's group in a custom variable.
				if( process.env.NODE_ENV === 'production' && _gaq ) {
					_gaq.push( [ '_setAccount', f_analytics_id ] );

					// do we need to anonymize IPs?
					if( privacySettings.trackUserOk !== true ) {
						_gaq.push( [ '_gat._anonymizeIp' ] );
					}

					_gaq.push(
						[ '_setDomainName', state.topifyPresets.forumSite.f_site_name ],
						[ '_setCustomVar', 1, 'grp', usergroup ],
						[ '_setCustomVar', 2, 'topify', '1' ],
						[ '_trackPageview', page ]
					);
					topifyConsole.info( 'GA PAGEVIEW (VS) - analyticsId: ' + f_analytics_id + ' - page: ' + page + ' - forumSiteId: ' + f_id + ' - domain: ' + state.topifyPresets.forumSite.f_site_name + ' - group: ' + usergroup + ' - topify: 1' );
				}

			} else {
				// all non VS
				ga( function() {
					if( !ga.getByName( f_id ) ) {
						ga( 'create', f_analytics_id, { name: f_id } );
						topifyConsole.info( 'GA PAGEVIEW - create tracker: ' + f_analytics_id + ' - forumId: ' + f_id );
					}
					data = {
						page: page,
						appName: 'Topify Browser V3'
					};

					// do we need to anonymize IPs?
					if( privacySettings.trackUserOk !== true ) {
						ga( f_id + '.set', 'anonymizeIp', true );
					}

					ga( f_id + '.set', data );
					ga( f_id + '.send', 'pageview' );

					topifyConsole.info( 'GA PAGEVIEW - analyticsId: ' + f_analytics_id + ' - data: ', data );
				} );
			}
		}
	};
}

/**
 * Sends event to GA
 *
 * @param sendAnalytics bit field 1: Topify Analytics, 2: Forum Analytics
 * @param label
 * @param optionalCategory
 * @param optionalAction
 * @param optionalValue
 * @param optionalForcedPage
 */
export function trackGAEvent( sendAnalytics, label = 'unknown', optionalCategory = 'topify', optionalAction = 'event', optionalValue = null, optionalForcedPage ) {
	return ( dispatch, getState ) => {
		const state = getState();

		// check privacy settings
		updatePrivacySettings( state );

		if( privacySettings.cookiesOk !== true || typeof ga === 'undefined' ) {
			return;
		}

		let page = optionalForcedPage || getTrackingUrl( state ),
			f_analytics_id = state.topifyPresets.forumSite.f_analytics_id,
			f_id = state.topifyPresets.forumSite.f_id + '',
			usergroup = ( typeof state.ustate.displaygrouptitle !== 'undefined' && state.ustate.displaygrouptitle ? state.ustate.displaygrouptitle : 'guest' ),
			data = {
				hitType: 'event',
				eventCategory: optionalCategory,
				eventAction: optionalAction,
				eventLabel: label,
				eventValue: optionalValue,

				page: page,
				dimension1: f_id,
				dimension3: usergroup
			};

		if( sendAnalytics & 1 ) {
			ga( function() {
				// do we need to anonymize IPs?
				if( privacySettings.trackUserOk !== true ) {
					ga( analyticsTrackerName + '.set', 'anonymizeIp', true );
				}

				ga( analyticsTrackerName + '.send', data );
				topifyConsole.log( 'GA EVENT - analyticsId: ' + topifyGA_id + ' - data: ', data );
			} );
		}

		if( ( sendAnalytics & 2 ) && f_analytics_id && isValidAnalyticsID( f_analytics_id ) ) {
			if( is.VS ) {
				// We do special handling for VerticalScope forums to record a specific domain
				// as well as the current user's group in a custom variable.
				if( process.env.NODE_ENV === 'production' && _gaq ) {
					if( optionalValue === 'hotDeals' ) {
						_gaq.push( [ "_trackEvent", optionalCategory, optionalAction, "true", null, true ] );

					} else {
						// reset values for forum analytics
						optionalCategory = 'topify';
						optionalAction = 'event';

						_gaq.push( [ '_setAccount', f_analytics_id ] );
						// do we need to anonymize IPs?
						if( privacySettings.trackUserOk !== true ) {
							_gaq.push( [ '_gat._anonymizeIp' ] );
						}
						_gaq.push(
							[ '_set', 'page', page ],
							[ '_setDomainName', state.topifyPresets.forumSite.f_site_name ],
							[ '_setCustomVar', 1, 'grp', usergroup ],
							[ '_setCustomVar', 2, 'topify', '1' ],
							[ '_trackEvent', optionalCategory, optionalAction, label, optionalValue, true ]
						);
					}
				}
				topifyConsole.log( 'GA EVENT (VS) - analyticsId: ' + f_analytics_id + ' - page: ' + page + ' - forumSiteId: ' + f_id + ' - domain: ' + state.topifyPresets.forumSite.f_site_name + ' - group: ' + usergroup + ' - topify: 1 - ' + optionalCategory + ' - ' + optionalAction + ' - ' + label + ' - ' + optionalValue );

			} else {
				// all non VS
				data.eventCategory = 'topify';
				data.eventAction = 'event';
				data.appName = 'Topify Browser V3';
				delete data.dimension1;
				delete data.dimension3;

				ga( function() {
					// do we need to anonymize IPs?
					if( privacySettings.trackUserOk !== true ) {
						ga( f_id + '.set', 'anonymizeIp', true );
					}

					ga( f_id + '.send', data );
					topifyConsole.log( 'GA EVENT - analyticsId: ' + f_analytics_id + ' - forumId: ' + f_id + ' - data: ', data );
				} );
			}
		}
	};
}

// Gets user location if they allow us
export function getGeoLocation() {
	return ( dispatch, getState ) => {
		const state = getState();
		let geoLocation = {
			allow: true,
			error: false,
			latitude: '',
			longitude: '',
			timestamp: 0
		};

		if( state.ads.geoLocation.allow === false ) {
			topifyConsole.info( 'geoLocation NOT allowed' );
			return;
		}

		// Checking last geo check
		if( !useSSL || Date.now() - geoLocationRefresh < state.ads.geoLocation.timestamp ) {
			return true;
		}

		geoLocation.timestamp = Date.now();

		navigator.geolocation.getCurrentPosition(
			function( position ) {
				geoLocation.latitude = position.coords.latitude;
				geoLocation.longitude = position.coords.longitude;
				topifyConsole.log( 'geoLocation updated: ', geoLocation );

				// Updating googletag targeting
				if( window.googletag ) {
					topifyConsole.log( 'Targetting googleads geoLocation' );

					window.googletag.cmd.push( () => {
						googletag.pubads().setLocation( geoLocation.latitude, geoLocation.longitude );
					} );
				}
				dispatch( setGeoLocation( geoLocation ) );
			},
			function( error ) {
				switch( error.code ) {
					case error.PERMISSION_DENIED:
						geoLocation.allow = false;
						break;
					case error.POSITION_UNAVAILABLE:
					case error.TIMEOUT:
					case error.UNKNOWN_ERROR:
						geoLocation.error = true;
						break;
				}
				dispatch( setGeoLocation( geoLocation ) );
			},
			{
				timeout: 10 * 1000
			}
		);
	}
}

// Init Ads: check and loads general libraries, on first load
export function preInitAds() {
	topifyConsole.info( 'PreInit Ads' );
	if( window.performance && window.performance.mark ) {
		window.performance.mark( 'preinit_ads' );
	}

	return ( dispatch, getState ) => {
		let state = getState();

		// check privacy settings
		updatePrivacySettings( state );

		// Run pre-options
		isVS( state );
		isUserLoggedIn( state );

		initEvents( dispatch );

		loadVsInterstitial( state );
		isLotame( state );
		isPrebid( state );
		isSortable( state ); // must go after 'isPrebid'
		isCrossPixel( state );
		isAdomik( state );
		isBlockthrough( state );
		isCaptify( state );
		isShareThrough( state );
		isComScore( state );
		isGumGum( state );
		isSkimLinks( state );
		isViglink( state );
		isListBuilder( state );

		initialGPTTargetting( state );
	}
}

export function initAdLibraries() {
	if( loaded.init ) {
		return true;
	}

	topifyConsole.info( 'Init Ads' );
	if( window.performance && window.performance.mark ) {
		window.performance.mark( 'init_ads' );
	}

	// Stop Observers, just in case
	_mutationObserver_Head.disconnect();
	_mutationObserver_Body.disconnect();

	return ( dispatch, getState ) => {
		let state = getState();

		// check privacy settings
		updatePrivacySettings( state );

		// pass in the target node, as well as the observer options
		_mutationObserver_Head.observe( getHead(), observerConfig );
		_mutationObserver_Body.observe( getBody(), observerConfig );

		// if we are GDPR and no consent processed yet: quit
		if( privacySettings.gdpr && !privacySettings.cookiesOk ) {
			// Check old consent
			if( getCookie( 'uk-cookie-legal' ) === "been-shown" ) {
				document.dispatchEvent( new CustomEvent( 'topifyGDPRConsent', { detail: { fcStatus: -1, cookiesOk: true } } ) );
			}

			return;
		}

		window.googletag = window.googletag || { cmd: [] };
		topifyConsole.log( 'DEFINED: googletag' );

		window._gaq = window._gaq || [];
		topifyConsole.log( 'DEFINED: _gaq' );

		if( is.prebid ) {
			window.pbjs = window.pbjs || {};
			window.pbjs.que = window.pbjs.que || [];
			topifyConsole.log( 'DEFINED: pbjs' );
		}

		loadLotame();
		loadLotame_ccauds();

		loadAdomik();

		// Load Main libraries
		initGoogleAds( state ).then( () => {
			// load other libraries
			loadVSAPI( state );
			loadBlockthrough();
			loadCustomScripts( state );
			loadGumGum( state );
			loadComScore( state );
			loadCrossPixel( state );
			loadShareThrough();
			loadListBuilder( state );

			loaded.init = true;
			if( window.performance && window.performance.mark ) {
				window.performance.mark( 'init_ads_done' );
			}

			// when we trigger onPageView before init is loaded
			// we'll recover the call
			if( loaded.pageView && loaded.pageView !== true ) {
				dispatch( onPageView( loaded.pageView ) );
			}
		} );
	};
}

// On every page view
export function onPageView( triggerValues = null ) {
	topifyConsole.info( 'Ads onPageView', triggerValues );

	// we don't need pageCounter greater than 10, keep it there
	if( pageCounter < 10 && loaded.init ) {
		pageCounter++;
	}

	if( pageCounter > 1 ) {
		cleanOldAdElements();
	}

	return ( dispatch, getState ) => {
		let state = getState();

		// We trigger onPageView before init loaded,
		// save values and skip
		if( loaded.init !== true ) {
			topifyConsole.info( 'Ads onPageView waiting for initAdLibraries to finish' );
			loaded.pageView = triggerValues || {}; // save values so init knows what's pending
			return false;
		}

		// check privacy settings
		updatePrivacySettings( state );

		isUserLoggedIn( state );

		// if we are EU and no consent processed yet: quit
		if( privacySettings.gdpr && !privacySettings.cookiesOk ) {
			return;
		}

		if( triggerValues && triggerValues.pageTransition ) {
			// We want to trigger this event when we switch sections
			document.dispatchEvent( onPageViewEvent );
		}

		// Record the Google Analytics pageview
		dispatch( trackPageView() );

//		if( pageCounter >= 2 && pageCounter < 3 ) {
//			dispatch( getGeoLocation() );
//		}

		// Refresh Ad Libraries
		if( triggerValues && 'undefined' !== typeof triggerValues.captify ) {
			// Captify needs to be triggered once per section
			fireCaptify( triggerValues.captify );
		}

		loadSkimlinks( state );
		loadViglink( state );
		loadLiveRamp( state );
		// Trigger GumGum when we switch between sections
		if( triggerValues && triggerValues.pageTransition ) {
			loadGumGum( state );
		}

		fireBlockthrough();
		fireBlockthrough.flush();

		if( pageCounter > 1 ) {
			fireComScore( state );
			refreshLotame();
			loadCustomScripts( state );
			refreshVSAPI( state );
			setTargetingGPT( state );
			refreshListBuilder();
			loadCrossPixel( state );
		} else {
			setTargetingGPT( state );
		}

		if( goInterstitial() ) {
			dispatch( showInterstitial() );
		} else if( state.ads.showInterstitial ) {
			dispatch( hideInterstitial() );
		}

		loaded.pageView = true;
	};
}
