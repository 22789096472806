export const TOGGLE_SIDEBAR_ON = 'TOGGLE_SIDEBAR_ON';
export const TOGGLE_SIDEBAR_OFF = 'TOGGLE_SIDEBAR_OFF';
export const TOGGLE_SEARCHBAR = 'TOGGLE_SEARCHBAR';
export const TOGGLE_HEADER_OVERFLOW = 'TOGGLE_HEADER_OVERFLOW';
export const RESET_OVERLAY = 'RESET_OVERLAY';
export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL';
export const HIDE_LOGIN_MODAL = 'HIDE_LOGIN_MODAL';
export const SHOW_CONFIRM_MODAL = 'SHOW_CONFIRM_MODAL';
export const HIDE_CONFIRM_MODAL = 'HIDE_CONFIRM_MODAL';
export const SHOW_GLOBAL_LOADING = 'SHOW_GLOBAL_LOADING';
export const HIDE_GLOBAL_LOADING = 'HIDE_GLOBAL_LOADING';
export const SHOW_REPLY_MODAL = 'SHOW_REPLY_MODAL';
export const HIDE_REPLY_MODAL = 'HIDE_REPLY_MODAL';
export const SHOW_REPLY_MODAL_LOADER = 'SHOW_REPLY_MODAL_LOADER';
export const HIDE_REPLY_MODAL_LOADER = 'HIDE_REPLY_MODAL_LOADER';
export const SHOW_REPLY_MODAL_ATTACHMENT_LOADER = 'SHOW_REPLY_MODAL_ATTACHMENT_LOADER';
export const HIDE_REPLY_MODAL_ATTACHMENT_LOADER = 'HIDE_REPLY_MODAL_ATTACHMENT_LOADER';
export const SHOW_USER_MODAL = 'SHOW_USER_MODAL';
export const HIDE_USER_MODAL = 'HIDE_USER_MODAL';
export const TOGGLE_SHARE_MENU = 'TOGGLE_SHARE_MENU';
export const OPEN_ANNOUNCEMENTS = 'OPEN_ANNOUNCEMENTS';
export const CLOSE_ANNOUNCEMENTS = 'CLOSE_ANNOUNCEMENTS';
export const TOGGLE_REGISTRATIONBAR = 'TOGGLE_REGISTRATIONBAR';

import Alert from 'react-s-alert';

export function resetOverlay() {
	return {
		type: RESET_OVERLAY
	};
}

export function toggleHeaderOverflow() {
	return {
		type: TOGGLE_HEADER_OVERFLOW
	};
}

export function toggleSearchBar() {
	return {
		type: TOGGLE_SEARCHBAR
	};
}

export function sidebarOn() {
	return {
		type: TOGGLE_SIDEBAR_ON
	};
}

export function sidebarOff() {
	return {
		type: TOGGLE_SIDEBAR_OFF
	};
}

export function showLoginModal() {
	return {
		type: SHOW_LOGIN_MODAL
	};
}

export function hideLoginModal() {
	return {
		type: HIDE_LOGIN_MODAL
	};
}

export function showConfirmModal( header, text, action ) {
	return {
		type: SHOW_CONFIRM_MODAL,
		header: header,
		text: text,
		action: action
	};
}

export function hideConfirmModal() {
	return {
		type: HIDE_CONFIRM_MODAL
	};
}

export function displayNotification( type, message, conf = {} ) {
	if( conf.force ) {
		Alert.closeAll();
	}

	let notificationId = '',
		configuration = Object.assign( {}, conf );

	switch( type ) {
		case 'reset':
			Alert.closeAll();
			break;
		case 'hide':
			if( configuration.id ) {
				Alert.close( configuration.id );
			}
			break;
		case 'success':
			notificationId = Alert.success( message, configuration );
			break;
		case 'info':
			notificationId = Alert.info( message, configuration );
			break;
		case 'alert':
		case 'warning':
			notificationId = Alert.warning( message, configuration );
			break;
		case 'error':
			notificationId = Alert.error( message, configuration );
			break;
		case 'notices':
			notificationId = Alert.info( message, configuration );
			break;
		default:
			break;
	}

	return notificationId;
}

export function showGlobalLoading( text ) {
	return {
		type: SHOW_GLOBAL_LOADING,
		text: text
	};
}

export function hideGlobalLoading() {
	return {
		type: HIDE_GLOBAL_LOADING,
		text: false
	};
}

export function showReplyModal( options ) {
	return {
		type: SHOW_REPLY_MODAL,
		options
	};
}

export function hideReplyModal() {
	return {
		type: HIDE_REPLY_MODAL
	};
}

export function	showReplyModalLoader(){
	return {
		type: SHOW_REPLY_MODAL_LOADER
	};
}

export function	hideReplyModalLoader(){
	return {
		type: HIDE_REPLY_MODAL_LOADER
	};
}

export function showReplyModalAttachmentLoader(){
	return {
		type: SHOW_REPLY_MODAL_ATTACHMENT_LOADER
	};
}

export function hideReplyModalAttachmentLoader(){
	return {
		type: HIDE_REPLY_MODAL_ATTACHMENT_LOADER
	};
}

export function showUserModal(path, user) {
	return {
		type: SHOW_USER_MODAL,
		path,
		user
	};
}

export function hideUserModal() {
	return {
		type: HIDE_USER_MODAL,
		path: [],
		user: {}
	};
}

export function toggleShareMenu() {
	return {
		type: TOGGLE_SHARE_MENU
	}
}

export function openAnnouncements() {
	return {
		type: OPEN_ANNOUNCEMENTS
	};
}

export function closeAnnouncements() {
	return {
		type: CLOSE_ANNOUNCEMENTS
	};
}

export function registrationBarToggle() {
	return {
		type: TOGGLE_REGISTRATIONBAR
	};
}