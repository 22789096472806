import fixUrlProtocol from 'Utils/fixUrlProtocol';

export function getUserInfo( userId = null ) {
	return ( dispatch, getState ) => {
		if( userId === null ) {
			return {};
		}

		const state = getState();
		let defaultUser = {
			id: 0,
			userName: 'Guest',
			avatar: 'https://staticimages.topify.com/default_avatar.png'
		}, user;

		// Let find the users array
		for( let view in state ) {
			if( state[ view ].entities && state[ view ].entities.users && state[ view ].entities.users[ userId ] ) {
				user = { ...defaultUser, ...state[ view ].entities.users[ userId ] };
				break;
			}
		}

		// fix protocol for user avatar
		if( user ) {
			user.avatar = fixUrlProtocol( user.avatar || defaultUser.avatar );
		}

		return user || defaultUser;
	}
}

export function currentUser() {
	return ( dispatch, getState ) => {
		const state = getState();
		let userId = state.ustate.id || 0,
			user = dispatch( getUserInfo( userId ) );

		user = {
			id: parseInt( userId ),
			userName: state.ustate.userName || user.userName,
			avatar: state.ustate.avatar || user.avatar
		};

		return user;
	}
}

export * from 'Common/actions/ContextInfoActions';