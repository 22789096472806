import format from 'date-fns/format';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';

export default function prettyDate( epoch, dateFormat = 'MMM DD, YYYY' ) {
	let time = epoch * 1000;

	if( dateFormat === 'datetime' ) {
		return format( time );
	}

	if( Date.now() - time > ( 5 * 86400000 ) ) { // before 5 days
		return format( time, dateFormat )
	} else {
		return distanceInWordsToNow( time, {
			addSuffix: true,
			includeSeconds: true
		} );
	}
}
