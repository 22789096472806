import { normalize, schema } from 'normalizr';

import { parsePath } from 'Utils/nav/NavHelpers';

import {
	fetchReleaseNotes,
	receiveReleaseNotes,
	failedReleaseNotes
} from 'Common/actions/ReleaseNotesActions';

export * from 'Common/actions/ReleaseNotesActions';

import { receiveGlobalMeta } from 'Actions/MetaActions';
import {
	setLastGlobalAction,
	getFetchConfig,
	offLineNotification,
	simpleFailFetchResponse
} from 'Actions/GlobalMetaActions';
import {
	getLoadingBehavior,
	shouldNotUpdateState,
	updatePagesResult,
	fetchSuccess
} from 'Actions/InfiniteScrollActions';
import { getPageSize } from 'Actions/UserSettingsActions';

const note = new schema.Entity( 'notes', {}, { idAttribute: 'n_id' } );

export const whatReleaseNotes = (pageNum,forceRefresh) => {

	return (dispatch,getState) => {

		const page = parseInt(pageNum),
			notesRequest = {
				page,
				forceRefresh
			},
			loadingBehavior = getLoadingBehavior(getState, null, "releaseNotes", page, forceRefresh);

		if ( shouldNotUpdateState(getState, notesRequest) ) {
			dispatch( updatePagesResult( pageNum || 1 ) );
			setLastGlobalAction('releaseNotes',notesRequest);
			return null;
		}

		dispatch( fetchReleaseNotes(loadingBehavior) );

		const state = getState(),
			currentPath = parsePath( document.location.hash ),
			preFetchPath = currentPath.length > 1 ? `/${ currentPath[ 1 ] }${ currentPath[ 2 ] ? '/' + currentPath[ 2 ] : '' }` : currentPath[ 0 ];

		const page_size = dispatch( getPageSize() ),
			version = TOPIFY_VERSION.match( /([^+]*).*?/ ),
			url = state.topifyPresets.ffUrl + 'm/getnotifications?v=' + encodeURIComponent( version[ 1 ] || TOPIFY_VERSION ) + '&ajax=1',
			config = dispatch( getFetchConfig() );

		return fetch(`${url}&pagesize=${page_size}&page=${page}`, config)
			.then( response => {
				if ( !response.ok) {
					dispatch( simpleFailFetchResponse( response ) );
					return null;
				} else {
					return response.json();
				}
			})
			.then( json => {
				if ( !json ) { return; }

				// preventing race condition when we switch page and
				// we still have pending a response from the server.
				// if currentLocation === '/' we are on first load
				let currentLocation = getState().router.location.pathname;
				if( currentLocation !== '/' && preFetchPath !== currentLocation ) {
					return;
				}

				let preNormalized = {
						notes: ( json.notifications_topify || { data: [] } ).data.map( e => {
							return Object.assign( e, { page: parseInt( page ) || 1 } );
						} )
					},
					other = {
						page: page || 1
					},
					result = Object.assign(
						{},
						other,
						{},
						normalize( preNormalized,
							{
								notes: [ note ],
							}
						)
					);

				let totalPages = Math.ceil( json.notifications_topify.total / page_size );

				dispatch( receiveGlobalMeta( null, "Topify Release Notes" ) );
				dispatch( fetchSuccess( null, "releaseNotes", page, totalPages, loadingBehavior ) );
				dispatch( receiveReleaseNotes( result, loadingBehavior ) );
			})
			.then(() => {
				setLastGlobalAction('releaseNotes',notesRequest);

			} ).catch( error => {
				dispatch( failedReleaseNotes( 'No connection' ) );
				dispatch( offLineNotification( error, page ) );
			} );
	};
};