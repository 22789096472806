export const TOGGLE_SELECTED_ITEM = 'TOGGLE_SELECTED_ITEM';
export const RESET_SELECTED_ITEM = 'RESET_SELECTED_ITEM';
export const SET_CACHE_BUST = 'SET_CACHE_BUST';
export const SET_GLOBAL_META = 'SET_GLOBAL_META';

export const RESET_META_LOADING = 'RESET_META_LOADING';

export const SET_META = 'SET_META';
export const ONLINE_STATUS = 'ONLINE_STATUS';

export const toggleSelectedItem = ( id ) => {
	return {
		type: TOGGLE_SELECTED_ITEM,
		id
	}
};

export const resetSelectedItem = () => {
	return {
		type: RESET_SELECTED_ITEM
	}
};

export const setCacheBuster = () => {
	try {
		navigator.serviceWorker.controller.postMessage( {
			topify: true,
			process: 'cacheBust'
		} );

	} catch( e ) {
		topifyConsole.log( 'setCacheBust - no SW available' );
	}

	return {
		type: SET_CACHE_BUST,
		cacheBust: Date.now()
	}
};

export const getCacheBuster = ( getState, cacheBust = false ) => {
	// When we have a SW it will manage the caching
	return ( 'serviceWorker' in navigator && navigator.serviceWorker.controller ? 0 : ( cacheBust ? Date.now() : getState().meta.cacheBust || 0 ) );
};

export const receiveGlobalMeta = ( meta, title, subTitle ) => {
	return {
		type: SET_GLOBAL_META,
		meta: meta,
		title: title,
		subTitle: subTitle
	};
};

export const resetMetaLoading = () => {
	return {
		type: RESET_META_LOADING
	}
};
