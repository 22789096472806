export const FETCH_RELEASE_NOTES = 'FETCH_RELEASE_NOTES';
export const FETCH_RELEASE_NOTES_SUCCESS = 'FETCH_RELEASE_NOTES_SUCCESS';
export const RESET_RELEASE_NOTES = 'RESET_RELEASE_NOTES';
export const SET_RELEASE_NOTES = 'SET_RELEASE_NOTES';
export const RELEASE_NOTES_FAILURE = 'RELEASE_NOTES_FAILURE';

export const fetchReleaseNotes = (loadingBehavior) => {
	return {
		type: FETCH_RELEASE_NOTES,
		loadingBehavior
	};
};

export const receiveReleaseNotes = (data,loadingBehavior) => {
	return {
		type: FETCH_RELEASE_NOTES_SUCCESS,
		data: data,
		loadingBehavior
	};
};

export const resetReleaseNotes = () => {
	return {
		type: RESET_RELEASE_NOTES
	};
};

export const setReleaseNotes = (state,date) => {
	return {
		type: SET_RELEASE_NOTES,
		state,
		date
	}
};

export const failedReleaseNotes = error => {
	return {
		type: RELEASE_NOTES_FAILURE,
		error: error
	};
};
