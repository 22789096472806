import { showReplyModalAttachmentLoader, hideReplyModalAttachmentLoader, displayNotification } from 'Actions/UiActions';
import { setGlobalNotification, getFetchConfig, offLineNotification } from 'Actions/GlobalMetaActions';
import { trackGAEvent } from 'Actions/adsActions';

export const SET_POST_HASH = 'SET_POST_HASH';
export const SET_POST_ATTACHMENT = 'SET_POST_ATTACHMENT';
export const REMOVE_POST_ATTACHMENT = 'REMOVE_POST_ATTACHMENT';

const setPostHash = ( responseHash ) => {
	return {
		type: SET_POST_HASH,
		responseHash: {
			posthash: responseHash.posthash,
			poststarttime: responseHash.poststarttime
		}
	};
};

const setPostAttachment = ( attachments ) => {
	return {
		type: SET_POST_ATTACHMENT,
		attachments
	};
};

const removePostAttachment = ( id ) => {
	return {
		type: REMOVE_POST_ATTACHMENT,
		id
	};
};

const uploadQuery = ( data ) => {
	return Object.keys( data ).map( ( key ) => `${key}=${data[ key ]}` ).join( '&' );
};

export function sendAttachment( file, data ) {
	return ( dispatch, getState ) => {
		dispatch( showReplyModalAttachmentLoader() );

		const state = getState(),
			url = state.topifyPresets.forumSite.url,
			config = dispatch( getFetchConfig( true, 'POST', file, false ) );

		return new Promise( ( resolve ) => {
			return fetch( `${url}?v=3.0&ajax=1&req=post&${uploadQuery( data )}`, config ).then( response => {

				dispatch( hideReplyModalAttachmentLoader() );

				if( !response.ok ) {
					response.json().then( json => {
						// Record the Google Analytics event.
						dispatch( trackGAEvent( 1, 'fileUploadFailed', 'error', 'system' ) );

						setGlobalNotification( dispatch, json, response );
						resolve( () => ({}) )
					} )
				} else {
					response.json().then( json => {
						displayNotification( 'success', 'The attachment was successfully uploaded', { timeout: 1000 } );

						// Record the Google Analytics event for the forum.
						dispatch( trackGAEvent( 2, 'uploadFile', 'userAction', 'creation' ) );

						return resolve( () => ({
							ids: json.ids,
							attach_urls: json.attach_urls,
							poststarttime: json.assign.poststarttime,
							posthash: json.assign.posthash,
						}) );
					} )
				}

			} ).catch( () => {
				dispatch( hideReplyModalAttachmentLoader() );
				dispatch( offLineNotification( { name: 'OfflineAttachments' } ) );
			} );
		} );
	};
}

export function deleteAttachment( data, showNotification = true ) {
	return ( dispatch, getState ) => {
		const state = getState(),
			url = state.topifyPresets.forumSite.url,
			config = dispatch( getFetchConfig( true, 'POST' ) );

		return fetch( `${url}?v=3.0&ajax=1&req=post&${uploadQuery( data )}`, config ).then( response => response.json() ).then( json => {
			json.deleted.forEach( attachmentObj => {
				let id = Object.keys( attachmentObj )[ 0 ];
				dispatch( removePostAttachment( id ) );
			} );

			if( showNotification ) {
				displayNotification( 'success', 'The attachment was successfully removed', { timeout: 1000 } );
			}
		} ).catch( () => {
			// Even offline, the request should have gone ok, let's take the attachment out
			dispatch( removePostAttachment( data.delete ) );
			dispatch( offLineNotification( { name: 'OfflineDraft' } ) );
		} );
	};
}

export function setAttachmentsHash( hash ) {
	const attachments = hash.attachments ?
		hash.attachments : // already wanted structure
		hash.attach_urls.map( ( url, index ) => { // get what we need
			return {
				url: url,
				id: parseInt( hash.ids[ index ] )
			}
		} ).filter( attachment => {
			return typeof attachment.id === 'number'
		} );

	return ( dispatch ) => {
		dispatch( setPostHash( hash ) );
		dispatch( setPostAttachment( attachments ) );
	};
}

export function updateEditingPostAttachments() {
	return ( dispatch, getState ) => {
		const state = getState(),
			selectedElements = state.meta.selectedElements || [],
			attachments = state.threadview.entities.attachments || [];

		let attachmentKeys = Object.keys( selectedElements ).map( ( postId ) => {
			return Object.keys( attachments ).filter( attachment => {
				return parseInt( attachments[ attachment ].post ) === parseInt( postId );
			} );
		} )[ 0 ];

		let attachmentsList = attachmentKeys.map( ( attachment_id ) => {
			return {
				...attachments[ attachment_id ],
				url: attachments[ attachment_id ].href_thumbnail || attachments[ attachment_id ].href
			}
		} );

		dispatch( setPostAttachment( attachmentsList ) );
	}
}
