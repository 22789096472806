import { push } from 'connected-react-router';

import { parsePath } from 'Utils/nav/NavHelpers';

import { receiveGlobalMeta, getCacheBuster } from 'Actions/MetaActions';
import { getLoadingBehavior, fetchSuccess } from 'Actions/InfiniteScrollActions';
import {
	setLastGlobalAction,
	setGlobalNotification,
	backInHistory,
	getFetchConfig,
	offLineNotification,
	doCronJob
} from 'Actions/GlobalMetaActions';
import { responseNormalizer, updateUstate } from 'Actions/responseNormalizer';
import { checkAdsPermissions } from 'Actions/adsActions';

import {
	PORTALPAGE_SUCCESS,
	PORTALPAGE_FETCH,
	PORTALPAGE_FAILURE,
	PORTALPAGE_RESET
} from 'Common/actions/PortalPageActions';

export * from 'Common/actions/PortalPageActions';

export function requestPortalPage( page ) {
	return {
		type: PORTALPAGE_FETCH,
		page: page
	};
}

export function receivePortalPage( page, data ) {
	return {
		type: PORTALPAGE_SUCCESS,
		page: page,
		data: data
	};
}

export function failedPortalPage( page, error ) {
	return {
		type: PORTALPAGE_FAILURE,
		page: page,
		error: error
	};
}

export function resetPortalPage() {
	return {
		type: PORTALPAGE_RESET
	}
}

export function fetchPortalPage( pageRequest, cacheBust = false ) {

	return function( dispatch, getState ) {
		pageRequest.loadingBehavior = getLoadingBehavior( getState, pageRequest.pagename, "portalpage", 1, true );

		dispatch( requestPortalPage( pageRequest ) );

		const state = getState(),
			url = state.topifyPresets.forumSite.url,
			currentPath = parsePath( document.location.hash ),
			preFetchPath = currentPath.length > 1 ? `/${ currentPath[ 1 ] }${ currentPath[ 2 ] ? '/' + currentPath[ 2 ] : '' }` : currentPath[ 0 ],
			fetchConfig = dispatch( getFetchConfig( cacheBust ) ), // Avoid Cache if cacheBust is required
			cacheBustQueryString = '&_=' + getCacheBuster( getState, cacheBust );

		return fetch( url + `?v=3.0&ajax=1&uid=0&req=portal&pagename=${pageRequest.pagename}${ cacheBustQueryString }`, fetchConfig ).then( ( response ) => {
				if( !response.ok ) {
					setGlobalNotification( dispatch, {}, response );

					dispatch( failedPortalPage( pageRequest, 'Bad response from server' ) );
					if( response.status !== 403 ) {
						dispatch( backInHistory() );
					}

				} else {
					response.json().then( ( json ) => {

						// preventing race condition when we switch page and
						// we still have pending a response from the server.
						// if currentLocation === '/' we are on first load
						let currentLocation = getState().router.location.pathname;
						if( currentLocation !== '/' && preFetchPath !== currentLocation ) {
							return;
						}

						setGlobalNotification( dispatch, json, { ok: true } );

						let other = Object.assign( {}, json.portal ? json.portal.meta : {} );

						let result = responseNormalizer( json, false, other );

						const forumTitle = state.topifyPresets.forumSite.f_title;

						if( json.portal ) {
							dispatch( receiveGlobalMeta( json.portal.meta, json.portal.meta.title, forumTitle ) );
						} else {
							dispatch( receiveGlobalMeta( null, forumTitle ) );
						}

						dispatch( updateUstate( json, result ) );
						dispatch( doCronJob( json ) );

						dispatch( checkAdsPermissions( -1 ) );

						dispatch( fetchSuccess( pageRequest.pagename, "portalpage", 1, 1, pageRequest.loadingBehavior ) );
						dispatch( receivePortalPage( pageRequest, result ) );
					} );
				}
			}
		).then( ()=> {
			setLastGlobalAction( 'portalpage', pageRequest );

		} ).catch( error => {
			dispatch( failedPortalPage( pageRequest, 'No connection' ) );
			dispatch( offLineNotification( error, 1 ) );
		} );
	}
}
