window.topifyDevice = {
	ran: false,
	any: false,
	os: {
		version: false,
		platform: {
			android: false,
			blackberry: false,
			ios: false,
			webos: false,
			opera: false,
			windows: false
		}
	},
	browser: {
		engine: '', // browser name or native
		version: null
	}
};

// Detects if we have a mobile device, OS, version and browser used
function _detectMobile() {
	let s, b;

	// let's detect system and version
	if( s = navigator.userAgent.match( /(Windows.*Phone)[\s\/]*((?:\d+\.?)+)?/i ) ) {
		topifyDevice.os.platform.windows = true;
		topifyDevice.os.version = typeof s[ 2 ] !== 'undefined' ? s[ 2 ] : false;
		topifyDevice.any = true;

	} else if( s = navigator.userAgent.match( /(Android)\s*((?:\d+\.?)+)?/i ) ) {
		topifyDevice.os.platform.android = true;
		topifyDevice.os.version = typeof s[ 2 ] !== 'undefined' ? s[ 2 ] : false;
		topifyDevice.any = true;

	} else if( s = navigator.userAgent.match( /(iPad|iPhone|iPod)(?:;.*CPU.*OS ((?:\d+_?)+))?/i ) ) {
		topifyDevice.os.platform.ios = s[ 1 ];
		topifyDevice.os.version = typeof s[ 2 ] !== 'undefined' ? s[ 2 ].replace( /_/, '.' ) : false;
		topifyDevice.any = true;

	} else if( s = navigator.userAgent.match( /(BlackBerry)\s*(\d+)?/i ) ) {
		topifyDevice.os.platform.blackberry = true;
		topifyDevice.os.version = typeof s[ 2 ] !== 'undefined' ? s[ 2 ] : false;
		topifyDevice.any = true;

	} else if( s = navigator.userAgent.match( /(webOS)[\s\/]*((?:\d+\.?)+)?/i ) ) {
		topifyDevice.os.platform.webos = true;
		topifyDevice.os.version = typeof s[ 2 ] !== 'undefined' ? s[ 2 ] : false;
		topifyDevice.any = true;

	} else if( s = navigator.userAgent.match( /(Opera Mini)[\s\/]*((?:\d+\.?)+)?/i ) ) {
		topifyDevice.os.platform.opera = true;
		topifyDevice.os.version = typeof s[ 2 ] !== 'undefined' ? s[ 2 ] : false;
		topifyDevice.any = true;
	}

	// let's check some browser basics...
	if( b = navigator.userAgent.match( /(OPR)[\s\/]*((?:\d+\.?)+)/i ) ) {
		topifyDevice.browser = {
			engine: typeof b[ 1 ] !== 'undefined' ? 'Opera' : '',
			version: typeof b[ 2 ] !== 'undefined' ? b[ 2 ] : null
		};

	} else if( b = navigator.userAgent.match( /(Edge)[\s\/]*((?:\d+\.?)+)/i ) ) {
		topifyDevice.browser = {
			engine: typeof b[ 1 ] !== 'undefined' ? 'Edge' : '',
			version: typeof b[ 2 ] !== 'undefined' ? b[ 2 ] : null
		};

	} else if( b = navigator.userAgent.match( /(Firefox|FxiOS)[\s\/]*((?:\d+\.?)+)/i ) ) {
		topifyDevice.browser = {
			engine: typeof b[ 1 ] !== 'undefined' ? 'Firefox' : '',
			version: typeof b[ 2 ] !== 'undefined' ? b[ 2 ] : null
		};

	} else if( b = navigator.userAgent.match( /(Chrome|CriOS)[\s\/]*((?:\d+\.?)+)/i ) ) {
		topifyDevice.browser = {
			engine: typeof b[ 1 ] !== 'undefined' ? 'Chrome' : '',
			version: typeof b[ 2 ] !== 'undefined' ? b[ 2 ] : null
		};

	} else if( b = navigator.userAgent.match( /(Safari)[\s\/]*((?:\d+\.?)+)/i ) ) {
		topifyDevice.browser = {
			engine: typeof b[ 1 ] !== 'undefined' ? 'Safari' : '',
			version: typeof b[ 2 ] !== 'undefined' ? b[ 2 ] : null
		};

	} else if( b = navigator.userAgent.match( /(IEMobile|MSIEMobile)[\s\/]*((?:\d+\.?)+)/i ) ) {
		topifyDevice.browser = {
			engine: typeof b[ 1 ] !== 'undefined' ? 'MSIE' : '',
			version: typeof b[ 2 ] !== 'undefined' ? b[ 2 ] : null
		};
	}
}

export function deviceType() {
	if( topifyDevice.ran !== true ) {
		_detectMobile();
	}
	return topifyDevice;
}

export function devicePlatform() {
	if( topifyDevice.ran !== true ) {
		_detectMobile();
	}

	if( topifyDevice.any ) {
		return Object.keys( topifyDevice.os.platform ).find( platform => topifyDevice.os.platform[ platform ] );
	}
}

/**
 * Compares 2 versions provided and tells which is bigger
 *
 * based on: https://gist.github.com/TheDistantSea/8021359
 *
 * @param v1
 * @param v2
 * @returns {number} -1: v1 < v2; 0: equal; 1: v1 > v2
 */
export function compareVersions( v1, v2 ) {
	let v1parts = (v1 + '').split( /\.|_/ ),
		v2parts = (v2 + '').split( /\.|_/ );

	// are versions well formed?
	if( !v1parts.every( p => /^\d+[a-zA-Z]*$/.test( p ) ) || !v2parts.every( p => /^\d+[a-zA-Z]*$/.test( p ) ) ) {
		return null;
	}

	// Complete versions with 0 up to same length
	while( v1parts.length < v2parts.length ) {
		v1parts.push( "0" );
	}
	while( v2parts.length < v1parts.length ) {
		v2parts.push( "0" );
	}

	// Convert Numeric parts to numbers
	v1parts = v1parts.map( n => isFinite( n ) ? Number( n ) : n );
	v2parts = v2parts.map( n => isFinite( n ) ? Number( n ) : n );

	for( let i = 0; i < v1parts.length; ++i ) {
		if( v2parts.length === i ) {
			return 1;
		}

		if( v1parts[ i ] == v2parts[ i ] ) {
			// continue;

		} else if( v1parts[ i ] > v2parts[ i ] ) {
			return 1;

		} else {
			return -1;
		}
	}

	return 0;
}