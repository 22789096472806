export const USERPROFILE_FETCH = 'USERPROFILE_FETCH';
export const USERPROFILE_SUCCESS = 'USERPROFILE_SUCCESS';
export const USERPROFILE_FAILURE = 'USERPROFILE_FAILURE';
export const RESET_USERPROFILE = 'RESET_USERPROFILE';

export function requestUserProfile( user ) {
	return {
		type: USERPROFILE_FETCH,
		user: user
	};
}

export function receiveUserProfile( user, data ) {
	return {
		type: USERPROFILE_SUCCESS,
		user: user,
		data: data
	};
}

export function failedUserProfile( user, error ) {
	return {
		type: USERPROFILE_FAILURE,
		user: user,
		error: error
	};
}

export function resetUserProfile() {
	return {
		type: RESET_USERPROFILE
	};
}