
export function pathToRoot() {
	return `/`;
}

export function pathToForumsIndex() {
	return process.env.config.section.home === false && process.env.config.IndexRoute === 'index' ? `/` : `/forums`;
}

export function pathToForum(forumId) {
	return `/forums/${forumId}`;
}

export function pathToThread(threadId) {
	return `/topics/${threadId}`;
}

export function pathToSearch(searchid=0) {
	return `/search/${searchid}`;
}

export function pathToActiveTopics() {
	return `/latest`;
}

export function pathToNewPosts() {
	return `/updated`;
}

export function pathToParticipated() {
	return `/participated`;
}

export function pathToSubscribed() {
	return `/following`;
}

export function pathToReleaseNotes() {
	return `/release`;
}

export function pathToMessages() {
	return `/pms`;
}

export function pathToMessagesFolder( folder ) {
	return `/pms/${folder}`;
}

export function pathToMessagesPM( message ) {
	return `/pm/${message}`;
}

export function pathToPost( postId ) {
	return `/topics/0?postid=${postId}`;
}

export function pathToUserProfile( userId ) {
	return `/userprofile/${ userId }`;
}

export function pathToOnline() {
	return `/online`;
}

export function pathToSettings() {
	return `/settings`;
}

export function pathToLostPwd() {
	return `/lostpwd`;
}

/** Get info about the path:
 * path[ 0 ] => pathname passed
 * path[ 1 ] => section unless we are at /
 * path[ 2 ] => id if any
 * path[ 3 ] => querystring if any
 *
 * @param path
 * @returns {Array|{index: number, input: string}}
 */
export function parsePath( path ) {
	let re = /(?:([#/]*[?&]?)([^\/&?#]+))?/ig,
		parsedPath = [ path.replace( '#/', '/' ) ],
		m;

	while( ( m = re.exec( path ) ) !== null ) {
		if( m.index === re.lastIndex ) {
			re.lastIndex++;
			break;
		}

		if( /[?&]/.test( m[ 1 ] ) === false ) { // path
			parsedPath.push( m[2] );

		} else { // querystring
			parsedPath[3] = parsedPath[3] ? parsedPath[3] : '';
			parsedPath[3] += m[1].replace( /^\/?[?]/, '' ) + m[2];
		}
	}

	return parsedPath;
}
