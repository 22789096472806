import { push, replace } from 'connected-react-router';

import {
	getLoadingBehavior,
	fetchSuccess,
	shouldNotUpdateState,
	updatePagesResult
} from 'Actions/InfiniteScrollActions';
import { receiveGlobalMeta, resetSelectedItem, getCacheBuster } from 'Actions/MetaActions';
import {
	setLastGlobalAction,
	setGlobalNotification,
	backInHistory,
	refreshPage,
	getFetchConfig,
	offLineNotification,
	simpleFailFetchResponse,
	doCronJob
} from 'Actions/GlobalMetaActions';
import { showGlobalLoading, hideGlobalLoading, displayNotification } from 'Actions/UiActions';
import { getPageSize, getLandingPage } from 'Actions/UserSettingsActions';
import { trackGAEvent } from 'Actions/adsActions';
import { responseNormalizer, updateUstate } from 'Actions/responseNormalizer';

import {
	requestMessages,
	receiveMessages,
	failedMessages,
	resetMessages
} from 'Common/actions/MessagesviewActions';

import { parsePath, pathToMessages, pathToMessagesFolder } from 'Utils/nav/NavHelpers';

export * from 'Common/actions/MessagesviewActions';

export function fetchMessages( messagesRequest ) {

	return function(dispatch, getState) {

		messagesRequest.loadingBehavior = getLoadingBehavior( getState, messagesRequest.id, messagesRequest.pmroute, messagesRequest.page, messagesRequest.forceRefresh );

		if( messagesRequest.pmroute === 'pmfolder' && shouldNotUpdateState( getState, messagesRequest ) ) {
			dispatch( updatePagesResult( messagesRequest.page || 1 ) );
			return null
		}

		dispatch( requestMessages( messagesRequest ) );

		const state = getState(),
			url = state.topifyPresets.forumSite.url,
			currentPath = parsePath( document.location.hash ),
			preFetchPath = currentPath.length > 1 ? `/${ currentPath[ 1 ] }${ currentPath[ 2 ] ? '/' + currentPath[ 2 ] : '' }` : currentPath[ 0 ],
			fetchConfig = dispatch( getFetchConfig( true ) ),
			userID = state.ustate.id ? state.ustate.id : 0,
			page_size = dispatch( getPageSize() ),
			cacheBustQueryString = '&_=' + getCacheBuster( getState, true ); // Avoid Cache independently from cacheBust

		let url_params = ``;
		switch( messagesRequest.pmroute ) {
			case 'pmfolder':
				url_params = `${ messagesRequest.id }&folder=true&pagesize=${ page_size }${ messagesRequest.page ? '&page=' + messagesRequest.page : '' }&lb=1`;
				break;
			case 'pm':
				url_params = `${ messagesRequest.id }`;
				break;
		}

		return fetch(url + `?v=3.0&ajax=1&uid=${ userID }&req=pm&id=${ url_params }${ cacheBustQueryString }`, fetchConfig)
			.then( ( response ) => {
					if( !response.ok ) {
						try {
							// if we are going back and already have an error, keep going skipping message
							if( state.messagesview.lastError === '' ) {
								response.json().then( ( json ) => {
									setGlobalNotification( dispatch, json, response );
								} );
							}
						} catch( e ) {
							setGlobalNotification( dispatch, {}, response );
						}

						dispatch( failedMessages( messagesRequest, 'Bad response from server' ) );

						// If we have a non authorized let's redirect to landing page
						if( response.status === 401 ) {
							let landingPage = dispatch( getLandingPage() );
							dispatch( replace( landingPage ) );

						} else if( response.status !== 403 ) {
							dispatch( backInHistory() );
						}

					} else {
						response.json()
							.then((json) => {

								// preventing race condition when we switch page and
								// we still have pending a response from the server.
								// if currentLocation === '/' we are on first load
								let currentLocation = getState().router.location.pathname;
								if( currentLocation !== '/' && preFetchPath !== currentLocation ) {
									return;
								}

								setGlobalNotification( dispatch, json, { ok: true } );

								if( messagesRequest.pmroute === 'pm' ) {
									// Record the Google Analytics event and for the forum.
									dispatch( trackGAEvent( 2, 'pmRead', 'userAction', 'consumption' ) );
								}

							let other = Object.assign(
								{},
								{ page: messagesRequest.page || 1 },
								json.pm ? json.pm.meta : {},
								messagesRequest.pmroute === 'pmfolder' ? json.pm.meta : ( json.pmfolder.meta || {} )
							);

							let result = responseNormalizer( json, messagesRequest.page, other );

							let forumTitle = 'Messages';
								switch( messagesRequest.pmroute ) {
									case 'pmfolder':
										forumTitle += ': ' + result.entities.pmfolders[ messagesRequest.id ].name;
										break;
									case 'pm':
										let folderId = result.entities.pms[ messagesRequest.id ].folder;
										forumTitle += ': ' + result.entities.pmfolders[ folderId ].name;
										break;
								}
								dispatch( receiveGlobalMeta( null, forumTitle, state.topifyPresets.forumSite.f_title ) );

								dispatch( updateUstate( json, result ) );
								dispatch( doCronJob( json ) );

								const messagesview = state.messagesview;

								if(messagesview.earliestLoadedPage === null || result.page < messagesview.earliestLoadedPage) {
									result.earliestLoadedPage = result.page;
								}

								if(messagesview.latestLoadedPage === null || result.page > messagesview.latestLoadedPage) {
									result.latestLoadedPage = result.page;
								}

								dispatch( fetchSuccess( messagesRequest.id, messagesRequest.pmroute, messagesRequest.page, result.totalPages, messagesRequest.loadingBehavior ) );
								dispatch( receiveMessages( messagesRequest.pmroute, messagesRequest.id, result, messagesRequest.loadingBehavior ) );
							}
						)
					}
				}
			).then( () => {
				setLastGlobalAction( 'messages_' + messagesRequest.pmroute, messagesRequest );

			} ).catch( error => {
				dispatch( failedMessages( messagesRequest, 'No connection' ) );
				dispatch( offLineNotification( error, messagesRequest.page || 1 ) );
			} );
	}
}

export function deletePms( pmIds, isFolder ) {
	return function(dispatch, getState) {
		topifyConsole.log( 'Delete PMs', pmIds );
		const state = getState(),
			url = state.topifyPresets.forumSite.url,
			userID = state.ustate.id ? state.ustate.id : 0;

		let pmids = [];
		pmIds.forEach( function( pmid ) {
			pmids.push( 'pmid[]=' + pmid );
		});
		pmids = pmids.join( '&' );

		const fetchConfig = dispatch( getFetchConfig( false, 'POST', pmids ) );

		dispatch( showGlobalLoading( 'Processing' ) );

		return fetch( url + `?v=3.0&ajax=1&uid=${ userID }&req=post&type=deletepm`, fetchConfig )
			.then( (response) => {
					if (!response.ok) {
						dispatch( simpleFailFetchResponse( response ) );

					} else {
						response.json()
							.then((json) => {
									setGlobalNotification( dispatch, json, { ok: true } );
									// Record the Google Analytics event.
									dispatch( trackGAEvent( 1, 'deleteMessage', 'userAction', 'creation' ) );
									if( isFolder ) {
										dispatch( refreshPage() );
									} else if( response.status !== 403 ) {
										dispatch( backInHistory() );
									}
									dispatch( hideGlobalLoading() );
								}
							);
					}
				}
			).catch( () => dispatch( offLineNotification( { name: 'OfflineAction' } ) ) );
	};
}

export function markAsReadPM( pmIds, read, isFolder = false ) {
	return function(dispatch, getState) {
		const state = getState(),
			url = state.topifyPresets.forumSite.url,
			userID = state.ustate.id ? state.ustate.id : 0;
		let pmids = null;

		topifyConsole.log( 'Mark as un/read', read, pmIds );

		if( isFolder ) {
			pmids = `pmfolder=${ pmIds }`;

		} else {
			pmids = [];
			pmIds.forEach( function( pmid ) {
				pmids.push( 'pmid[]=' + pmid );
			} );
			pmids = pmids.join( '&' );
		}

		let body = `${ pmids }&state=${ read ? 1 : 0 }`;

		const fetchConfig = dispatch( getFetchConfig( false, 'POST', body ) );

		dispatch( showGlobalLoading( 'Processing' ) );

		return fetch( url + `?v=3.0&ajax=1&uid=${ userID }&req=post&type=markreadpm`, fetchConfig )
			.then( (response) => {
					if (!response.ok) {
						dispatch( simpleFailFetchResponse( response ) );

					} else {
						response.json()
							.then((json) => {
									setGlobalNotification( dispatch, json, { ok: true } );
									// Record the Google Analytics event and for the forum.
									dispatch( trackGAEvent( 3, 'pmRead', 'userAction', 'consumption' ) );

									dispatch( refreshPage() );
									dispatch( hideGlobalLoading() );
								}
							);
					}
				}
			).catch( () => dispatch( offLineNotification( { name: 'OfflineAction' } ) ) );
	};
}

export function movePMs( messages, folder ) {
	return function(dispatch, getState) {
		const state = getState(),
			url = state.topifyPresets.forumSite.url,
			userID = state.ustate.id ? state.ustate.id : 0;

		topifyConsole.log( 'Move PMS to folder', messages, folder );
		if( !messages || !messages.length || folder === null ) {
			displayNotification( 'info', 'No messages selected or folder provided' );
			return false;
		}

		let pmids = [];
		messages.forEach( function( pmid ) {
			pmids.push( 'pmid[]=' + pmid );
		} );
		pmids = pmids.join( '&' );

		let body = `${ pmids }&folder=${ folder }`;

		const fetchConfig = dispatch( getFetchConfig( false, 'POST', body ) );

		dispatch( showGlobalLoading( 'Processing' ) );

		return fetch( url + `?v=3.0&ajax=1&uid=${ userID }&req=post&type=movepm`, fetchConfig )
		.then( (response) => {
				if (!response.ok) {
					dispatch( simpleFailFetchResponse( response ) );

				} else {
					response.json()
					.then((json) => {
							setGlobalNotification( dispatch, json, { ok: true } );
							// Record the Google Analytics event and for the forum.
							dispatch( trackGAEvent( 1, 'pmMoved', 'userAction', 'consumption' ) );

							dispatch( resetSelectedItem() );
							dispatch( refreshPage() );
							dispatch( hideGlobalLoading() );
						}
					);
				}
			}
		).catch( () => dispatch( offLineNotification( { name: 'OfflineAction' } ) ) );
	};
}

export function goToMessageFolder( folderId ) {
	return function( dispatch ) {
		if( isFinite( parseInt( folderId ) ) ) {
			dispatch( resetMessages() );
			dispatch( push( pathToMessagesFolder( folderId ) ) );
		} else {
			dispatch( resetMessages() );
			dispatch( push( pathToMessages() ) );
		}
	}
}