/**
 * Sets a cookie
 *
 * @param name cookie name
 * @param value cookie value
 * @param expiration expiration in minutes
 * @param domain [optional] domain
 */
export function setCookie( name, value, expiration, domain = '' ) {
	if( !name || !value || !expiration ) {
		return;
	}

	let d = new Date();
	d.setTime( d.getTime() + (expiration * 60 * 1000) );
	let cName = name + "=" + value,
		expires = ";expires=" + d.toUTCString(),
		cDomain = (domain ? ";domain=" + domain : '');
	document.cookie = cName + expires + ";path=/" + cDomain;
}

/**
 * Gets cookie value
 *
 * @param name
 * @returns string|null cookie value or null
 */
export function getCookie( name ) {
	if( document.cookie.length > 0 ) {
		let cookieRe = new RegExp( `(?:(?:^|.*;\\s*)${ name }\\s*\\=\\s*([^;]*).*$)|^.*$` ),
			cookieValue = document.cookie.replace( cookieRe, "$1" );

		return cookieValue ? decodeURIComponent( cookieValue ).trim() : null;
	}
	return null;
}

/**
 * Expires given cookie name if it exists
 *
 * @param name
 * @returns {boolean}
 */
export function expireCookie( name ) {
	if( getCookie( name ) ) {
		document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
		return true;
	}
	return false;
}
