export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const UPDATE_PAGE_NUMBER = 'UPDATE_PAGE_NUMBER';
export const UPDATE_PAGES_RESULT = 'UPDATE_PAGES_RESULT';
export const RESET_INFINITE = 'RESET_INFINITE';
export const RETRIEVABLE_CONTENT = 'RETRIEVABLE_CONTENT';

export const LOADING_BEHAVIOR = {
	PREPEND: 'PREPEND',
	APPEND: 'APPEND',
	REFRESH: 'REFRESH',
	NONE: 'NONE'
};

export function getLoadingBehavior( getState, id, type, page, forceRefresh = false ) {
	let result;
	const state = getState().infiniteScroll;


	if(
		state.earliestLoadedPage === null
		|| state.latestLoadedPage === null
		|| type !== state.loadType
		|| id !== state.id
		|| forceRefresh
	) {
		// This is the initial load
		result = LOADING_BEHAVIOR.REFRESH;
	} else {
		if( page === state.earliestLoadedPage - 1 ) {
			// Scrolling up
			result = LOADING_BEHAVIOR.PREPEND;
		} else if( page === state.latestLoadedPage + 1 ) {
			// Scrolling down
			result = LOADING_BEHAVIOR.APPEND;
		} else if( state.earliestLoadedPage <= page && state.latestLoadedPage >= page ) {
			// Scrolling to edges
			result = LOADING_BEHAVIOR.NONE;
		} else {
			// Jumped to a page
			result = LOADING_BEHAVIOR.REFRESH;
		}
	}

	return result;
}

export function fetchSuccess( id, type, page, totalPages, loadingBehavior ) {
	return {
		type: FETCH_SUCCESS,
		id: id,
		loadType: type,
		page: parseInt( page ),
		totalPages: parseInt( totalPages ),
		loadingBehavior: loadingBehavior
	}
}

export function resetInfinite(){
	return {
		type: RESET_INFINITE
	}
}

export function updatePagesResult( newPage = 1 ) {
	return {
		type: UPDATE_PAGES_RESULT,
		page: parseInt( newPage )
	}
}

export function shouldNotUpdateState(getState, request) {
	const state = getState().infiniteScroll,
		page = request.page || 1;

	return (
		!request.forceRefresh &&
		parseInt( state.earliestLoadedPage ) !== parseInt( state.latestLoadedPage ) &&
		parseInt( page ) >= parseInt( state.earliestLoadedPage ) &&
		parseInt( page ) <= parseInt( state.latestLoadedPage ) &&
		!request.postid
	);
}
