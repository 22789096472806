import { push } from 'connected-react-router';

import { parsePath, pathToThread } from 'Utils/nav/NavHelpers';

import { receiveGlobalMeta, getCacheBuster, resetSelectedItem } from 'Actions/MetaActions';
import {
	getLoadingBehavior,
	shouldNotUpdateState,
	updatePagesResult,
	fetchSuccess,
	resetInfinite
} from 'Actions/InfiniteScrollActions';
import {
	getFetchConfig,
	setLastGlobalAction,
	setGlobalNotification,
	offLineNotification,
	backInHistory,
	simpleFailFetchResponse,
	doCronJob
} from 'Actions/GlobalMetaActions';
import { displayNotification } from 'Actions/UiActions';
import { getPageSize } from 'Actions/UserSettingsActions';
import { responseNormalizer, updateUstate } from 'Actions/responseNormalizer';
import { trackGAEvent, checkAdsPermissions } from 'Actions/adsActions';

import {
	requestForum,
	receiveForum,
	failedForum,
	updateForumFollow,
	updateThreadFollow,
	resetForum
} from 'Common/actions/ForumviewActions';

export * from 'Common/actions/ForumviewActions';

export function fetchForum( forumRequest, cacheBust = false ) {

	return function( dispatch, getState ) {
		forumRequest.loadingBehavior = getLoadingBehavior( getState, forumRequest.id, "forum", forumRequest.page, forumRequest.forceRefresh );

		if( shouldNotUpdateState( getState, forumRequest ) ) {
			dispatch( updatePagesResult( forumRequest.page || 1 ) );
			return null
		}

		dispatch( requestForum( forumRequest ) );

		const state = getState(),
			url = state.topifyPresets.forumSite.url,
			currentPath = parsePath( document.location.hash ),
			preFetchPath = currentPath.length > 1 ? `/${ currentPath[ 1 ] }${ currentPath[ 2 ] ? '/' + currentPath[ 2 ] : '' }` : currentPath[ 0 ],
			userID = state.ustate.id ? state.ustate.id : 0,
			fetchConfig = dispatch( getFetchConfig( cacheBust ) ), // get fetchConfig
			cacheBustQueryString = '&_=' + getCacheBuster( getState, cacheBust ),
			page_size = dispatch( getPageSize() );

		return fetch( url + `?v=3.0&ajax=1&uid=${ userID }&req=cat&id=${forumRequest.id}&pagesize=${page_size}&page=${forumRequest.page}&lb=1${ cacheBustQueryString }`, fetchConfig )
			.then( ( response ) => {
				if( !response.ok ) {
					dispatch( simpleFailFetchResponse( response ) );

					dispatch( failedForum( forumRequest, 'Bad response from server' ) );
					if( response.status !== 403 ) {
						dispatch( backInHistory() );
					}

				} else {
					response.json().then( ( json ) => {

						// preventing race condition when we switch page and
						// we still have pending a response from the server.
						// if currentLocation === '/' we are on first load
						let currentLocation = getState().router.location.pathname;
						if( currentLocation !== '/' && preFetchPath !== currentLocation ) {
							return;
						}

						setGlobalNotification( dispatch, json, { ok: true } );

						let other = Object.assign( {}, { page: forumRequest.page }, json.topic ? json.topic.meta : {} );

						let result = responseNormalizer( json, forumRequest.page, other );

						const forumTitle = state.topifyPresets.forumSite.f_title;

						if( json.topic && json.topic.meta.path.length ) {
							dispatch( receiveGlobalMeta( json.topic.meta, json.topic.meta.path[ 0 ].title, forumTitle ) );
						} else {
							dispatch( receiveGlobalMeta( null, forumTitle ) );
						}

						dispatch( updateUstate( json, result ) );
						dispatch( doCronJob( json ) );

						dispatch( checkAdsPermissions( parseInt( forumRequest.id ) ) );

						const forumview = state.forumview;

						if( forumview.earliestLoadedPage === null || result.page < forumview.earliestLoadedPage ) {
							result.earliestLoadedPage = result.page;
						}

						if( forumview.latestLoadedPage === null || result.page > forumview.latestLoadedPage ) {
							result.latestLoadedPage = result.page;
						}

						dispatch( fetchSuccess( forumRequest.id, "forum", forumRequest.page, result.totalPages, forumRequest.loadingBehavior ) );
						dispatch( receiveForum( forumRequest, result ) );
					} );
				}
			}
		).then( () => {
			setLastGlobalAction( 'forum', forumRequest );

		} ).catch( error => {
			dispatch( failedForum( forumRequest, 'No connection' ) );
			dispatch( offLineNotification( error, forumRequest.page || 1 ) );
		} );
	};
}

export function updateFollowing( data ) {
	let subID, subState, subType;
	if( typeof data.forum !== 'undefined' ) {
		subID = data.forum.id;
		subState = data.forum.isSubscribed ? 0 : 1;
		subType = 'Forum';
	} else { //TODO - throw error if typeof data.thread is also undefined?
		subID = data.thread.id;
		subState = data.thread.isSubscribed ? 0 : 1;
		subType = 'Topic';
	}

	let subResponse = subState ? 'followed' : 'unfollowed';

	return (dispatch, getState) => {
		const state = getState(),
			url = state.topifyPresets.forumSite.url,
			body = `type=sub&${ subType.toLowerCase() }=${subID}&state=${subState}`,
			config = dispatch( getFetchConfig( true, 'POST', body ) );

		switch( subType ) {
			case 'Forum':
				dispatch( updateForumFollow( subID, !!(subState) ) );
				break;
			case 'Topic':
				dispatch( updateThreadFollow( subID, !!(subState) ) );
				break;
		}

		return fetch(`${url}?v=3.0&ajax=1&uid=${data.ustate.id}&req=post`, config)
		.then( response => {
			if (!response.ok) {
				dispatch( simpleFailFetchResponse( response ) );

				switch( subType ) {
					case 'Forum':
						dispatch( updateForumFollow( subID, !(subState) ) );
						break;
					case 'Topic':
						dispatch( updateThreadFollow( subID, !(subState) ) );
						break;
				}

			} else {
				response.json().then( json => {
					if( subState ) {
						// Record the Google Analytics event for the forum.
						dispatch( trackGAEvent( 2, `follow${ subType }`, 'userAction', 'consumption' ) );
					}

					// Updating state with the returned value
					switch( subType ) {
						case 'Forum':
							dispatch( updateForumFollow( subID, json.result ) );
							break;
						case 'Topic':
							dispatch( updateThreadFollow( subID, json.result ) );
							break;
					}

					if( json.result !== !!(subState) ) {
						displayNotification( 'warning', `The ${subType} is ${subResponse}`, { timeout: 1500 } );
					} else if( data.kebabMenu === true ) {
						displayNotification( 'success', `You've ${ json.result ? 'subscribed to' : 'unsubscribed from' } this ${subType}`, { timeout: 1500 } );
					}
				});
			}
		} ).catch( () => dispatch( offLineNotification( { name: 'OfflineAction' } ) ) );
	};
}

export function goToThread( id ) {
	return function( dispatch ) {
		if( isFinite( parseInt( id ) ) ) {
			dispatch( resetSelectedItem() );
			dispatch( resetInfinite() );
			dispatch( resetForum() );
			dispatch( push( pathToThread( id ) ) );
		}
	}
}
