export const FETCH_ONLINE_USERS = 'FETCH_ONLINE_USERS';
export const FETCH_ONLINE_USERS_SUCCESS = 'FETCH_ONLINE_USERS_SUCCESS';
export const RESET_ONLINE_USERS = 'RESET_ONLINE_USERS';
export const ONLINE_USERS_FAILURE = 'ONLINE_USERS_FAILURE';

export const fetchOnlineUsers = (loadingBehavior) => {
	return {
		type: FETCH_ONLINE_USERS,
		loadingBehavior
	};
};

export const receiveOnlineUsers = (data,loadingBehavior) => {
	return {
		type: FETCH_ONLINE_USERS_SUCCESS,
		data: data,
		loadingBehavior
	};
};

export const resetOnlineUsers = () => {
	return {
		type: RESET_ONLINE_USERS
	};
};

export const failedOnlineUsers = error => {
	return {
		type: ONLINE_USERS_FAILURE,
		error: error
	};
};