export const FORUM_FETCH = 'FORUM_FETCH';
export const FORUM_SUCCESS = 'FORUM_SUCCESS';
export const FORUM_FAILURE = 'FORUM_FAILURE';
export const FORUM_RESET = 'FORUM_RESET';

export const FORUM_FOLLOWING = 'FORUM_FOLLOWING';
export const THREAD_FOLLOWING = 'THREAD_FOLLOWING';

export const FORUM_REQUEST_EPIC = 'FORUM_REQUEST_EPIC';
export const SET_FORUM = 'SET_FORUM';

export function requestForum(forum) {
	return {
		type: FORUM_FETCH,
		forum: forum
	};
}

export function receiveForum(forum, data) {
	return {
		type: FORUM_SUCCESS,
		forum: forum,
		data: data
	};
}

export function failedForum(forum, error) {
	return {
		type: FORUM_FAILURE,
		forum: forum,
		error: error
	};
}

export function resetForum(){
	return {
		type: FORUM_RESET
	}
}

export const setForum = (state,date) => {
	return {
		type: SET_FORUM,
		state,
		date
	}
};

export function updateForumFollow( forumId, follow ) { // singular, used only in src
	return {
		type: FORUM_FOLLOWING,
		forumId: forumId,
		follow: follow
	};
}

export function updateThreadFollow( threadId, follow ) {
	return {
		type: THREAD_FOLLOWING,
		threadId: threadId,
		follow: follow
	};
}

export function requestForumEpic(forumParams) {
	return {
		type: FORUM_REQUEST_EPIC,
		forumParams
	}
}
