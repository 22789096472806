import { parsePath } from 'Utils/nav/NavHelpers';

import { replace } from 'connected-react-router';

import { currentUser } from 'Actions/ContextInfoActions';

import { receiveGlobalMeta } from 'Actions/MetaActions';
import {
	setLastGlobalAction,
	getFetchConfig,
	backInHistory,
	offLineNotification,
	simpleFailFetchResponse,
	doCronJob
} from 'Actions/GlobalMetaActions';
import {
	getLoadingBehavior,
	shouldNotUpdateState,
	updatePagesResult,
	fetchSuccess
} from 'Actions/InfiniteScrollActions';
import { mapOnlineUsersLoadingBehaviour, getPageSize, getLandingPage } from 'Actions/UserSettingsActions';
import { responseNormalizer, updateUstate } from 'Actions/responseNormalizer';

import {
	fetchOnlineUsers,
	receiveOnlineUsers,
	failedOnlineUsers
} from 'Common/actions/OnlineUsersActions';

export * from 'Common/actions/OnlineUsersActions';

export const whoIsOnline = ( pageNum, forceRefresh ) => {

	return (dispatch,getState) => {

		const page = parseInt(pageNum),
			onlineRequest = {
				page,
				forceRefresh
			},
			loadingBehavior = getLoadingBehavior(getState, null, "onlineUsers", page, forceRefresh);

		if ( shouldNotUpdateState(getState, onlineRequest) ) {
			dispatch(updatePagesResult(pageNum||1));
			setLastGlobalAction('onlineUsers',onlineRequest);
			return null;
		}

		dispatch( fetchOnlineUsers(loadingBehavior) );

		const state = getState(),
			url = state.topifyPresets.forumSite.url,
			currentPath = parsePath( document.location.hash ),
			preFetchPath = currentPath.length > 1 ? `/${ currentPath[ 1 ] }${ currentPath[ 2 ] ? '/' + currentPath[ 2 ] : '' }` : currentPath[ 0 ],
			uid = dispatch( currentUser() ).id,
			page_size = dispatch( getPageSize() ),
			config = dispatch( getFetchConfig( true ) ),
			lb = mapOnlineUsersLoadingBehaviour( state.ui.userSettings.onlineUsersLoadingBehavior );

		return fetch(`${url}?v=3.0&ajax=1&uid=${uid}&req=online&pagesize=${page_size}&page=${page}&lb=${ lb }`, config)
			.then( response => {
				if( !response.ok ) {
					dispatch( simpleFailFetchResponse( response ) );

					// If we have a non authorized let's redirect to landing page
					if( response.status === 401 ) {
						let landingPage = dispatch( getLandingPage() );
						dispatch( replace( landingPage ) );
					} else if( response.status !== 403 ) {
						dispatch( backInHistory() );
					}

				} else {
					return response.json();
				}
			})
			.then( json => {
				if ( !json ) { return; }

				// preventing race condition when we switch page and
				// we still have pending a response from the server.
				// if currentLocation === '/' we are on first load
				let currentLocation = getState().router.location.pathname;
				if( currentLocation !== '/' && preFetchPath !== currentLocation ) {
					return;
				}

				let other = Object.assign( {}, { page: pageNum || 1 }, json.online ? json.online.meta : {} );

				let result = responseNormalizer( json, pageNum, other );

				dispatch( updateUstate( json, result ) );
				dispatch( doCronJob( json ) );

				dispatch( receiveGlobalMeta(json.online.meta, "Who is Online") );
				dispatch( fetchSuccess(null, "onlineUsers", page, json.online.meta.totalPages, loadingBehavior));
				dispatch( receiveOnlineUsers( result, loadingBehavior ) )
			})
			.then(() => {
				setLastGlobalAction('onlineUsers',onlineRequest);

			} ).catch( error => {
				dispatch( failedOnlineUsers( 'No connection' ) );
				dispatch( offLineNotification( error, page ) );
			} );
	};
};