export default function decode( text ) {
	return typeof text === 'string' ? decodeHtml( text ) : text;
}

// This is a hacky but efficient and short way to transform ALL html entities in symbols
function decodeHtml( html ) {
	let txt = document.createElement( "textarea" );
	txt.innerHTML = html;
	let result = txt.value;
	// cleaning
	try {
		txt.remove();
	} catch( e ) { /* ignore */
	}
	txt = null;
	// return result
	return result;
}
