import queryString from 'query-string';
import isEqual from 'lodash/isEqual';

import { parsePath } from 'Utils/nav/NavHelpers';

import { receiveGlobalMeta, getCacheBuster } from 'Actions/MetaActions';
import {
	getLoadingBehavior,
	shouldNotUpdateState,
	updatePagesResult,
	fetchSuccess
} from 'Actions/InfiniteScrollActions';
import {
	setLastGlobalAction,
	setGlobalNotification,
	backInHistory,
	getFetchConfig,
	offLineNotification,
	simpleFailFetchResponse,
	doCronJob
} from 'Actions/GlobalMetaActions';
import { trackGAEvent, checkAdsPermissions } from 'Actions/adsActions';
import { mapLoadingBehaviour, getPageSize } from 'Actions/UserSettingsActions';
import { responseNormalizer, updateUstate } from 'Actions/responseNormalizer';

import {
	requestSearch,
	receiveSearch,
	failedSearch
} from 'Common/actions/SearchResultActions';

export * from 'Common/actions/SearchResultActions';

export function fetchSearch( searchRequest, cacheBust = false ) {
	return function(dispatch, getState) {
		let action = '',
			page_size = dispatch( getPageSize() );

		switch( searchRequest.id ) {
			case 'fav':
				action = 'fav';
				// set double pageSize to prevent jumpy pages
				page_size = page_size > 20 ? page_size : 20;
				break;
			case 'participated':
			case 'part':
				action = 'participated';
				break;
			case 'updated':
			case 'unread':
				action = 'updated';
				// Always cache bust new posts
				cacheBust = Date.now();
				break;
			case 'latest':
			case 'new':
				action = 'latest';
				break;
		}

		searchRequest.loadingBehavior = getLoadingBehavior( getState, action, "search", searchRequest.page, searchRequest.forceRefresh );

		if( shouldNotUpdateState( getState, searchRequest ) ) {
			dispatch( updatePagesResult( searchRequest.page || 1 ) );
			return null;
		}

		dispatch( requestSearch( searchRequest ) );

		const state = getState(),
			url = state.topifyPresets.forumSite.url,
			currentPath = parsePath( document.location.hash ),
			preFetchPath = currentPath.length > 1 ? `/${ currentPath[ 1 ] }${ currentPath[ 2 ] ? '/' + currentPath[ 2 ] : '' }` : currentPath[ 0 ],
			preFetchSearch = `${ currentPath[ 3 ] ? currentPath[ 3 ] : ''}`,
			showPosts = searchRequest.showposts ? '&showposts=' + searchRequest.showposts : '',
			searchForum = searchRequest.forumId ? `&sf=${searchRequest.forumId}` : '',
			searchId = searchRequest.searchId ? `&searchid=${searchRequest.searchId}` : '',
			searchQuery = searchRequest.searchQuery ? '&sq=' + searchRequest.searchQuery : '',
			id = searchRequest.id ? `&id=${searchRequest.id}` : '',
			userID = state.ustate.id ? state.ustate.id : 0,
			reqType = searchRequest.id === 'fav' ? 'fav' : 'search',
			showTopics = searchRequest.showtopics ? '&showtopics=' + searchRequest.showtopics : '',
			fetchConfig = dispatch( getFetchConfig( cacheBust ) ),// Avoid Cache if cacheBust is required
			cacheBustQueryString = '&_=' + getCacheBuster( getState, cacheBust ),
			lb = mapLoadingBehaviour( state.ui.userSettings.loadingBehavior ),
			search_query_url = `${url}?v=3.0&ajax=1&uid=${ userID }&req=${reqType}${id}${showPosts}${showTopics}${searchId}${searchForum}${searchQuery}&pagesize=${page_size}&page=${searchRequest.page}&lb=${ lb }${ cacheBustQueryString }`;

		return fetch(search_query_url, fetchConfig )
			.then((response) => {
				if (!response.ok) {
					dispatch( simpleFailFetchResponse( response ) );

					// Too many requests / flood alert
					if( response.status === 429 ) {
						let retry = response.headers.get( 'Retry-After' ) || null;
						dispatch( receiveGlobalMeta( ( retry ? { retryAfter: retry } : {} ), "Search error" ) );
						dispatch( failedSearch( searchRequest, 'flood' ) );

					} else if( response.status !== 403 ) {
						dispatch( failedSearch( searchRequest, 'Bad response from server' ) );
						dispatch( backInHistory() );
					}

				} else {
					response.json().then( ( json ) => {

						// preventing race condition when we switch page and
						// we still have pending a response from the server.
						// On search we have to check as well queryString params except page
						let query = queryString.parse( preFetchSearch ),
							queryCurrent = queryString.parse( getState().router.location.search );
						delete query.page;
						delete queryCurrent.page;
						// if currentLocation === '/' we are on first load
						let currentLocation = getState().router.location.pathname;
						if( currentLocation !== '/' && preFetchPath !== currentLocation || !isEqual( query, queryCurrent ) ) {
							return;
						}

						setGlobalNotification( dispatch, json, { ok: true } );

						if( searchRequest.forumId !== null ) {
							// Record the Google Analytics event for the forum.
							dispatch( trackGAEvent( 2, 'searchForum', 'userAction', 'consumption' ) );
						}

						let searchData = ( json.search && json.search.data.length > 0 ? json.search.data[ 0 ] : false ),
							other = Object.assign(
								{},
								{
									page: searchRequest.page,
									searchId: ( searchData ? parseInt( searchData.id ) : 0 ),
									query: {
										searchQuery: ( searchData ? searchData.query : null ) || searchRequest.searchQuery,
										showposts: ( searchData && searchData.showposts ? searchData.showposts : 0 ),
										forumId: ( searchData && searchData.searchForum || null ),
										action: ( reqType === 'fav' ? reqType : ( searchData && searchData.action || null ) ),
										showtopics: searchRequest.showtopics || null
									}
								},
								(json.search ? json.search.meta : json.following.meta)
							);

						let result = responseNormalizer( json, searchRequest.page, other );

						if( result.commonwords === null ) {
							result.commonwords = [];
						}

						// Preventing error on breadcrumb
						if( json.search && json.search.meta.path === null ) {
							json.search.meta.path = [];
						}

						dispatch( updateUstate( json, result ) );
						dispatch( doCronJob( json ) );

						dispatch( checkAdsPermissions( 0 ) );

						let action = json.search && json.search.data && json.search.data.length > 0 ? json.search.data[ 0 ].action : searchRequest.id;

						switch( action ) {
							case 'fav':
								dispatch( receiveGlobalMeta( state.meta, "Subscribed", state.topifyPresets.forumSite.f_title ) );
								break;
							case 'participated':
							case 'part':
								dispatch( receiveGlobalMeta( state.meta, "Participated", state.topifyPresets.forumSite.f_title ) );
								break;
							case 'updated':
							case 'unread':
								dispatch( receiveGlobalMeta( state.meta, "Topics With New Posts", state.topifyPresets.forumSite.f_title ) );
								break;
							case 'latest':
							case 'new':
								dispatch( receiveGlobalMeta( state.meta, "Active Topics", state.topifyPresets.forumSite.f_title ) );
								break;
							default:
								result.query.action = 'search';
								dispatch( receiveGlobalMeta( json.search.meta, "Search Results" ) );
						}

						dispatch( fetchSuccess( action, "search", searchRequest.page, result.totalPages, searchRequest.loadingBehavior ) );
						dispatch( receiveSearch( searchRequest, result ) );
					} );
				}
			}
		).then(()=>{
			setLastGlobalAction( searchRequest.id, searchRequest );

		} ).catch( error => {
			dispatch( failedSearch( searchRequest, 'No connection' ) );
			dispatch( offLineNotification( error, searchRequest.page ) );
		} );
	}
}
