import { replace } from 'connected-react-router';
import queryString from 'query-string';

import { setLastGlobalPage, lastGlobalAction } from 'Actions/GlobalMetaActions';

import { UPDATE_PAGE_NUMBER, RETRIEVABLE_CONTENT } from 'Common/actions/InfiniteScrollActions';

export * from 'Common/actions/InfiniteScrollActions';

export const updatePageNumber = ( newPage = lastGlobalAction.data.page, curLocation = {} ) => {
	return function( dispatch, getState ) {
		dispatch( {
			type: UPDATE_PAGE_NUMBER,
			page: parseInt( newPage )
		} );

		setLastGlobalPage( newPage );

		const state = getState(),
			location = curLocation.pathname ? curLocation : state.router.location,
			pathname = location.pathname;
		let query = location.search ? queryString.parse( location.search ) : {};

		query.page = newPage;

		dispatch( replace( {
				pathname: pathname,
				search: queryString.stringify( query )
			} )
		);

		let onPageUpdateEvent = new CustomEvent( 'topifyPageUpdateEvent', { detail: { page: parseInt( newPage ) } } );
		document.dispatchEvent( onPageUpdateEvent );
	};
};

export function retrievableContent( page ) {
	return {
		type: RETRIEVABLE_CONTENT,
		page: page
	}
}