
export function getUSerPermissions() {
	return ( dispatch, getState ) => {
		try {
			let state = getState(),
				isUserLoggedIn = !!( state.ustate.id );

			return isUserLoggedIn && state.ustate.perms ? state.ustate.perms : {};

		} catch( e ) {
			return null;
		}
	};
}

export function getSubForumPermissions() {
	return ( dispatch, getState ) => {
		try {
			let forum = getCurrentForum( getState() );
			return {
				cancreate: forum.cancreate,
				isForum: forum.isForum,
				canpostattachments: forum.canpostattachments
			};
		} catch( e ) {
			return null;
		}
	};
}

export function getThreadPermissions() {
	return ( dispatch, getState ) => {
		try {
			let thread = getCurrentThread( getState() );

			return {
				canreply: thread.canreply,
				canopenclose: thread.canopenclose,
				candelete: thread.candelete,
				canreport: thread.canreport,
				isOpen: thread.isOpen,
				isDeleted: thread.isDeleted,
				canpostattachments: thread.canpostattachments
			};
		} catch( e ) {
			return null;
		}
	};
}

export function isSubscribedForum() {
	return ( dispatch, getState ) => {
		try {
			let forum = getCurrentForum( getState() );
			return forum.isSubscribed;
		} catch( e ) {
			return null;
		}
	};
}

export function isSubscribedThread() {
	return ( dispatch, getState ) => {
		try {
			let thread = getCurrentThread( getState() );
			return thread ? thread.isSubscribed : null;
		} catch( e ) {
			return null;
		}
	};
}

export function canCreateThread() {
	return ( dispatch, getState ) => {
		let isUserLoggedIn = !!( getState().ustate.id ),
			forumPerms = dispatch( getSubForumPermissions() );

		return forumPerms ? ( isUserLoggedIn && forumPerms.cancreate && forumPerms.isForum ) : false;
	};
}

export function canReplyThread() {
	return ( dispatch, getState ) => {
		let isUserLoggedIn = !!( getState().ustate.id ),
			threadPerms = dispatch( getThreadPermissions() );

		return threadPerms ? ( isUserLoggedIn && threadPerms.canreply && threadPerms.isOpen && !threadPerms.isDeleted ) : false;
	};
}

export function canOpenCloseThread() {
	return ( dispatch, getState ) => {
		let isUserLoggedIn = !!( getState().ustate.id ),
			threadPerms = dispatch( getThreadPermissions() );

		return threadPerms && isUserLoggedIn && threadPerms.canopenclose && !threadPerms.isDeleted ? threadPerms.isOpen : null;
	};
}

export function canDeleteThread() {
	return ( dispatch, getState ) => {
		let isUserLoggedIn = !!( getState().ustate.id ),
			threadPerms = dispatch( getThreadPermissions() );

		return threadPerms && isUserLoggedIn && threadPerms.candelete ? !threadPerms.isDeleted : null;
	};
}

export function canEditPost( postId = false ) {
	if( !postId ) {
		return false;
	}

	return ( dispatch, getState ) => {
		let state = getState(),
			isUserLoggedIn = !!( state.ustate.id ),
			id = parseInt( postId ),
			post = id ? state.threadview.entities.posts[ id ] : null;

		return post && isUserLoggedIn ? post.canEdit : false;
	};
}

// Check if we can delete or restore deleted posts
export function canDeletePosts( areDeleted = false ) {
	return ( dispatch, getState ) => {
		let state = getState(),
			isUserLoggedIn = !!( state.ustate.id ),
			threadPerms = dispatch( getThreadPermissions() ),
			modDelete = !!( state.meta.permissions && state.meta.permissions.candeleteposts ),
			selectedElements = Object.keys( state.meta.selectedElements );

		if( selectedElements.length <= 0 || !isUserLoggedIn || !threadPerms ) {
			return false;
		}

		if( threadPerms.isOpen ) {
			return selectedElements.every( item => {
					let post = state.threadview.entities.posts ? state.threadview.entities.posts[ item ] : false,
						ownPost = post ? post.canEdit && state.ustate.id && parseInt( post.forumUser ) === parseInt( state.ustate.id ) : false;
					return ( ownPost || threadPerms.candelete || modDelete ) && post.isDeleted === areDeleted;
				}
			);
		}

		return false;
	};
}

export function canReportPost() {
	return ( dispatch, getState ) => {
		let isUserLoggedIn = !!( getState().ustate.id ),
			threadPerms = dispatch( getThreadPermissions() );

		return threadPerms && isUserLoggedIn ? threadPerms.canreport : null;
	};
}

export function canPostAttachments() {
	return ( dispatch ) => {
		let perms = dispatch( getSubForumPermissions() ) || dispatch( getThreadPermissions() );

		return perms ? perms.canpostattachments : null;
	};
}

const getTopic = ( getState, topicId ) => {
	const topics = getState().threadview.entities.topics;
	if( topics && topics[ topicId ] ) {
		return topics[ topicId ];
	} else {
		return null;
	}
};

export function topicForumId(topicId) {
	return (dispatch,getState) => {
		let topic = getTopic(getState, topicId);
		return topic ? topic.forum : '';
	}
}

export const topicForumTitle = (topicId) => {
	return (dispatch,getState) => {
		return getTopic(getState, topicId).title;
	}
};

export const getForumTitle = (forumId) => {
	return (dispatch,getState) => {
		return getState().forumview.entities.forums[forumId].title;
	}
};

export function topicTitle(topicId) {
	return (dispatch,getState) => {
		let topic = getTopic(getState, topicId);
		return topic ? topic.title : '';
	}
}

export function topicHref( topicId ) {
	return ( dispatch, getState ) => {
		let topic = getTopic( getState, topicId );
		return topic ? topic.href : '';
	}
}

const getForum = ( getState, forumId ) => {
	if( forumId > 0 ) {
		const forums = getState().forumview.entities.forums;
		if( forums && forums[ forumId ] ) {
			return forums[ forumId ];
		} else {
			return null;
		}
	} else {
		return null;
	}
};

export function forumHref( forumId ) {
	return ( dispatch, getState ) => {
		let forum = getForum( getState, forumId );
		return forum ? forum.href : getState().topifyPresets.forumSite.f_url;
	}
}

export function messageTitle(messageId) {
	return (dispatch,getState) => {
		const pms = getState().messagesview.entities.pms;
		if ( !!pms[messageId] ) {
			return pms[messageId].title;
		} else {
			throw new Error('Title not found for message');
		}
	}
}

export function messageTo(messageId) {
	return (dispatch,getState) => {
		const entities = getState().messagesview.entities,
			pms = entities.pms,
			users = entities.users,
			user = users[ pms[messageId].fromUser ];

			return { id: user.id, username: user.userName };
	}
}

export function getPmFolders() {
	return (dispatch,getState) => {
		const entities = getState().messagesview.entities;

		return entities.pmfolders || [];
	}
}

export function currentPMFolder( pmid ) {
	return (dispatch,getState) => {
		const state = getState(),
			entities = state.messagesview.entities;

		try {
			let pmFolder = entities.pms[ pmid ].folder;
			return entities.pmfolders[ pmFolder ];
		} catch( e ) {
			return false;
		}
	}
}

export function messageContent(){
	return (dispatch,getState) => {
		const state = getState(),
			selectedElements = state.meta.selectedElements,
			posts = state.threadview.entities.posts;
			return Object.keys(selectedElements).map((postId)=> {
				let	post = posts[postId];
				return `${post.message}`
			}).join('');
	}
}

// @param isPm: typeof Bool or Array of messageIds
// @param isNative: is web or native
export function replyQuote( isPM = false, isNative = false ) {
	return (dispatch,getState) => {
		const state = getState(),
			storeName = isNative ? 'messageview' : 'messagesview',
			selectedElements = !isPM ? state.meta.selectedElements : isPM,
			items = !isPM ? state.threadview.entities.posts : state[storeName].entities.pms;

		if( !isPM ) {
			return Object.keys( selectedElements ).map( ( postId ) => {
				let post = items[ postId ];
				return `[QUOTE=${post.forumUserName};${postId}]${post.message}[/QUOTE]`;
			} ).join( '\n' );
		} else {
			return selectedElements.map( ( pmId ) => {
				let pm = items[ pmId ],
					userName = state[storeName].entities.users[ pm.fromUser ];

				return `[QUOTE=${ userName ? userName.userName : '' };]${pm.message}[/QUOTE]`;
			} ).join( '\n' );
		}
	}
}

export function uploadLimits() {
	return (dispatch,getState) => {
		return getState().ustate.upload;
	}
}

export function postSelected() {
	return (dispatch,getState) => {
		const selectedElements = getState().meta.selectedElements;
		return Object.keys(selectedElements)[0];
	}
}

export function selectedPMsUnread( every = false ) {
	return (dispatch,getState) => {
		let state = getState(),
			selectedElements = Object.keys( state.meta.selectedElements );
		if( state.messagesview.entities.pms && selectedElements.length > 0 ) {
			if( every ) {
				return selectedElements.every( pmId => state.messagesview.entities.pms[ pmId ] && state.messagesview.entities.pms[ pmId ].unread === true );
			} else {
				return selectedElements.some( pmId => state.messagesview.entities.pms[ pmId ] && state.messagesview.entities.pms[ pmId ].unread === true );
			}
		}
	};
}

export function selectedItemsUnread() {
	return (dispatch,getState) => {
		let state = getState(), entity, unread, value = true,
			searchresult = state.searchresult || state.search;

		switch( true ) {
			case ( searchresult.result.forums && searchresult.result.forums.length > 0 ):
				entity = 'forums';
				unread = 'isRead';
				value = false;
				break;

			case ( searchresult.result.topics && searchresult.result.topics.length > 0 ):
				entity = 'topics';
				unread = 'unread';
				break;
		}

		if( entity && searchresult.entities[ entity ] ) {
			let entities = searchresult.entities[ entity ],
				unreadItems = searchresult.result[ entity ].filter( itemid => entities[ itemid ] && entities[ itemid ][ unread ] === value );

			return {
				entity: entity,
				unreadItems: unreadItems
			}
		}

		return false;
	};
}

export function getThreadPrefix() {
	return ( dispatch, getState ) => {
		try {
			let thread = getCurrentThread( getState() );
			return thread ? {
				prefix_title_rich: thread.prefix_title_rich,
				prefixid: thread.prefixid
			} : {};
		} catch( e ) {
			return null;
		}
	};
}

export function getThreadPrefixInForum() {
	return ( dispatch, getState ) => {
		try {
			let forum = getCurrentForum( getState() );
			return forum && forum.prefixes ? forum.prefixes : [];
		} catch( e ) {
			return null;
		}
	};
}

function getCurrentForum( state ) {
	let forumid = parseInt( state.forumview.forumId );
	return forumid ? state.forumview.entities.forums[ forumid ] : null;
}

function getCurrentThread( state ) {
	let threadid = parseInt( state.threadview.threadid );
	return threadid ? state.threadview.entities.topics[ threadid ] : null;
}
