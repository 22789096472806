export const SEARCH_FETCH = 'SEARCH_FETCH';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';
export const SEARCH_REQUEST_EPIC = 'SEARCH_REQUEST_EPIC';
export const RESET_SEARCH = 'RESET_SEARCH';
export const SET_SEARCH = 'SET_SEARCH';

export const requestSearch = (query) => {
	return {
		type: SEARCH_FETCH,
		query
	};
};

export const receiveSearch = (query, data) => {
	return {
		type: SEARCH_SUCCESS,
		query,
		data
	};
};

export const failedSearch = (query, error) => {
	return {
		type: SEARCH_FAILURE,
		query,
		error
	};
};

export const resetSearch = ()=> {
	return {
		type: RESET_SEARCH
	};
};


export const setSearch = (state,date) => {
	return {
		type: SET_SEARCH,
		state,
		date,
	}
};

export const requestSearchEpic = (searchParams) => {
	return {
		type: SEARCH_REQUEST_EPIC,
		searchParams
	}
};