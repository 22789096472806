import { parsePath } from 'Utils/nav/NavHelpers';

import { receiveCurrentUser } from 'Actions/UstateActions';
import { receiveGlobalMeta, getCacheBuster, setCacheBuster } from 'Actions/MetaActions';
import {
	getFetchConfig,
	setLastGlobalAction,
	setGlobalNotification,
	backInHistory,
	offLineNotification,
	simpleFailFetchResponse,
	doCronJob
} from 'Actions/GlobalMetaActions';
import {
	getLoadingBehavior,
	shouldNotUpdateState,
	updatePagesResult,
	fetchSuccess
} from 'Actions/InfiniteScrollActions';
import {
	showGlobalLoading,
	hideGlobalLoading,
	displayNotification
} from 'Actions/UiActions'
import { mapLoadingBehaviour, getPageSize } from 'Actions/UserSettingsActions';
import { trackGAEvent } from 'Actions/adsActions';
import { responseNormalizer, updateUstate } from 'Actions/responseNormalizer';
import { checkAdsPermissions } from 'Actions/adsActions';

import {
	requestUserProfile,
	receiveUserProfile,
	failedUserProfile
} from 'Common/actions/UserprofileActions';

export * from 'Common/actions/UserprofileActions';

export function fetchUserProfile( userRequest, cacheBust = false ) {

	return function(dispatch, getState) {

		userRequest.loadingBehavior = getLoadingBehavior(getState, userRequest.id, "userprofile", userRequest.page, userRequest.forceRefresh);

		if ( shouldNotUpdateState( getState, userRequest ) ) {
			dispatch( updatePagesResult( userRequest.page || 1 ) );
			return null;
		}

		dispatch( requestUserProfile( userRequest ) );

		const state = getState(),
			url = state.topifyPresets.forumSite.url,
			currentPath = parsePath( document.location.hash ),
			preFetchPath = currentPath.length > 1 ? `/${ currentPath[ 1 ] }${ currentPath[ 2 ] ? '/' + currentPath[ 2 ] : '' }` : currentPath[ 0 ],
			id = userRequest.id ? `&id=${userRequest.id}` : '',
			userID = state.ustate.id ? state.ustate.id : 0,
			fetchConfig = dispatch( getFetchConfig( cacheBust ) ),// Avoid Cache if cacheBust is required
			cacheBustQueryString = '&_=' + getCacheBuster( getState, cacheBust ),
			lb = mapLoadingBehaviour( state.ui.userSettings.loadingBehavior ),
			page_size = dispatch( getPageSize() );

		return fetch( url + `?v=3.0&ajax=1&uid=${ userID }&req=userprofile${id}&pagesize=${page_size}&page=${userRequest.page}&lb=${ lb }${ cacheBustQueryString }`, fetchConfig )
			.then((response) => {
				if (!response.ok) {
					dispatch( simpleFailFetchResponse( response ) );

					dispatch( failedUserProfile( userRequest, 'Bad response from server' ) );
					if( response.status !== 403 ) {
						dispatch( backInHistory() );
					}

				} else {
					response.json().then((json) => {

						// preventing race condition when we switch page and
						// we still have pending a response from the server.
						// if currentLocation === '/' we are on first load
						let currentLocation = getState().router.location.pathname;
						if( currentLocation !== '/' && preFetchPath !== currentLocation ) {
							return;
						}

						setGlobalNotification(dispatch,json,{ok: true});

						let other = Object.assign( {}, { page: userRequest.page }, json.forumUser ? json.forumUser.meta : {} );
						let result = responseNormalizer( json, userRequest.page, other );

						// If we searched by userName instead of id
						if( typeof userRequest.id === 'string' ) {
							for( let user in result.entities.users ) {
								if( result.entities.users[ user ].userName === userRequest.id ) {
									userRequest.id = user;
									break;
								}
							}
						}

						let myprofile = json.ustate && json.ustate.id && parseInt( userRequest.id ) === parseInt( json.ustate.id ),
							userName = userRequest.id && result.entities.users[ userRequest.id ].userName ? `: ${ result.entities.users[ userRequest.id ].userName }` : '',
							title = userRequest.cp === true ?
							'User Control Panel' :
							( myprofile ? "My User Profile" : `User Profile${ userName }` );

						dispatch( receiveGlobalMeta( json.forumUser.meta, title, state.topifyPresets.forumSite.f_title ) );

						dispatch( updateUstate( json, result ) );
						dispatch( doCronJob( json ) );

						dispatch( checkAdsPermissions( 0 ) );

						dispatch( fetchSuccess( userRequest.id, "userprofile", userRequest.page, result.totalPages, userRequest.loadingBehavior ) );
						dispatch( receiveUserProfile( userRequest, result ) );
					} );
				}
			}
		).then(()=>{
			setLastGlobalAction('userprofile',userRequest);

		} ).catch( error => {
			dispatch( failedUserProfile( userRequest, 'No connection' ) );
			dispatch( offLineNotification( error, userRequest.page ) );
		} );
	};
}

export function uploadAvatar( data ) {
	return ( dispatch, getState ) => {
		dispatch( showGlobalLoading( 'Saving avatar' ) );

		const state = getState(),
			url = state.topifyPresets.forumSite.url,
			userID = state.ustate.id ? state.ustate.id : 0,
			config = dispatch( getFetchConfig( true, 'POST', data, false ) );

		return new Promise( ( resolve, reject ) => {
			return fetch( `${ url }?v=3.0&ajax=1&uid=${ userID }&req=post&type=uploadavatar`, config ).then( response => {

				dispatch( hideGlobalLoading() );

				if( !response.ok ) {
					response.json().then( json => {
						// Record the Google Analytics event.
						dispatch( trackGAEvent( 1, 'avatarUploadFailed', 'error', 'system' ) );

						setGlobalNotification( dispatch, json, response );
						resolve( () => ({}) );
					} );

				} else {
					response.json().then( json => {
						displayNotification( 'success', 'Avatar successfully updated', { timeout: 1000 } );
						dispatch( setCacheBuster() );

						if( json.forumUser.data[0] ) {
							let currentUser = json.forumUser.data[ 0 ],
								new_ustate = Object.assign( {}, json.ustate, { userName: currentUser.userName, avatar: currentUser.avatar, online: currentUser.online, lastActivity: currentUser.lastActivity } );

							dispatch( receiveCurrentUser( new_ustate ) );

							// Record the Google Analytics event.
							dispatch( trackGAEvent( 1, 'uploadAvatar', 'userAction', 'userCP' ) );

							return {
								result: true,
								avatar: json.forumUser.data[ 0 ].avatar
							};

						} else {
							return { result: false };
						}
					} ).then( r => {
						dispatch( hideGlobalLoading() );
						resolve( () => r )
					} );
				}
			} ).catch( () => dispatch( offLineNotification( { name: 'OfflineAction' } ) ) );
		} );
	};
}

export function saveUserCP( settings ) {
	return ( dispatch, getState ) => {
		const state = getState(),
			url = state.topifyPresets.forumSite.url,
			userID = state.ustate.id ? state.ustate.id : 0;

		let fields = [],
			body = null;

		// Create the body with the fields passed
		if( Array.isArray( settings ) && settings.length ) {
			settings.forEach( ( el ) => {
				fields.push( el.join( '=' ) );
			} );

			if( fields.length ) {
				body = fields.join( '&' );
			}
		}

		const config = dispatch( getFetchConfig( true, 'POST', body ) );

		return new Promise( ( resolve, reject ) => {
			return fetch( `${ url }?v=3.0&ajax=1&uid=${ userID }&req=post&type=saveusercp`, config ).then( response => {

				if( !response.ok ) {
					response.json().then( json => {
						// Record the Google Analytics event.
						dispatch( trackGAEvent( 1, 'saveUserCP', 'error', 'system' ) );

						setGlobalNotification( dispatch, json, response );
						dispatch( hideGlobalLoading() );
						return resolve( () => ({ result: false }) );
					} );

				} else {
					response.json().then( json => {
						if( json.result === false ) {
							setGlobalNotification( dispatch, json, response );
						} else {
							displayNotification( 'success', 'Setting successfully updated', {
								force: true,
								timeout: 1000
							} );
							dispatch( setCacheBuster() );
						}

						// Record the Google Analytics event.
						settings.forEach( ( field ) => {
							if( field[ 0 ] === 'field' && field[ 1 ] ) {
								json.result === false ?
									dispatch( trackGAEvent( 1, 'saveUserCP_' + field[ 1 ], 'error', 'user' ) ) :
									dispatch( trackGAEvent( 1, 'saveUserCP_' + field[ 1 ], 'userAction', 'userCP' ) );
							}
						} );

						if( json.forumUser.data[ 0 ] ) {
							let currentUser = json.forumUser.data[ 0 ],
								new_ustate = Object.assign( {}, json.ustate, {
									userName: currentUser.userName,
									avatar: currentUser.avatar,
									online: currentUser.online,
									lastActivity: currentUser.lastActivity
								} );

							dispatch( receiveCurrentUser( new_ustate ) );

							return true;
						} else {
							return false;
						}
					} ).then( r => {
						dispatch( hideGlobalLoading() );
						return resolve( () => ({ result: r }) );
					} )
				}
			} ).catch( () => dispatch( offLineNotification( { name: 'OfflineAction' } ) ) );
		} );
	};
}

