import { parsePath, pathToPost, pathToForum } from 'Utils/nav/NavHelpers';

import { push } from 'connected-react-router';

import { receiveGlobalMeta, getCacheBuster, resetSelectedItem } from 'Actions/MetaActions';
import {
	getLoadingBehavior,
	shouldNotUpdateState,
	updatePagesResult,
	fetchSuccess
} from 'Actions/InfiniteScrollActions';
import {
	setLastGlobalAction,
	setGlobalNotification,
	backInHistory,
	refreshPage,
	getFetchConfig,
	offLineNotification,
	simpleFailFetchResponse,
	doCronJob
} from 'Actions/GlobalMetaActions';
import { addPhoto, removePhoto } from 'Actions/PhotoGalleryActions';
import { trackGAEvent, checkAdsPermissions } from 'Actions/adsActions';
import {
	showGlobalLoading,
	hideGlobalLoading,
	displayNotification
} from 'Actions/UiActions'
import { mapLoadingBehaviour, getPageSize } from 'Actions/UserSettingsActions';
import { responseNormalizer, updateUstate } from 'Actions/responseNormalizer';

import {
	requestThread,
	receiveThread,
	failedThread
} from 'Common/actions/ThreadviewActions';

export * from 'Common/actions/ThreadviewActions';

export function addPhotoGalleryItems( response ) {
	let attachments = response.entities.attachments;
	return ( dispatch ) => {
		dispatch( removePhoto( response.result.posts ) );

		response.result.attachments.forEach( ( id ) => {
			let attachment = attachments[ id ];
			dispatch(
				addPhoto( {
					id: id,
					src: attachment.href,
					thumbnail: attachment.href_thumbnail ? attachment.href_thumbnail : attachment.href,
					w: 0,
					h: 0,
					postId: attachment.post
				} )
			);
		} );
	};
}

export function fetchThread( thread, cacheBust = false ) {

	return function( dispatch, getState ) {
		// avoid false request to thread 0
		if(
			( !thread.id || parseInt( thread.id ) === 0 ) &&
			( !thread.postid || parseInt( thread.postid ) === 0 )
		) {
			return null;
		}

		thread.loadingBehavior = getLoadingBehavior( getState, thread.id, "thread", thread.page, ( thread.postid ? true : thread.forceRefresh) );

		if( shouldNotUpdateState( getState, thread ) ) {
			dispatch( updatePagesResult( thread.page || 1 ) );
			return null;
		}

		dispatch( requestThread( thread ) );

		const state = getState(),
			url = state.topifyPresets.forumSite.url,
			currentPath = parsePath( document.location.hash ),
			preFetchPath = currentPath.length > 1 ? `/${ currentPath[ 1 ] }${ currentPath[ 2 ] ? '/' + currentPath[ 2 ] : '' }` : currentPath[ 0 ],
			userID = state.ustate.id ? state.ustate.id : 0,
			goto = thread.postid ? `&goto=true&postid=${thread.postid}` : `&id=${thread.id}`,
			page = thread.postid ? '' : ( thread.page ? `&page=${thread.page}` : `&page=0` ), // if we have a postid skip the page
			fetchConfig = dispatch( getFetchConfig( cacheBust ) ),// Avoid Cache if cacheBust is required
			cacheBustQueryString = '&_=' + getCacheBuster( getState, cacheBust ),
			lb = mapLoadingBehaviour( state.ui.userSettings.loadingBehavior ),
			page_size = dispatch( getPageSize() );

		return fetch( url + `?v=3.0&ajax=1&uid=${ userID }&req=topic${ goto }&pagesize=${ page_size }${ page }&lb=${ lb }${ cacheBustQueryString }`, fetchConfig ).then( ( response ) => {
			if( !response.ok ) {
				dispatch( simpleFailFetchResponse( response ) );

				dispatch( failedThread( thread, 'Bad response from server' ) );
				if( response.status !== 403 ) {
					dispatch( backInHistory() );
				}

			} else {
				response.json().then( ( json ) => {

					// preventing race condition when we switch page and
					// we still have pending a response from the server.
					// if currentLocation === '/' we are on first load
					let currentLocation = getState().router.location.pathname;
					if( currentLocation !== '/' && preFetchPath !== currentLocation ) {
						return;
					}

					setGlobalNotification( dispatch, json, { ok: true } );

					let other = Object.assign( {}, { page: json.post.meta.page }, json.post ? json.post.meta : {} );

					let result = responseNormalizer( json, thread.page, other );

					thread.id = result.threadid;
					thread.page = json.post.meta.page;
					const forumTitle = state.topifyPresets.forumSite.f_title;

					dispatch( receiveGlobalMeta( json.post.meta, result.entities.topics[ result.result.topics[ 0 ] ].title, forumTitle ) );

					dispatch( updateUstate( json, result ) );
					dispatch( doCronJob( json ) );

					if( result.result.forums[ 0 ] ) {
						dispatch( checkAdsPermissions( parseInt( result.result.forums[ 0 ] ) ) );
					}

					dispatch( addPhotoGalleryItems( result ) );
					dispatch( fetchSuccess( result.threadid, "thread", result.page, result.totalPages, thread.loadingBehavior ) );
					dispatch( receiveThread( thread, result ) );
				} );
			}
		} ).then( () => {
			setLastGlobalAction( 'thread', thread );

		} ).catch( error => {
			dispatch( failedThread( thread, 'No connection' ) );
			dispatch( offLineNotification( error, thread.page ) );
		} );
	}
}

/**
 *
 * @param postsId
 * @param actionState bool true: delete | false: undelete
 * @returns {Function}
 */
export function deletePosts( postsId, actionState = false ) {
	return function( dispatch, getState ) {
		topifyConsole.log( 'Delete Posts', postsId );
		const state = getState(),
			url = state.topifyPresets.forumSite.url,
			userID = state.ustate.id ? state.ustate.id : 0,
			newState = actionState ? "1" : "-1";

		let postids = [];
		postsId.forEach( function( postid ) {
			postids.push( 'relpostid[]=' + postid );
		} );
		postids = postids.join( '&' );

		let moreData = `&req=post&state=${ newState }&type=moderate&action=delete`;

		const fetchConfig = dispatch( getFetchConfig( true, 'POST', postids ) );

		dispatch( showGlobalLoading( 'Processing' ) );

		return fetch( url + `?v=3.0&ajax=1&uid=${ userID }${ moreData }`, fetchConfig ).then( ( response ) => {
				if( !response.ok ) {
					dispatch( simpleFailFetchResponse( response ) );

					dispatch( failedThread( thread, 'Bad response from server' ) );
					if( response.status !== 403 ) {
						dispatch( backInHistory() );
					}

				} else {
					response.json().then( ( json ) => {
							setGlobalNotification( dispatch, json, { ok: true } );
							if( json.state ) {
								let count = json.result.postid.length,
									message = `${ count } post${ count === 1 ? ' was' : 's were' } ${ json.result.isDeleted ? 'deleted' : 'undeleted' }`;
								displayNotification( 'success', message );

								if( state.meta.permissions && state.meta.permissions.candeleteposts ) {
									// is Mod
									// Record the Google Analytics event and for the forum.
									dispatch( trackGAEvent( 3, ( actionState ? 'modDeletePosts' : 'modUndeletePosts' ), 'moderatorAction', 'topic' ) );
								} else {
									// is regular user
									// Record the Google Analytics event and for the forum.
									dispatch( trackGAEvent( 3, 'deletePost', 'userAction', 'creation' ) );
								}

								// What do we do now?
								if( json.result.goto && isFinite( json.result.goto ) ) {
									dispatch( push( pathToPost( parseInt( json.result.goto ) ) ) );
								} else if( json.result.forumid && isFinite( json.result.forumid ) ) {
									dispatch( push( pathToForum( parseInt( json.result.forumid ) ) ) );
								} else {
									dispatch( refreshPage() );
								}

							} else {
								displayNotification( 'warning', 'Some error happened. Try again.' );
								dispatch( refreshPage() );
							}
						}
					)
				}
			}
		).then( () => {
			dispatch( hideGlobalLoading() );

		} ).catch( () => dispatch( offLineNotification( { name: 'OfflineAction' } ) ) );
	};
}

export function doPollVote( vote ) {
	return function( dispatch, getState ) {
		const state = getState(),
			url = state.topifyPresets.forumSite.url + '?v=3.0&ajax=1&req=post&uid=' + state.ustate.id,
			voteBody = 'type=vote&pollid=' + vote.pollID + '&topic=' + vote.topic + '&votes=' + vote.votes;

		const config = dispatch( getFetchConfig( true, 'POST', voteBody ) );

		return fetch( url, config ).then( ( response ) => {
			if( !response.ok ) {
				dispatch( simpleFailFetchResponse( response ) );

			} else {
				response.json().then( ( json ) => {
					dispatch( refreshPage() );
					displayNotification( 'info', 'You have voted' );
					// Record the Google Analytics event.
					dispatch( trackGAEvent( 1, 'pollVote', 'userAction', 'creation' ) );
				} )
			}
		} ).catch( () => dispatch( offLineNotification( { name: 'OfflineAction' } ) ) );
	};
}

export function markAsRead( itemIds, type ) {
	return function( dispatch, getState ) {
		const state = getState(),
			url = state.topifyPresets.forumSite.url,
			userID = state.ustate.id ? state.ustate.id : 0;

		topifyConsole.log( 'Mark ' + type + ' as read', itemIds );

		if( !itemIds || !type ) {
			return false;
		}

		let moreData = [];

		switch( type ) {
			case 'forums':
				moreData.push( `forumid=${ itemIds }` );
				break;

			case 'topics':
				itemIds = isNaN( itemIds ) && itemIds.length ? itemIds : [ itemIds ];
				itemIds.forEach( function( item_id ) {
					moreData.push( 'topicid[]=' + item_id );
				} );
				break;

			case 'announcement':
				moreData.push( `type=markread` );
				moreData.push( `announcementid=${ itemIds }` );
				break;
		}
		moreData = moreData.join( '&' );

		const fetchConfig = dispatch( getFetchConfig( true, 'POST', moreData ) );

		if( type !== 'announcement' ) {
			dispatch( showGlobalLoading( 'Processing' ) );
		}

		return fetch( url + `?v=3.0&ajax=1&uid=${ userID }&req=post&type=markread`, fetchConfig ).then( ( response ) => {
				if( !response.ok ) {
					dispatch( simpleFailFetchResponse( response ) );

				} else {
					response.json().then( ( json ) => {
							setGlobalNotification( dispatch, json, { ok: true } );

							if( type !== 'announcement' ) {
								dispatch( resetSelectedItem() );
								dispatch( refreshPage() );
								dispatch( hideGlobalLoading() );
							}

							displayNotification( 'info', 'Successfully marked as read', { force: true, timeout: 1500 } );
						}
					);
				}
		} ).catch( () => dispatch( offLineNotification( { name: 'OfflineAction' } ) ) );
	};
}

export function reportPost( postId, reason = '' ) {

	return function( dispatch, getState ) {
		const state = getState(),
			url = state.topifyPresets.forumSite.url,
			userID = state.ustate.id ? state.ustate.id : 0;

		topifyConsole.log( 'Reporting Post', postId );

		if( !postId ) {
			return false;
		}

		dispatch( showGlobalLoading( 'Processing' ) );

		let moreData = `relpostid=${ postId }&reason=${ reason && reason.value ? encodeURIComponent( reason.value ) : '' }`;

		const fetchConfig = dispatch( getFetchConfig( true, 'POST', moreData ) );

		return fetch( url + `?v=3.0&ajax=1&uid=${ userID }&req=post&type=report`, fetchConfig ).then( ( response ) => {
				dispatch( hideGlobalLoading() );

				if( !response.ok ) {
					dispatch( simpleFailFetchResponse( response ) );

				} else {
					response.json().then( ( json ) => {
							setGlobalNotification( dispatch, json, { ok: true } );
							// Record the Google Analytics event.
							dispatch( trackGAEvent( 1, "postReported", 'userAction', 'consumption' ) );
							displayNotification( 'info', 'Post successfully reported' );
						}
					);
				}
		} ).catch( () => dispatch( offLineNotification( { name: 'OfflineAction' } ) ) );
	};
}

