import { createStore, applyMiddleware } from 'redux';

import { connectRouter, routerMiddleware } from 'connected-react-router';

import thunk from 'redux-thunk';
import rootReducer from '../reducers';

export default function configureStore( history ) {
	return createStore(
		connectRouter( history )( rootReducer ), // new root reducer with router state
		applyMiddleware( thunk, routerMiddleware( history ) )
	);
}
