import { displayNotification } from 'Actions/UiActions';
import { pathToRoot, pathToForumsIndex, pathToActiveTopics } from 'Utils/nav/NavHelpers';

export const CHANGE_USER_SETTINGS = 'CHANGE_USER_SETTINGS';

export function saveUserSettingsState( userSettings ) {
	return {
		type: CHANGE_USER_SETTINGS,
		userSettings
	};
}

/** User Settings Functions **/
export function saveSetting( userSettings ) {
	return ( dispatch ) => {
		dispatch( updateUserSettingsStorage( userSettings ) );
		dispatch( saveUserSettingsState( userSettings ) );
		displayNotification( 'success', 'Settings saved', { force: true, timeout: 1000 } );
	}
}

export function getSavedUserSettings() {
	try {
		// removing old v2 settings
		window.localStorage.removeItem( 'topify_local_settings' );
		// Return v3 settings
		return JSON.parse( ( window.localStorage.getItem( 'topifyLocalSettings' ) || '{}' ) );
	} catch( e ) {
		topifyConsole.warn( 'Error loading localStorage: ', e );
	}
}

export function fixLandingPage( landingPage ) {
	try {
		if( landingPage === 'home' && process.env.config.section.home === false ) {
			return process.env.config.IndexRoute;
		}
	} catch( e ) {
		return landingPage;
	}
	return landingPage;
}

export function updateUserSettingsStorage( userSettings ) {
	return ( dispatch, getState ) => {
		try {
			let state = getState(),
				currentSettings = state.ui.userSettings,
				data = Object.assign( {}, currentSettings, userSettings, { timestamp: Date.now() } );

			window.localStorage.setItem( 'topifyLocalSettings', JSON.stringify( data ) );
			topifyConsole.info( 'topifyLocalSettings saved' );
		} catch( e ) {
			topifyConsole.error( 'Error saving localStorage: topifyLocalSettings: ', e );
		}
	}
}

export function mapLoadingBehaviour( lb ) {
	let loading_behavior = {
		oldest: '1',
		newest: '2',
		unread: '3'
	};
	return loading_behavior[ lb ] ? loading_behavior[ lb ] : '1';
}

export function mapOnlineUsersLoadingBehaviour( lb ) {
	let loading_behavior = {
		recent: '0', // recent first
		az: '2', // username a -> z ( default )
		za: '1' // username z -> a
	};
	return loading_behavior[ lb ] ? loading_behavior[ lb ] : '2';
}

export function getPageSize() {
	return ( dispatch, getState ) => {
		let state = getState(),
			pageSizeOption = parseInt( state.ui.userSettings.pageSize ),
			pageSizeDesktop = state.ustate.pageSize ? state.ustate.pageSize : 10;
		return pageSizeOption === 0 ? pageSizeDesktop : pageSizeOption;
	}
}

export function getLandingPage() {
	return ( dispatch, getState ) => {
		let state = getState(),
			landingPagePath = pathToRoot();

		switch( state.ui.userSettings.landingPage ) {
			case 'index':
				landingPagePath = pathToForumsIndex();
				break;
			case 'active':
				landingPagePath = pathToActiveTopics();
				break;
		}

		return landingPagePath;
	}
}