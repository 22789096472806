export const FORUMS_INDEX_FETCH = 'FORUMS_INDEX_FETCH';
export const FORUMS_INDEX_SUCCESS = 'FORUMS_INDEX_SUCCESS';
export const FORUMS_INDEX_FAILURE = 'FORUMS_INDEX_FAILURE';
export const FORUMS_INDEX_RESET = 'FORUMS_INDEX_RESET';
export const FORUMS_INDEX_SET = 'FORUMS_INDEX_SET';

export const FORUMS_FOLLOWING = 'FORUMS_FOLLOWING';

export const FORUMS_INDEX_REQUEST_EPIC = 'FORUMS_INDEX_REQUEST_EPIC';

export function requestForumsIndex(forum) {
	return {
		type: FORUMS_INDEX_FETCH,
		forum: forum
	};
}

export function receiveForumsIndex(forum, data) {
	return {
		type: FORUMS_INDEX_SUCCESS,
		forum: forum,
		data: data
	};
}

export function failedForumsIndex(forum, error) {
	return {
		type: FORUMS_INDEX_FAILURE,
		forum: forum,
		error: error
	};
}

export function resetForumsIndex(){
	return {
		type: FORUMS_INDEX_RESET
	}
}

export function updateForumsFollow( forumId, follow ) { // plural, used only in native
	return {
		type: FORUMS_FOLLOWING,
		forumId: forumId,
		follow: follow
	};
}

export function requestForumsIndexEpic(forumParams) {
	return {
		type: FORUMS_INDEX_REQUEST_EPIC,
		forumParams
	}
}

export const setForumsIndex = (state,date) => {
	return {
		type: FORUMS_INDEX_SET,
		state,
		date
	}
};
