export default class TopifyConsole {
	constructor( debug, topifyConsoleLabel ) {
		// What level of console we want?
		this.debugState = debug;
		this.label = topifyConsoleLabel;
		this.getDebugMode();
	}

	getDebugMode = function() {
		console.log( ...this.label, 'Topify\'s debug mode: ', this.debugState );
		return this.debugState;
	};

	// topify_debug >= 1
	// only errors and clear
	clear = function() { this.debugState > 0 ? console.clear() : null };
	error = function() { this.debugState > 0 ? console.error( ...this.label, ...arguments ) : null };

	// topify_debug >= 2
	// warning, errors and timing
	time = function() { this.debugState > 1 ? console.time( ...arguments ) : null };
	timeEnd = function() { this.debugState > 1 ? console.timeEnd( ...arguments ) : null };
	timeStamp = function() { this.debugState > 1 ? console.timeStamp() : null };
	warn = function() { this.debugState > 1 ? console.warn( ...this.label, ...arguments ) : null };

	// topify_debug >= 3
	// info, warning, error, timing and table
	table = function() { this.debugState > 2 ? console.table( ...arguments ) : null };
	info = function() { this.debugState > 2 ? console.info( ...this.label, 'INFO:', ...arguments ) : null };
	debug = function() { this.debugState > 3 ? console.debug( ...this.label, ...arguments ) : null };

	// topify_debug >= 4
	// all
	log = function() { this.debugState > 3 ? console.log( ...this.label, ...arguments ) : null };
	group = function() { this.debugState > 3 ? console.group( ...arguments ) : null };
	groupCollapsed = function() { this.debugState > 3 ? console.groupCollapsed( ...arguments ) : null };
	groupEnd = function() { this.debugState > 3 ? console.groupEnd() : null };
	assert = function() { this.debugState > 3 ? console.assert( ...arguments ) : null };
	count = function() { this.debugState > 3 ? console.count( ...arguments ) : null };
	dir = function() { this.debugState > 3 ? console.dir( ...arguments ) : null };
	dirxml = function() { this.debugState > 3 ? console.dirxml( ...arguments ) : null };
	profile = function() { this.debugState > 3 ? console.profile( ...arguments ) : null };
	profileEnd = function() { this.debugState > 3 ? console.profileEnd() : null };
	trace = function() { this.debugState > 3 ? console.trace( ...arguments ) : null };
}
