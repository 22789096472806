export const THREAD_FETCH = 'THREAD_FETCH';
export const THREAD_SUCCESS = 'THREAD_SUCCESS';
export const THREAD_FAILURE = 'THREAD_FAILURE';
export const THREAD_RESET = 'THREAD_RESET';
export const THREAD_REQUEST_EPIC = 'THREAD_REQUEST_EPIC';
export const SET_THREAD = 'SET_THREAD';
export const ANNOUNCEMENT_READ = 'ANNOUNCEMENT_READ';
export const POST_CONTENTUPDATE = 'POST_CONTENTUPDATE';


export function requestThread( thread ) {
	return {
		type: THREAD_FETCH,
		thread: thread
	};
}

export function receiveThread( thread, data ) {
	return {
		type: THREAD_SUCCESS,
		thread: thread,
		data: data
	};
}

export function failedThread( thread, error ) {
	return {
		type: THREAD_FAILURE,
		thread: thread,
		error: error
	};
}

export function resetThread() {
	return {
		type: THREAD_RESET
	};
}

export const setThread = (state,date) => {
	return {
		type: SET_THREAD,
		state,
		date,
	}
};

export function requestThreadEpic(threadParams) {
	return {
		type: THREAD_REQUEST_EPIC,
		threadParams
	}
}

export function markReadAnnouncement( anId ) {
	return {
		type: ANNOUNCEMENT_READ,
		announcement: anId
	};
}

export function updatePostContent( postId, data ) {
	return {
		type: POST_CONTENTUPDATE,
		postId,
		data
	};
}
