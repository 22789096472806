export const ADD_PHOTO = 'ADD_PHOTO';
export const SHOW_GALLERY = 'SHOW_GALLERY';
export const HIDE_GALLERY = 'HIDE_GALLERY';
export const REMOVE_PHOTO = 'REMOVE_PHOTO';
export const RESET_GALLERY = 'RESET_GALLERY';

export function addPhoto(item) {
	return {
		type: ADD_PHOTO,
		item: item
	}
}

export function removePhoto( items ) {
	return {
		type: REMOVE_PHOTO,
		items: items // array
	}
}

export function showGallery(options,postId) {
	return {
		type: SHOW_GALLERY,
		postId: postId,
		options: options
	}
}

export function hideGallery() {
	return {
		type: HIDE_GALLERY
	}
}

export function resetGallery() {
	return {
		type: RESET_GALLERY
	}
}