import queryString from 'query-string';

import { push, replace, goBack } from 'connected-react-router';

import { displayNotification, hideGlobalLoading } from 'Actions/UiActions';
import { trackGAEvent } from 'Actions/adsActions';

import { fetchForum, resetForum } from 'Actions/ForumviewActions';
import { fetchThread, resetThread } from 'Actions/ThreadviewActions';
import { fetchSearch, resetSearch } from 'Actions/SearchResultActions';
import { fetchMessages, resetMessages } from 'Actions/MessagesviewActions';
import { fetchUserProfile, resetUserProfile } from 'Actions/UserprofileActions';
import { resetOverlay } from 'Actions/UiActions';
import { updatePageNumber, resetInfinite, retrievableContent } from 'Actions/InfiniteScrollActions'
import { resetGallery } from 'Actions/PhotoGalleryActions';
import { resetOnlineUsers, whoIsOnline } from 'Actions/OnlineUsersActions';
import { whatReleaseNotes, resetReleaseNotes } from 'Actions/ReleaseNotesActions';
import { fetchPortalPage, resetPortalPage } from 'Actions/PortalPageActions';
import { setCacheBuster } from 'Actions/MetaActions';
import { responseNormalizer, updateUstate } from 'Actions/responseNormalizer';
import { getLandingPage } from 'Actions/UserSettingsActions';

import fixUrlProtocol from 'Utils/fixUrlProtocol';
import { setCookie } from 'Utils/cookies';

export const CLEAR_FIRST_LOAD = 'CLEAR_FIRST_LOAD';

export let lastGlobalAction = {
	type: null,
	data: {}
};

export function clearFirstLoad() {
	return {
		type: CLEAR_FIRST_LOAD
	}
}

export function setLastGlobalAction(type, data) {
	if(type && data){
		if (lastGlobalAction.type !== type) {
			lastGlobalAction = {
				type,
				data: {
					...data,
					page: parseInt( data.page ) || 1
				}
			};
		} else {
			lastGlobalAction = {
				type: type,
				data: Object.assign(
					lastGlobalAction.data,
					data, {
						forceRefresh: true,
						page: lastGlobalAction.data.page || parseInt( data.page )
					}
				)
			};
		}
	}
	return lastGlobalAction;
}

export function setLastGlobalPage( page ) {
	if( page ) {
		lastGlobalAction = {
			...lastGlobalAction,
			data: {
				...lastGlobalAction.data,
				page: parseInt( page )
			}
		};
	}
	return lastGlobalAction;
}

export function setGlobalNotification( dispatch, json, response ) {
	if ( ( !json.message || json.message.length === 0 ) && response.status === 401 ) {
		displayNotification( 'warning', 'You don\'t have permissions to perform this action or you are not logged in. Please sign in and try again.' );
		// Record the Google Analytics event.
		dispatch( trackGAEvent( 1, 'noPerms', 'error', 'user' ) );

	} else if ( !json.message && response.status === 422 ) {
		displayNotification( 'warning', 'Username or Password is incorrect.' );
		// Record the Google Analytics event.
		dispatch( trackGAEvent( 1, 'forumLoginFailed', 'error', 'user' ) );

	} else if( response.status === 403 ) {
		let message = json.message || { data: { message: 'Access Forbidden' } };
		(Array.isArray( message.data ) ? message.data : [ message.data ]).map( function( element ) {
			displayNotification( 'error', element.message, { timeout: 'none' } );
		} );
		// Record the Google Analytics event.
		dispatch( trackGAEvent( 1, 'error_403', 'error', 'user' ) );
		// throw the exception on console
		topifyConsole.error( message );
		throw '403 - Access Forbidden';

	} else if ( !json.message && !response.ok && response.name !== "TypeError" ) {
		displayNotification( 'warning', 'An error has occurred.' );
		// Record the Google Analytics event.
		dispatch( trackGAEvent( 1, `forumCommunication${ response.status ? '_' + response.status : '' }`, 'error', 'system' ) );

	} else if ( !json.message && response.message && response.name ) {
		displayNotification( 'error', 'An error has occurred. ' + response.message );
		// Record the Google Analytics event.
		dispatch( trackGAEvent( 1, `serverCommunication${ response.status ? '_' + response.status : '' }`, 'error', 'system' ) );
	}

	if( json && json.message && json.message.data ) {
		// Ensure we have an array of messages to map
		(Array.isArray( json.message.data ) ? json.message.data : [ json.message.data ]).map( function( element ) {
			switch( element.type ) {
				case 'success':
					displayNotification( 'success', element.message, { force: true } );
					break;
				case 'info':
					displayNotification( 'info', element.message, { force: true } );
					break;
				case 'alert':
				case 'warning':
					displayNotification( 'warning', element.message, { force: true } );
					break;
				case 'error':
					displayNotification( 'error', element.message, { force: true } );
					// Record the Google Analytics event.
					dispatch( trackGAEvent( 1, 'errorReturned', 'error', 'system' ) );
					break;
				default:
					break;
			}
		});
	}
}

export function checkOnlineStatus( dispatch, isOnline ) {
	if( isOnline === false ) {
		dispatch( resetOverlay() );
		offLineNotification( { name: 'OfflineAction' } );
		return false;
	}
	return true;
}

export function offLineNotification( error = {}, page = null ) {
	return( dispatch, getState ) => {
		const state = getState();

		dispatch( hideGlobalLoading() );

		const notification = Number.isInteger( page ) ? !state.infiniteScroll.retrievableContent.includes( page ) : true;

		if( navigator.onLine === false ) { // OffLine

			if( page ) {
				dispatch( retrievableContent( page ) );
			}

			// we don't need notify every time
			let msg = 'You have access to limited offline content',
				eventGA = `userOffline`,
				timeout = 1500;
			switch( error.name ) {
				case 'OfflineAction':
					msg = 'This action is not accessible offline';
					eventGA = error.name;
					timeout = 2000;
					break;

				case 'OfflineCompose':
					msg = 'You cannot post offline';
					eventGA = error.name;
					timeout = 4000;
					break;

				case 'OfflineDraft':
					msg = 'You cannot post offline, but this message will be saved to your drafts';
					eventGA = error.name;
					timeout = 4000;
					break;

				case 'OfflineAttachments':
					msg = 'You cannot add or remove attachments offline';
					eventGA = error.name;
					timeout = 4000;
					break;
			}

			if( notification ) {
				displayNotification( 'alert', msg, { timeout: timeout, position: 'bottom' } );

				// Record the Google Analytics event.
				dispatch( trackGAEvent( 1, eventGA, 'error', 'system' ) );
			}

		} else if( notification && error.name === 'TypeError' ) {
			// It seems that some browsers returns ERR_NETWORK_DISCONNECTED or ERR_NETWORK_CHANGED
			// when reporting is online, send to GA and silently skip

			// Record the Google Analytics event.
			dispatch( trackGAEvent( 1, `noOnline`, 'error', 'system' ) );
		}
	}
}

export function backInHistory() {
	return ( dispatch ) => {
		if( lastGlobalAction.type !== null ) {
			dispatch( goBack() );
		} else {
			// Go to landing page
			let landingPage = dispatch( getLandingPage() );
			dispatch( push( landingPage ) );
		}
	};
}

export function gotoRegister( redirectUrl, thisLocation ) {
		let mobileurl = thisLocation.pathname ? thisLocation.pathname.replace( /^\//, '' ) + thisLocation.search : '';
		document.location.replace( `${ redirectUrl }${ /\?/i.test( redirectUrl ) ? '&' : '?' }mobileurl=${ mobileurl ? encodeURIComponent( mobileurl ) : '/' }` );
}

export function exitTopify() {
	let domain = location.hostname;
	if(domain.indexOf("www.") === 0) {
		domain = domain.substr(4);
	}
	domain = "." + domain;

	setCookie( 'topify_hideAppView', '1', (90 * 24 * 60), domain ); // 90 days
	setCookie( 'topify_hidePrompt', '1', (24 * 60), domain ); // 1 day

	// Clear SW cache
	try {
		navigator.serviceWorker.controller.postMessage( {
			topify: true,
			process: 'preCacheBust'
		} );
	} catch( e ) { /* ignore */ }

	// Record the Google Analytics event.
	this.props.dispatch( trackGAEvent( 1, 'exitTopify', 'userAction', 'exit') );

	let redirectToUrl = location.origin + location.pathname;

	if(!redirectToUrl) {
		redirectToUrl = this.props.forum_url;
	}

	let urlSearch = window.location.search.replace( /\?/, '' ).replace( /rtemv=\d+/i, '' ).replace( /nocache=\d+/i, '' ).replace( /tfr=1/i, '' );

	window.location.href = redirectToUrl + '?' + urlSearch + ( urlSearch.length ? '&' : '' ) + 'nocache=' + Date.now();
	location.reload(true);

	return false;
}

export function refreshPage( manual = false ) {
	return function( dispatch ) {
		// Hide overlay and all opened stuff
		dispatch( resetOverlay() );
		// reset attachments gallery
		dispatch( resetGallery() );

		// CacheBust
		dispatch( setCacheBuster() );

		// We only want trigger analytics when we refresh manually
		if( manual ) {
			// Record the Google Analytics event.
			dispatch( trackGAEvent( 1, `refresh_${ lastGlobalAction.type }`, 'userAction', 'consumption' ) );
		}

		switch( lastGlobalAction.type ) {
			case 'forum':
				dispatch( resetInfinite() );
				dispatch( resetForum() );
				dispatch( fetchForum( lastGlobalAction.data ) );
				// Forum Index page doesn't have pages, no need to update it
				if( lastGlobalAction.data.id !== -1 ) {
					dispatch( updatePageNumber( lastGlobalAction.data.page ) );
				}
				break;

			case 'thread':
				dispatch( resetInfinite() );
				dispatch( resetThread() );
				dispatch( fetchThread( lastGlobalAction.data ) );
				dispatch( updatePageNumber( lastGlobalAction.data.page ) );
				break;

			case 'fav':
			case 'part':
			case 'new':
			case 'unread':
			case 'search':
				dispatch( resetInfinite() );
				dispatch( resetSearch() );
				dispatch( resetForum() );
				dispatch( resetThread() );
				dispatch( fetchSearch( lastGlobalAction.data ) );
				dispatch( updatePageNumber( lastGlobalAction.data.page ) );
				break;

			case 'messages_pmroot':
			case 'messages_pmfolder':
			case 'messages_pm':
				dispatch( resetInfinite() );
				dispatch( resetMessages() );
				dispatch( fetchMessages( lastGlobalAction.data ) );
				dispatch( updatePageNumber( lastGlobalAction.data.page ) );
				break;

			case 'userprofile':
				dispatch( resetInfinite() );
				dispatch( resetUserProfile() );
				dispatch( resetForum() );
				dispatch( resetThread() );
				dispatch( fetchUserProfile( lastGlobalAction.data ) );
				dispatch( updatePageNumber( lastGlobalAction.data.page ) );
				break;

			case 'onlineUsers':
				dispatch( resetInfinite() );
				dispatch( resetOnlineUsers() );
				dispatch( whoIsOnline( lastGlobalAction.data.page ) );
				dispatch( updatePageNumber( lastGlobalAction.data.page ) );
				break;

			case 'releaseNotes':
				dispatch( resetInfinite() );
				dispatch( resetReleaseNotes() );
				dispatch( whatReleaseNotes( lastGlobalAction.data.page ) );
				dispatch( updatePageNumber( lastGlobalAction.data.page ) );
				break;

			case 'portalpage':
				dispatch( resetPortalPage() );
				dispatch( fetchPortalPage( { pagename: lastGlobalAction.data.pagename } ) );
				break;

			default:
				//noop
				break;
		}
	};
}

export function resetAll() {
	return function( dispatch ) {
		dispatch( resetInfinite() );
		dispatch( resetUserProfile() );
		dispatch( resetMessages() );
		dispatch( resetSearch() );
		dispatch( resetThread() );
		dispatch( resetForum() );
		dispatch( resetPortalPage() );
		dispatch( resetReleaseNotes() );
	}
}

export function pingServer() {
	return ( dispatch, getState ) => {
		const state = getState(),
			uid = state.ustate.id ? state.ustate.id : 0,
			url = state.topifyPresets.forumSite.url,
			config = dispatch( getFetchConfig() );

		return fetch( url + `?v=3.0&ajax=1&uid=${uid}&req=get&object=ping&_=${ Date.now() }`, config ).then( ( response ) => {
				if( response.ok ) {
					response.json().then( ( json ) => {
						let result = responseNormalizer( json, false, {} );
						dispatch( updateUstate( json, result ) );
						dispatch( doCronJob( json ) );
					} );
				}
			}
		);
	}
}

/**
 * Unified fetch config constructor function
 *
 * @param cacheBust
 * @param method
 * @param body
 * @param urlencoded
 *
 * @returns {function(*, *)} => {{method: string, mode: string, credentials: string, redirect: string, cache: string, body: *}}
 */
export function getFetchConfig( cacheBust = false, method = 'GET', body = null, urlencoded = true ) {
	return ( dispatch, getState ) => {
		let state = getState(),
			cache = ( cacheBust ? 'reload' : 'default' ),
			myHeaders = new Headers();

		if( method === 'POST' ) {
			if( urlencoded ) {
				myHeaders.append( "Content-Type", "application/x-www-form-urlencoded; charset=UTF-8" );
			}
		}

		// Check if we want to force cache busting
		try {
			let query = queryString.parse( state.router.location.search );
			if( query && parseInt( query.forceCacheBust ) === 1 ) {
				myHeaders.append( 'pragma', 'no-cache' );
				myHeaders.append( 'cache-control', 'no-cache' );
				cache = 'reload';

				// remove the query string and replace url
				delete query.forceCacheBust;
				dispatch( replace( {
					pathname: state.router.location.pathname,
					search: queryString.stringify( query )
				} ) );
			}

		} catch( e ) { /* ignore */ }

		let config = {
			method: method,
			mode: 'cors',
			credentials: 'include',
			redirect: 'follow',
			cache: cache,
		};

		if( method !== 'GET' && method !== 'HEAD' && body ) {
			config.body = body;
		}

		if( myHeaders ) {
			config.headers = myHeaders;
		}

		return config;
	}
}

export function simpleFailFetchResponse( response ) {
	return function( dispatch ) {
		try {
			response.json().then( ( json ) => {
				setGlobalNotification( dispatch, json, response );
			} ).catch( () => {
				setGlobalNotification( dispatch, {}, response );
			} );
		} catch( e ) {
			setGlobalNotification( dispatch, {}, response );
		}
	}
}

export function doCronJob( json ) {
	return function( dispatch ) {
		// Check if we have an URL in json.cron and trigger it
		if( json && json.cron ) {
			const myHeaders = new Headers();
			myHeaders.append( 'pragma', 'no-cache' );
			myHeaders.append( 'cache-control', 'no-cache' );
			const config = {
				method: 'GET',
				mode: 'cors',
				credentials: 'include',
				redirect: 'follow',
				cache: 'reload',
				headers: myHeaders
			};

			// fetch and forget
			fetch( fixUrlProtocol( json.cron ), config ).then( response => topifyConsole.log( 'CRON:', response.statusText ) ).catch( error => topifyConsole.error( 'CRON ERROR:', error ) );
		}
	}
}