/**
 * Returns the url fixed with https if the url is http and we are in a https page
 * otherwise leave it how it is
 *
 * @param url string
 * @returns string url with corrected protocol
 */
export default function fixUrlProtocol( url ) {
	return url && /^http:/i.test( url ) ? url.replace( /^http:[/]{2}/i, ('https:' === document.location.protocol ? 'https://' : 'http://') ) : url;
}
