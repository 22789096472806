export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_USTATE = 'SET_USTATE';

export const receiveCurrentUser = ( ustate ) => {
  return {
    type: SET_CURRENT_USER,
    ustate: ustate
  }
};

export const setUstate = (state,date) => {
	return {
		type: SET_USTATE,
		state,
		date
	}
};

