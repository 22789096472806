
export const MESSAGES_FETCH = 'MESSAGES_FETCH';
export const MESSAGES_SUCCESS = 'MESSAGES_SUCCESS';
export const MESSAGES_FAILURE = 'MESSAGES_FAILURE';
export const MESSAGES_RESET = 'MESSAGES_RESET';

export const RESTORE_MESSAGES = 'RESTORE_MESSAGES';
export const SET_MESSAGES = 'SET_MESSAGES';

export const MESSAGES_REQUEST_EPIC = 'MESSAGES_REQUEST_EPIC';

export function requestMessages( message ) {
	let id_name = {};

	switch( message.pmroute ) {
		case 'pmfolder':
			id_name = { pmfolder: message.id };
			break;
		case 'pm':
			id_name = { pm: message.id };
			break;
	}

	return {
		type: MESSAGES_FETCH,
		loadingBehavior: message.loadingBehavior,
		...id_name
	};
}

export function receiveMessages( route, id, data, loadingBehavior ) {
	return {
		type: MESSAGES_SUCCESS,
		pmroute: route,
		id: id,
		data: data,
		loadingBehavior: loadingBehavior
	};
}

export function failedMessages( route, error ) {
	return {
		type: MESSAGES_FAILURE,
		pmroute: route,
		error: error
	};
}

export function resetMessages(){
	return {
		type: MESSAGES_RESET
	};
}

export const restoreMessages = () => {
	return {
		type: RESTORE_MESSAGES
	}
};

export function requestMessagesEpic(messagesParams) {
	return {
		type: MESSAGES_REQUEST_EPIC,
		messagesParams
	}
}

export const setMessages = (state,date) => {
	return {
		type: SET_MESSAGES,
		state,
		date,
	}
};