export * from 'Common/actions/MetaActions';

import { ONLINE_STATUS } from "Common/actions/MetaActions";

export const onlineStatus = isOnline => {
	return {
		type: ONLINE_STATUS,
		isOnline: isOnline
	}
};
