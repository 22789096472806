import { string, regex, seq, seqMap, seqObj, alt, lazy, succeed, end } from 'parsimmon';

// main Parser
const parseBBcode = lazy( () => alt( bbcodeBlock, nobbcodeSingleTag, htmlTag, words ).many() ) ;

// skipping after spaces
function token( parser ) {
	return parser.skip( whitespace );
}
// ignore space before
function preToken( parser ) {
	return whitespace.then( parser );
}

// preformatted tag, do not parse content
const preformatted = /php|code|html/i;

// Custom whitespace regex
const whitespace = regex( /\s*/m );

// bbCode tokens
const bbcodeOpenBegin = token( string( '[' ) );
const bbcodeCloseBegin = token( string( '[/' ) );
const bbcodeEnd = preToken( string( ']' ) );

// html tokens
const htmlOpenBegin = regex( /<[a-zA-Z]/ );
const htmlEnd = string( '>' );
const htmlContent = regex( /[^>]+/ );

// bbCode elements
const bbcodeWord = regex( /\w+\b/i );

// other helpers
const words = regex( /[^\[]+/ ) // any word but [
	.or( string( '[' ).skip( end ) ) // it ends with an open [
	.or( string( '[/' ).skip( end ) ) // it ends with [/
	.or( string( '[' ).lookahead( preToken( bbcodeOpenBegin ) ) ) // double [ with or without space
	.desc( 'words' );

// Handle stray html tags
const htmlTag = seqMap( htmlOpenBegin, htmlContent, htmlEnd,
	( a, b ) => {
		switch( b ) {
			case 'br':
				return '\n';
			default:
				return '';
		}
	}
).desc( 'html tag' );

// Attribute value quoted or not
const bbcodeAttrVal = token( preToken( string( '=' ) ) ).then( alt( regex( /(['"])(.*?)(\1)/, 2 ), regex( /[^\s\]]+/i ) ) );
// Param value quotes or not. The difference is that the params with no quotes can have spaces (ie: quote usernames)
const bbcodeParamsVal = token( preToken( string( '=' ) ) ).then( alt( regex( /(['"])(.*?)(\1)/, 2 ), regex( /[^\]]+/i ) ) );

// ATTRIBUTE KEY VALUE PAIRS KEY=VAL
const bbcodeAttrPair = seqObj( [ 'key', preToken( bbcodeWord ) ], [ 'val', bbcodeAttrVal ] )
	.map( r => {
			return {
				key: r.key,
				val: r.val
			};
		}
	);

// tag name
const simpleTag = seqObj( [ 'tag', bbcodeWord ] );
// tag with params and/or attibutes
const complexTag = seqObj( [ 'tag', bbcodeWord ], [ 'params', bbcodeParamsVal.atMost( 1 ) ], [ 'attributes', bbcodeAttrPair.many() ] );

// no bbCode TAG [SOMETHING WITH POTENTIALLY bbCODE INSIDE]
const nobbcodeSingleTag = seq( bbcodeOpenBegin.then( regex( /[^\/\[]/ ) ) )
	.chain( openingTag => seq( succeed( '[' ), succeed( openingTag[ 0 ] ) ) )
	.map( content => {
			return {
				open: { tag: 'noTag', attributes: null, params: null },
				content: content,
				close: null
			};
		}
	)
	.desc( 'no bbcode single tag' );

// lists items parser [*] when inside [list]
const bbCodeListItemsReducer = ( resultAcc, item, curr_i, result ) => {
	// avoid empty lines as fist item
	if( resultAcc.length === 0 && typeof item === 'string' ) {
		if( item.trim() === '' ) {
			return resultAcc;

		} else {
			// if we have some text with no [li] or [*] item
			// inject one to start the list
			resultAcc[ resultAcc.length ] = {
				open: {
					tag: 'li',
					attributes: null,
					params: null
				},
				content: [ item ],
				close: {
					tag: 'li',
					attributes: null,
					params: null
				}
			};
		}

	} else if( typeof item === 'object' && item.content.reduce( ( a, b ) => a + b, '' ) === '[*' ) {
		// the list item starts
		resultAcc[ resultAcc.length ] = {
			open: {
				tag: 'li',
				attributes: null,
				params: null
			},
			content: [ '' ],
			close: {
				tag: 'li',
				attributes: null,
				params: null
			}
		};

	} else if(
		typeof item === 'string' && /^\s*\]/i.test( item )
		&& typeof result[ curr_i - 1 ] === 'object' && result[ curr_i - 1 ].content.reduce( ( a, b ) => a + b, '' ) === '[*'
	) {
		// text inside list item, let's add it
		resultAcc[ resultAcc.length - 1 ].content[ 0 ] += item.replace( /^\]\s*/, '' );

	} else {
		// other items already parsed in list items, let's put it on previous item
		resultAcc[ resultAcc.length - 1 ].content.push( item );
	}
	return resultAcc;
};

// bbCode Block
const bbcodeBlock = seq( bbcodeOpenBegin.then( alt( complexTag, simpleTag ) ).skip( bbcodeEnd ) )
	.chain(
		openingTag => {
			// by default we'll look for a closing tag same as opening and parse the content inside,
			// but we have some edge cases

			// We want the closing tag [/OPENING_TAG] but the same tag we openend
			let closingTag = seq( bbcodeCloseBegin, regex( new RegExp( openingTag[ 0 ].tag, 'i' ) ).skip( bbcodeEnd ) );

			// preformatted tag, do not parse content
			if( preformatted.test( openingTag[ 0 ].tag ) ) {
				let unparsedContent = seq( regex( new RegExp( '([^]*?)' + '\\[\/\\s*' + openingTag[ 0 ].tag + '\\s*\\]', 'i' ), 1 ) ).map( r => r );
				return seq( succeed( openingTag[ 0 ] ), unparsedContent );

			} else if( /list/i.test( openingTag[ 0 ].tag ) ) { // bbcode [list]
				// get whatever is inside [list][/list]
				return seq( succeed( openingTag[ 0 ] ), parseBBcode.skip( closingTag ) )
					.map( result => {
						if( result && result.length > 0 ) { // parse inside [list]
							let newResult = result[ 1 ].reduce( bbCodeListItemsReducer, [] );
							return [ result[ 0 ], newResult ];
						}
						return false;
					}
				);
			}

			return seq( succeed( openingTag[ 0 ] ), parseBBcode.skip( closingTag ) );
		}
	)
	.map(
		( [ open, content ] ) => {
			let attributes = {};
			// convert into a more useful json
			if( open.attributes.length ) {
				open.attributes.forEach( el => {
					// if we already have any value concat them
					attributes[ el.key ] = attributes[ el.key ] ? attributes[ el.key ] + ' ' + el.val : el.val;
				} );
			}

			return {
				open: {
					tag: open.tag.toLowerCase(),
					attributes: attributes || {},
					params: open.params && open.params.join( ' ' ) || null
				},
				content: content,
				close: open
			};
		}
	)
	.desc( 'bbcodeBlock' );

export default parseBBcode;

